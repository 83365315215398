/*
* Styles Component de CadastroServicos
*/
import styled from 'styled-components';
import * as constants from '../../../../../constants/styles';

const {
    third,
    white,
    grayDark,
    warning,
    secondary,
    primary,
    yellow,
    def,
    danger
} = constants


export const Container = styled.div`
    min-height: 100%;
    background: ${third};
    padding: 0;
    .anexos {
        padding: 0 0 0 20px;
    }
    div.info {
        width: 100%;
        padding: 30px 0;
        display: flex;
        justify-content: end;
        align-items: center;
        span.title {
            font-size: 30pt;
            margin-left: 20px;
            color: ${primary};
        }
        span {
            padding: 10px 20px;
            font-size: 14pt;
            color: ${grayDark}
        }
    }

    div.table {
            width: 95%;
            display: flex;
            padding: 5px 0px;
            justify-content: center;
            align-items: center;

            table.warning {
                th {
                    background: ${warning};
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            table.def {
                th {
                    background: ${def};
                }
            }
            table {
                width: 100%;
                margin: 0;
                th {
                    padding: 15px;
                    background: ${props => constants[props.theme] ? constants[props.theme] : secondary};
                    color: ${white};
                    text-align: start;
                }
                td {
                    padding: 15px;
                    button {
                        border: none;
                        border-radius: 200px;
                        padding: 5px;
                        margin:0 10px 0 0;
                        color: ${white};
                        //width: 30px;
                        font-size:12pt;
                        box-shadow: 3px 1px 5px #ccc;
                    }
                    button.disabled {
                        background: #aaa;
                        color: #eee;
                    }
                    button.continue {
                        background: ${secondary};
                    }
                    button.edit {
                        background: ${secondary};
                    }
                    button.print {
                        background: ${primary};
                    }
                    button.conclued {
                        background: ${def};
                    }
                    button.inclonclued {
                        background: ${yellow};
                    }
                    button.canceled {
                        background: ${danger};
                        width: 100%;
                    }
                    button.see {
                        background: ${primary};
                        padding: 5px 7px;
                    }
                }
                .two-buttons {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 10px;
                }
                tr {
                    background: ${white};
                    &:nth-child(even) {
                        background: ${third};
                    }
                }

            }
        }

`;

export const Content = styled.div`
    width:100%;
    margin: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .area, .documentos, .anexos {
        width: 95%;
        overflow-y: auto;
        overflow-x: auto;
        height: 400px;
        max-height:400px;
    }
    @media only screen and (max-width: 360px) {
        .area {
            width: 80vw;
            max-height: 14em!important;
            overflow-y: scroll;

        }

    }

    @media only screen and (min-width: 360px) and (max-width: 768px) {

    }
    .voltar{
        width: 100%;
        display: flex;
        padding-left: 80px;
    }
    .back{
       background: ${def}; 
       border: none;
       border-radius: 200px;
       padding: 10px 20px;
       margin:0 10px 0 0;
       color: ${white};
       font-size:12pt;
       box-shadow: 3px 1px 5px #ccc;
    }
`;

export const Table = styled.table`
    width: 100%;
    margin: 0;
    th {
        padding: 15px;
        text-align: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        ${props => props.theme};
    }
    td {
        padding: 15px;
        button {
            border: none;
            border-radius: 5px;
            padding: 10px;
            margin: 0 2px;
            color: ${white};
        }
        button.upload {
            background: ${warning};
        }
        button.see {
            background: ${secondary};
        }        
        button.arquivos {
            background: ${primary};
        }
    }
    tr {
        background: ${white};
        &:nth-child(even) {
            background: ${third};
        }
    }
    @media only screen and (max-width: 360px) {
        thead tr th {
            width: 1px!important;
        }
        tbody tr td {
            max-width: 1px!important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

