import styled from 'styled-components';
import * as constants from '../../../constants/styles';

const {
    whiteDark,
    secondary,
    whiteLight,
    boxShadow,
    danger
} = constants

export const Container = styled.form.attrs(props => ({
    onSubmit: props.onSubmit,
    theme: props.theme
}))`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    div.form-line {
        display: flex;
        flex-direction: column;
        margin: .5% 1%;
        span {
            font-size: 10pt;
            padding: 10px 0;
        }
        input {
            border: none;
            background: ${whiteDark};
            padding: 10px;
            border-radius: 20px;
        }
        textarea {
            border: none;
            background: ${whiteDark};
            padding: 10px;
            border-radius: 20px;
        }
    }
    div.button {
        padding: 20px 20px;
        display: flex;
        width: 100%;
        justify-content: end;
        button {
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            background: ${props => constants[props.theme] ? constants[props.theme] : secondary};
            color: ${whiteLight};
            font-size: 12pt;
            box-shadow: 1px 1px 5px ${boxShadow};
        }
        button.cancel {
            background: ${danger};
            margin: 0 10px;
        }
        button:hover {
            box-shadow: none;
        }
    }

`;
