/*
* Styles Component de CadastroServicos
*/
import styled from 'styled-components';
import * as constants from '../../../constants/styles';

const {
    third,
    white,
    boxShadow,
    warning,
    def,
    danger
} = constants

export const Container = styled.div`
    min-height: 100%;
    background: ${third};
    display: flex;
    flex-direction: row;
    div.area {
        max-height: 600px;
        overflow:auto;
        border: none;
        padding: 0;
    }
    div.containerButtonNew {
        width: 99%;
        display: flex;
        justify-content: start;
        margin-top: 15px;
        button {
            padding: 10px 20px;
            border: none;
            background: ${def};
            color: ${white};
            border-radius: 5px;
            font-size: 14pt;

            svg {
                margin-right: 10px;
            }
        }

        input {
            width: 25%;
            border: .5px solid ${boxShadow};
            border-radius: 5px;
            margin: 0 10px 0 0;
            padding: 10px;
        }
    }
    .card-contacts ul {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;
        margin: 50px 50px 50px 0;
    }

    .card-contacts ul li {
        display: flex;
        flex-direction: column;
        transition: .2s;
        &:hover {
            box-shadow: 3px 3px 10px #ccc;
            transition: .2s;
        }
    }

    .card-contacts ul li footer {
        flex: 1;
        background: #FFF;
        border: 1px solid #EEE;
        padding: 15px 20px;
        text-align: left;
        border-radius: 0 0 5px 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: space-between;
    }

    .card-contacts ul li footer strong {
        font-size: 16px;
        color: #333;
    }

    .card-contacts ul li footer p {
        font-size: 14px;
        color: #999;
        line-height: 25px;
        margin-top: 5px;
    }

    .card-contacts ul li .buttons button {
        padding: 10px;
        margin: 0 3px;
        width: 40px;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.05);
        border: 0;
        border-radius: 50px;
        color: #fff;
        cursor: pointer;
        float: right;
    }

    .card-contacts ul li .buttons button.edit {
        background: ${warning};
    }

    .card-contacts ul li .buttons button.send {
        background: ${def};
    }

    .card-contacts ul li .buttons button.delete {
        background: ${danger};
    }

    .empty-box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .empty-box span {
        color: #ddd;
        font-style: italic;
        font-size: 18pt;
        padding: 50px;
    }
    @media only screen and (max-width: 360px) {
        .area {
            width: 85vw;
        }
        .card-contacts ul {
            grid-template-columns: repeat(1, 1fr);
        }
        .card-contacts ul li footer strong {
            font-size: 16px;
        }

        .card-contacts ul li footer p {
            font-size: 10px;
        }
        div.containerButtonNew {
            input {
                width: 50%;
            }
        }
        table {
            td {
                button.edit {
                    span {
                        display: none;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 360px) and (max-width: 768px) {
        .card-contacts ul {
            grid-template-columns: repeat(1, 1fr);
        }
        div.containerButtonNew {
            input {
                width: 50%;
            }
        }
        table {
            td {
                button.edit {
                    span {
                        display: none;
                    }
                }
            }
        }
    }
`;


