import React from 'react';

import { Container } from './styles';

const Card = (props) => {
    return (
        <Container onClick={() => props.onClick(props.id)} {...props.theme}>
            <div className="number">
                <span>{props.number}</span>
            </div>
            <div className="span">
                <span>{props.label}</span>
            </div>
        </Container>
    )
};

export default Card;
