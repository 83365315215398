import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Container } from './styles';
import { useSession } from '../../../../utils';
import AreaComponent from '../../../../components/AreaComponent';
import TopMenu from '../../components/TopMenu';
import Loading from '../../components/Loading';
import Upload from "../../../../components/UploadComponent";
import FileList from "../../../../components/FileListComponent";
import { uniqueId } from "lodash";
import filesize from "filesize";
import api from '../../../../services/api';
import baseURL from '../../baseURL';

export default class UploadAreaCliente extends Component {
    
        state = {
            userLogged: useSession(value => value, 'client-session'),
            uploadedFiles: [],
            dados: {
                title: "Área do Cliente - Upload"
            },
            loading: false
        };

        handleUpload = files => {
            const uploadedFiles = files.map(file => ({
                file,
                id: uniqueId(),
                name: file.name,
                readableSize: filesize(file.size),
                preview: URL.createObjectURL(file),
                progress: 0,
                uploaded: false,
                error: false,
                url: null
            }));

            this.setState({
                uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
            });

            uploadedFiles.forEach(this.processUpload);
        };

        updateFile = (id, data) => {
            this.setState({
            uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
                return id === uploadedFile.id
                ? { ...uploadedFile, ...data }
                : uploadedFile;
            })
            });
        };

        processUpload = async (uploadedFile) =>{
            const data = new FormData();
            data.append("file", uploadedFile.file, uploadedFile.name);
            await api
                .post(`${baseURL}/posts/${this.state.userLogged.who === 'fabricantes' ? this.state.userLogged.user[0].FC_NOME : this.state.userLogged.user[0].CC_NOME}/${this.props.match.params.idCer}`, data, {
                onUploadProgress: e => {
                    const progress = parseInt(Math.round((e.loaded * 100) / e.total));

                    this.updateFile(uploadedFile.id, {
                        progress
                    });
                }
                })
                .then(response => {
                    this.updateFile(uploadedFile.id, {
                        uploaded: true,
                        id: response.data.affectedRows
                    });
                })
                .catch(() => {
                this.updateFile(uploadedFile.id, {
                    error: true
                    });
                });
        };

        handleDelete = async (id) => {
            await api.delete(`${baseURL}/posts/${id}`);

            this.setState({
                uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id)
            });
        };

        handleConclued = async () => {
            this.setState({
                loading: true
            });
            await api.get(`${baseURL}/sendConclued/${this.props.match.params.idCer}`);
            this.props.history.goBack()
            this.setState({
                loading: false
            });
        }

        render(){
            document.title = `${this.state.dados.title}`;
            return(
                <Container>
                    <Loading load={this.state.loading} />
                    <TopMenu />
                    <div className="content">
                        <AreaComponent title="Upload de documentos" theme="warning">
                            <Upload onUpload={this.handleUpload} />
                            <div className="buttonContainer">
                                <button onClick={this.handleConclued} className="submit">
                                <FontAwesomeIcon icon={faCheckCircle} /> Concluir Envio
                                </button>
                            </div>
                            {!!this.state.uploadedFiles.length && (
                                <FileList files={this.state.uploadedFiles} onDelete={this.handleDelete} />
                            )}
                        </AreaComponent>
                    </div>

                </Container>
            );
        }
}
