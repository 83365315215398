/*
* Styles Component de CadastroServicos
*/
import styled, { keyframes } from 'styled-components';
import * as constants from '../../../constants/styles';

const {
    third,
    white,
    secondary,
    warning,
    def,
    primary,
    yellow,
    danger,
    subtitle
} = constants

const rotate = keyframes`
    0% {
    }
    50% {
        color: white;
    }
    100% {
    }
`;


export const Container = styled.div.attrs(props => ({
    theme: props.theme
}))`
    min-height: 100%;
    background: ${third};
    display: flex;
    flex-direction: row;
    .container-button {
        padding: 20px 40px 0px 0;
    }
    .container-btn-close {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btn-primary {
            border: none;
            background-color: ${danger};
            color: ${white};
            padding: 10px;
        }
    }
    div.titleContainer {
        display: flex;
        flex-direction: column;
        span.title {
            font-size: 16pt;
            font-weight: 800;
            color:  ${primary};
        }
        span.subTitle {
            color: ${subtitle};
            font-size: 11pt;
        }
    }
    div.load {
        position: absolute;
        display: none;
        min-width: 100%;
        min-height: 100%;
        z-index: 9999;
        background: rgba(255, 255, 255, .8);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 100px;
        img {
            background: ${primary};
            padding: 30px;
            border-radius: 20px;
            width: 300px;
        }
        span {
            font-size: 20pt;
            color: ${primary};
            animation: ${rotate} .5s linear infinite;
        }
    }
    div.load.inProcess {
        display: flex;
    }

    div.top-button {
        width: 99%;
        margin: 10px 0 0 0;
        button {
            background: #38726C;
            border: none;
            color: ${white};
            padding: 10px 15px;
            font-size: 12pt;
            border-radius: 20px;
            box-shadow: 3px 3px 3px #ccc;
        }
    }

    div.area {
        max-height: 600px;
        overflow-x:hidden;
        overflow-y:auto;
        padding: 0;
    }
    div.containerButtonNew {
        width: 99%;
        display: flex;
        justify-content: start;
        margin-top: 15px;
        margin-bottom: -30px;
        button {
            padding: 10px 20px;
            border: none;
            background: ${def};
            color: ${white};
            border-radius: 5px;
            font-size: 14pt;

            svg {
                margin-right: 10px;
            }
        }
    }

    div.container-areas {
        display:grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;

        div.table {
            width: 100%;
            display: flex;
            padding: 5px 0px;
            justify-content: center;
            align-items: center;

            table.warning {
                th {
                    background: ${warning};
                }
            }

            table.def {
                th {
                    background: ${def};
                }
            }
            table {
                width: 100%;
                margin: 0;
                th {
                    padding: 15px;
                    background: ${props => constants[props.theme] ? constants[props.theme] : secondary};
                    color: ${white};
                    text-align: start;
                }
                td {
                    padding: 15px;
                    button {
                        border: none;
                        border-radius: 200px;
                        padding: 5px;
                        margin: 1px;
                        color: ${white};
                        width: 25px;
                        font-size:9pt;
                        box-shadow: 3px 1px 5px #ccc;
                    }
                    button.upload {
                        background: ${warning};
                    }
                    button.disabled {
                        background: #aaa;
                        color: #eee;
                    }
                    button.continue {
                        background: ${secondary};
                    }
                    button.edit {
                        background: ${secondary};
                    }
                    button.etapas {
                        background: ${primary};
                    }
                    button.print {
                        background: ${primary};
                    }
                    button.conclued {
                        background: ${def};
                    }
                    button.inclonclued {
                        background: ${yellow};
                    }
                    button.canceled {
                        background: ${danger};
                    }
                    button.see {
                        background: ${primary};
                    }
                }
                tr {
                    background: ${white};
                    &:nth-child(even) {
                        background: ${third};
                    }
                }

            }
        }
    }
    @media only screen and (max-width: 360px) {
        div.area {
            width: 88vw;
            overflow-x:scroll;
        }
        div.container-areas {
            grid-template-columns: 1fr;
        }
    }
    @media only screen and (min-width: 360px) and (max-width: 768px) {
        div.container-areas {
            grid-template-columns: 3fr;
        }
    }

`;


export const Process = styled.td`
    ${props => props.process};
    font-weight: 400;
`;

