import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import logo from '../../../../assets/img/logo.png';
// import CryptoJS from "crypto-js";


export default function AreaCliente(props) {
    const [ code, setCode ] = useState("");
    const { push } = useHistory();
    const dados = {
        title: "Area do Cliente"
    };

    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`;
    }, [dados.title]);

    async function handleAuthenticate(){
        // var codeId = CryptoJS.AES.encrypt(code, 'secret key 50271541').toString();
        push(`/certificate/status?code=${code}`)
    }

    return(
    <Container>
        <div className="background"/>
        <form className="login" onSubmit={e => handleAuthenticate(e)}>
            <img src={logo} alt="Logo"/>
            <span>Consultar Certificado</span>
            <input type="text" placeholder="Número do certificado" value={code} onChange={({target}) => setCode(target.value)} className="pass"/>
            <button className="submit" onClick={() => handleAuthenticate()}>
                Entrar
            </button>
        </form>
    </Container>
    );
}
