/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/* eslint-disable no-fallthrough */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract, faCertificate } from '@fortawesome/free-solid-svg-icons';
import MenuComponent from '../../../../components/MenuComponent';
import ContentComponent from '../../../../components/ContentComponent';
import AreaComponent from '../../../../components/AreaComponent';
import { Form, Input } from '../../../../components/Form/';

import Select from 'react-select';
import Models from '../../../../models';
import { Container } from './styles';
import baseURL from '../../baseURL';
import AnexaA from './components/AnexaA';
import RelacaoDocuments from './components/RelacaoDocuments';
import Descricao from './components/Descricao';
import { addMonths, parseISO, format, isValid } from 'date-fns';


export default function CRUDCertificados(props) {
    const { id, requerent, who } = props.match.params;
    const { replace } = useHistory();
    // const userLogged = useSession(value => value.user ? value.user[0] : value);
    const dados = {
        title: "Certificados",
        subtitle: "Emitindo certificado",
        bread: ['Certificados', 'Emitindo' ],
        theme: 'primary'
    };
    const [ modelo, setModelo ] = useState([]);
    const [ modelos, setModelos ] = useState([]);

    const [ fabricantes, setFabricantes ] = useState([]);
    const [ assinantes, setAssinantes ] = useState([]);
    const [ responsaveis, setResponsaveis ] = useState([]);
    const [ formData, setFormData ] = useState({});
    const [ validade, setValidade ] = useState(0);
    const [ carregando, setCarregando ] = useState(false);
    const [ fabricante, setFabricante ] = useState();

    useEffect(() => { init(); }, [])
    useEffect(() => { document.title = `${dados.title} - QCDOCS`; }, [dados.title])

    async function init(){
        setCarregando(true);
        //SETA OS FABRICANTES
        const resFabs = await Models.fabricantesModel['INDEX']();
        setFabricantes(resFabs.map(fab => ({label: fab.FAB_RAZAO_SOCIAL, value: fab.FAB_ID})));
        //SETA OS ASSINANTES
        const resAss = await Models.assinantesModel['INDEX']();
        setAssinantes(resAss.map(ass => ({label: ass.CERTA_NOME, value: ass.CERTA_ID})));
        //SETA OS RESPONSAVEIS
        const resResp = await Models.usuariosModel['INDEX']();
        setResponsaveis(resResp.filter(user => user.USU_FK_RESP_ID === 1).map(ass => ({label: ass.USU_NAME, value: ass.USU_ID})));
        //SELECIONA O CERTIFICADO
        const resCertificado = await Models.certificadosModel['SHOW'](id);
        //SETA MODELOS
        const resModelos = await Models.modelos_certificadosModel['INDEX_SERVICE']();
        setModelo(resModelos.filter(model => model.SER_ID === resCertificado[0].CER_FK_SER_ID));
        setModelos(resModelos);
        //SELECIONA O FABRICANTE
        const resFab = await Models.fabricantesModel['SHOW'](resCertificado[0].CER_FK_FAB_ID);
        setFabricante(resFab[0] ? {label: resFab[0].FAB_RAZAO_SOCIAL, value: resFab[0].FAB_ID} : []);
        //SELECIONA A DATA DE EMISSÃO
        const {
            CER_ARQUIVO_TECNICO_QC,
            CER_DATA_EMISSAO,
            CER_DATA_VALIDADE,
            CER_FK_CERTA_ID,
            CER_FK_USU_ID,
            CER_LISTA_ACESSORIO,
            CER_NUMERO_PROJETO,
            CER_PRODUTO,
            CER_MODELO,
            CER_NORMAS_APLICAVEIS,
            CER_RELATORIO_AUDITORIA,
            CER_RELATORIO_ENSAIO,
            CER_ESCOPO,
            CER_SCOPE,
            CER_ITENS_NAO_APLICAVEIS,
            CER_NOT_APPLICABLE_ITEMS,
            CER_DATA_EMISSAO_MANUTENCAO
        } = resCertificado[0];
        var resFormData = {
            CER_ARQUIVO_TECNICO_QC,
            CER_DATA_EMISSAO,
            CER_DATA_VALIDADE,
            CER_FK_CERTA_ID,
            CER_FK_USU_ID,
            CER_LISTA_ACESSORIO,
            CER_NUMERO_PROJETO,
            CER_PRODUTO,
            CER_MODELO,
            CER_NORMAS_APLICAVEIS,
            CER_RELATORIO_AUDITORIA,
            CER_RELATORIO_ENSAIO,
            CER_ESCOPO,
            CER_SCOPE,
            CER_ITENS_NAO_APLICAVEIS,
            CER_NOT_APPLICABLE_ITEMS,
            CER_DATA_EMISSAO_MANUTENCAO
        }
        if(resCertificado[0].CER_STATUS === 4) resFormData = {...resFormData, CER_STATUS: 0}
        setFormData(resFormData)
        //SELECIONA O SERVIÇO
        const resServico = await Models.servicosModel['SHOW'](resCertificado[0].CER_FK_SER_ID)
        setValidade(resServico[0] ? resServico[0].SER_VALIDADE : "");
        setCarregando(false);
    }

    async function handleSelectModel(model) {
        setModelo([model])
        setValidade(model.SER_VALIDADE);
        await Models.certificadosModel['UPDATE'](id, { CER_FK_SER_ID: model.SER_ID })
    }

    async function handleSelectManufactory(manufactory) {
        setFabricante(manufactory);
        await Models.certificadosModel['UPDATE'](id, { CER_FK_FAB_ID: manufactory.value })
    }

    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
                        <AreaComponent title="Modelo do certificado" theme={dados.theme}>
                            {
                                modelos.length > 0 && modelo ?
                                    <ul className="models">
                                        {
                                            modelos.filter(model => model.SER_STATUS).map((model, key) => {
                                                let select = modelo[0] === model;
                                                return(
                                                    <li className={select ? 'select' : ''} key={key} onClick={() => !select ? handleSelectModel(model) : {}}>
                                                        <FontAwesomeIcon icon={faFileContract} />
                                                        <span>{model.SER_NOME}<br/>({model.SER_PORTARIA})</span>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                :
                                    <div className="empty-model">
                                        <span>{carregando ? "Carregando..." : "Não há serviços cadastrados!"}</span>
                                    </div>
                            }
                        </AreaComponent>
                        <AreaComponent title="Fabricante" theme={dados.theme}>
                            <div className="fabricante">
                                <Select
                                    isClearable
                                    value={fabricante}
                                    onChange={e => handleSelectManufactory(e)}
                                    placeholder={carregando ? "Carregando..." : "Selecione o fabricante"}
                                    options={fabricantes}
                                    noOptionsMessage={() => 'Sem opções'} />
                            </div>
                        </AreaComponent>
                                <div className="editorArea">
                                    <AreaComponent title="Formulário de Emissão" theme={dados.theme}>
                                    {
                                        !carregando ?
                                        <Form
                                        id={id}
                                        data={formData}
                                        model='certificadosModel'
                                        returnFunction={() => replace(`${baseURL}/${who}/${requerent}`)}
                                        submitIcon={<FontAwesomeIcon icon={faCertificate} />}
                                        submitText='Salvar'>
                                        <Input span="Número do Projeto" type="text" name="CER_NUMERO_PROJETO" size='30%' />
                                        <Input span="Produto" type="text" name="CER_PRODUTO" size='30%' />
                                        <Input span="Modelo" type="text" name="CER_MODELO" size='30%' />
                                        <Input span="Responsável" type="select" name="CER_FK_USU_ID" options={responsaveis} size='30%' />
                                        <Input span="Normas Aplicáveis" type="textarea" name="CER_NORMAS_APLICAVEIS" normal size='30%' />
                                        <Input span="Relatório de Ensaio" type="textarea" normal name="CER_RELATORIO_ENSAIO" size='30%' />
                                        <Input span="Arquivo Técnico da QC" type="text" name="CER_ARQUIVO_TECNICO_QC" size='30%' />
                                        <Input span="Relatório de auditoria" type="text" name="CER_RELATORIO_AUDITORIA" size='30%' />
                                        <Input span="Lista de acessórios (Se aplicável)" type="textarea" normal name="CER_LISTA_ACESSORIO" size='30%' />
                                        <Input span="Assinante" type="select" name="CER_FK_CERTA_ID" options={assinantes} maxLength={2} size='30%' />
                                        <Input
                                            span="Data de Emissão"
                                            type="date"
                                            name="CER_DATA_EMISSAO"
                                            size='30%'
                                            onChange={(e, allData) => {
                                                allData.CER_DATA_EMISSAO = e;
                                                allData.CER_DATA_VALIDADE = isValid(parseISO(e)) ? format(addMonths(parseISO(e), validade), "yyyy'-'MM'-'dd") : "";
                                                return allData
                                            }}
                                        />
                                        <Input
                                            span="Data de Emissão do certificado (Caso Manutenção)"
                                            type="date"
                                            name="CER_DATA_EMISSAO_MANUTENCAO"
                                            size='30%'
                                            onChange={(e, allData) => {
                                                allData.CER_DATA_EMISSAO_MANUTENCAO = e;
                                                allData.CER_DATA_VALIDADE_MANUTENCAO = isValid(parseISO(e)) ? format(addMonths(parseISO(e), validade), "yyyy'-'MM'-'dd") : "";
                                                return allData
                                            }}
                                        />
                                        <Input span="Valor em dB (A) (Caso Potência Sonora)" type="text" name="CER_VALOR_DB" size='30%' />
                                        <Input span="Escopo (Caso OMD)" type="textarea" name="CER_ESCOPO" normal size='30%' />
                                        <Input span="Scope (Caso OMD)" type="textarea" name="CER_SCOPE" normal size='30%' />
                                        <Input span="Itens não aplicáveis (Caso OMD)" type="textarea" name="CER_ITENS_NAO_APLICAVEIS" normal size='30%' />
                                        <Input span="Not Applicable items (Caso OMD)" type="textarea" name="CER_NOT_APPLICABLE_ITEMS" normal size='30%' />
                                        <Input
                                            type="hidden"
                                            name="CER_DATA_VALIDADE"
                                        />
                                    </Form>
                                        :
                                        <div className="empty-model">
                                            <span>Carregando...</span>
                                        </div>
                                    }
                                    </AreaComponent>
                                    <div className="containerArea">
                                        <AreaComponent title="Anexo A" theme="primary">
                                            {
                                                !carregando ?
                                                    <AnexaA
                                                        id={id} />
                                                :
                                                <div className="empty-model">
                                                    <span>Carregando...</span>
                                                </div>
                                            }
                                        </AreaComponent>
                                        <AreaComponent title="Relação de Documentos" theme="primary">
                                            {
                                                !carregando ?
                                                    <RelacaoDocuments
                                                        id={id} />
                                                :
                                                <div className="empty-model">
                                                    <span>Carregando...</span>
                                                </div>
                                            }
                                        </AreaComponent>
                                        <AreaComponent title="Histórico" theme="primary">
                                            {
                                                !carregando ?
                                                    <Descricao
                                                        id={id} />
                                                :
                                                <div className="empty-model">
                                                    <span>Carregando...</span>
                                                </div>
                                            }
                                        </AreaComponent>
                                    </div>
                                </div>
        </ContentComponent>
    </Container>
    );
}
