/*
* Styles Component de CadastroServicos
*/
import styled from 'styled-components';
import bg from '../../../../../assets/img/bg_servicos.png'
import * as constants from '../../../../../constants/styles';

const {
    warning,
    primary
} = constants

export const Container = styled.div`
    min-height: 100%;
    background-image: url(${bg});
    background-size: cover;
    padding: 0;
    div.content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 80vh;

        button {
            border: none;
            padding: 10px 20px;
            font-size: 18pt;
            margin: 10px;
            background: ${warning};
            color: white;
        }
    }
`;

export const Info = styled.div`
    min-height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 25vw;
        padding-top: 35px;
    }
    span b {
        font-size: 2vw;
        color: ${primary}
    }
    p {
        font-size: 1.5vw;
        margin: 20px;
        color: ${primary}
    }

`;
