import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js';
import 'chartjs-plugin-labels';
import { Container } from './styles';

export default function ChartComponent({ title, options = { type: 'bar' } }) {
    const ctx = useRef();
    useEffect(() => {
        if (ctx.current) new Chart(ctx.current.getContext('2d'), {
            ...options,
            options: {
                legend: {
                    display: false
                },
                responsive: true,
                title: {
                    display: true,
                    fontSize: 16,
                    text: title
                },
                elements: {
                    arc: {
                        borderWidth: 1
                    }
                },
                tooltips: {
                    enabled: true
                },
                onClick: (evt, item) => {
                    if(!item[0]) return;
                    item[0]._model.outerRadius += 10
                },
                plugins: {
                    legend: true,
                    labels: [
                        {
                            render: 'value',
                            position: 'border',
                            textMargin: 10,
                            arc: false,
                            fontColor: "#fff",
                            fontSize: 18
                        },
                        {
                            render: 'label',
                            position: 'outside',
                            textMargin: 10,
                            arc: false
                        }
                    ]
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctx])

    return (
        <Container className='chart'>
            <canvas ref={ctx} />
        </Container>
    )
}

