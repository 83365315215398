/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faEdit, faPhone, faEye } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import MenuComponent from '../../components/MenuComponent/';
import ContentComponent from '../../components/ContentComponent/';
import AreaComponent from '../../components/AreaComponent/';
import { Container } from './styles';
import * as Model from '../../models/clientesModel';
import baseURL from './baseURL';
import baseURLContacts from '../Contatos/baseURL';
import baseURLProfile from '../Profile/baseURL';
import { verifyString, verifyTableString, captalizeString, debounce, sortFunctions, handleSort } from '../../utils';

export default function Clientes() {
    const [ lines, setLines ] = useState([]);
    const filtro = useRef();
    const [sort, setSort] = useState({ currentSort: 'up', column: '' });
    const sortColumns = column => handleSort(column, sort, setSort);
    const { push } = useHistory();
    const dados = {
        title: "Clientes",
        subtitle: "Todos os Clientes",
        bread: ['Clientes']
    };

    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`;
        init();
    }, []);

    async function handleSearch(){
        if(filtro.current.value === "") { init(); return; };
        const response = await Model['SEARCH'](filtro.current.value);
        setLines(response || [])
    }

    async function init(){
        setLines(await Model['INDEX']())
    }

    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <div className="containerButtonNew">
                <input ref={filtro} placeholder="Filtrar..." onChange={debounce(handleSearch)}/>
                <button onClick={() => push(`${baseURL}/cadastro`)}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    Novo
                </button>
            </div>
            <AreaComponent>
                <table>
                    <thead>
                        <tr>
                            <th onClick={() => sortColumns('CLI_RAZAO_SOCIAL')}>Razão Social</th>
                            <th onClick={() => sortColumns('CLI_ENDERECO')} width={300}>Endereço</th>
                            <th onClick={() => sortColumns('CLI_CNPJ')} width={300}>CNPJ</th>
                            <th onClick={() => sortColumns('CLI_TELEFONE')} width={300}>Telefone</th>
                            <th width={150}>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lines
                            .sort(sortFunctions[sort.currentSort](sort.column))
                            .map((line, key) => {
                                return (
                                    <tr key={key}>
                                        <td title={line.CLI_RAZAO_SOCIAL}>{line.CLI_RAZAO_SOCIAL}</td>
                                        {
                                            line.CLI_PAIS !== 'Brasil' ?
                                                <>
                                                    <td title={`${line.CLI_ENDERECO}, ${captalizeString(line.CLI_PAIS)}`}>{line.CLI_ENDERECO}, {captalizeString(line.CLI_PAIS)} </td>
                                                    <td> - </td>
                                                </>
                                            :
                                            <>
                                                <td title={`${verifyTableString(line.CLI_ENDERECO)}${verifyTableString(line.CLI_NUMERO)}${verifyTableString(line.CLI_COMPLEMENTO)}${verifyTableString(line.CLI_BAIRRO)}${verifyTableString(line.CLI_CEP)}${verifyTableString(line.CLI_CIDADE)}${verifyTableString(line.CLI_ESTADO)}${captalizeString(line.CLI_PAIS)}`}>
                                                    {verifyTableString(line.CLI_ENDERECO)}{verifyTableString(line.CLI_NUMERO)}{verifyTableString(line.CLI_COMPLEMENTO)}{verifyTableString(line.CLI_BAIRRO)}{verifyTableString(line.CLI_CEP)}{verifyTableString(line.CLI_CIDADE)}{verifyTableString(line.CLI_ESTADO)}{captalizeString(line.CLI_PAIS)}
                                                </td>
                                                <td>{verifyString(line.CLI_CNPJ)}</td>
                                            </>
                                        }
                                        <td>{verifyString(line.CLI_TELEFONE)}</td>
                                        <td>
                                            <button className="action edit" title="Editar" onClick={() => push(`${baseURL}/editar/${line.CLI_ID}`)}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                            <button className="action contacts" title="Ver Contatos" onClick={() => push(`${baseURLContacts}/clientes/${line.CLI_ID}`)}>
                                                <FontAwesomeIcon icon={faPhone} />
                                            </button>
                                            <button className="action profile" title="Perfil" onClick={() => window.open(`${baseURLProfile}/clientes/${line.CLI_ID}`)}>
                                                <FontAwesomeIcon icon={faEye} />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </AreaComponent>
        </ContentComponent>
    </Container>
    );
}
