export const transition = '0.5s';

export const primary = '#4e77af';
export const secondary = '#71abd3';
export const third = '#eef8fd';

export const subtitle = '#888';

export const boxShadow = '#ccc';

export const breadcrumbText = '#aaa';
export const breadcrumbBorder = '#999';

export const white = '#fff';
export const whiteLight = '#fefefe';
export const whiteDark = '#efefef';
export const grayDark = '#555';

export const yellow = '#ddee45';
export const warning = '#ffaa45';
export const danger = '#dd4c34';
export const def = '#2abbac';

