import * as servicosModel from './servicosModel';
import * as clientesModel from './clientesModel';
import * as fabricantesModel from './fabricantesModel';
import * as fabricantes_contatosModel from './fabricantes_contatosModel';
import * as clientes_contatosModel from './clientes_contatosModel';
import * as cargosModel from './cargosModel';
import * as assinantesModel from './assinantesModel';
import * as modelos_certificadosModel from './modelos_certificadosModel';
import * as certificadosModel from './certificadosModel';
import * as certificados_anexosModel from './certificados_anexosModel';
import * as certificados_relacao_documentosModel from './certificados_relacao_documentosModel';
import * as certificados_descricaoModel from './certificados_descricaoModel';
import * as usuariosModel from './usuariosModel';
import * as portalModel from './portalModel';
import * as documentosModel from './documentosModel';
import * as faqModel from './faqModel';
import * as etapasModel from './etapasModel';
import * as andamentoModel from './andamentoModel';

export default {
    servicosModel,
    clientesModel,
    fabricantesModel,
    fabricantes_contatosModel,
    clientes_contatosModel,
    cargosModel,
    assinantesModel,
    modelos_certificadosModel,
    certificadosModel,
    usuariosModel,
    portalModel,
    certificados_anexosModel,
    certificados_relacao_documentosModel,
    certificados_descricaoModel,
    documentosModel,
    faqModel,
    etapasModel,
    andamentoModel
}
