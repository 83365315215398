/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Container, Info } from './styles';
import TopMenu from '../../components/TopMenu';
import logo from '../../../../assets/img/logo2.png';
import { useSession } from '../../../../utils';

export default function HomeAreaCliente() {
    const { push } = useHistory();
    const userLogged = useSession(value => value, 'client-session');

    const dados = {
        title: "Área do Cliente - Home"
    };


    useEffect(() => {
        document.title = `${dados.title}`;
    }, [dados.title]);

    return(
    <Container>
        <TopMenu />
        <div className="content">
            <Info>
                <span><b>Bem-vindo à área da {userLogged.who === 'fabricantes' ? userLogged.user[0].FAB_RAZAO_SOCIAL : userLogged.user[0].CLI_RAZAO_SOCIAL}</b></span>
                <img src={logo} alt="Logo"/>
                <p>Dando <b>vida</b> às grandes <b>ideias!</b></p>
            </Info>
            <button onClick={() => push('/portal/dashboard')}>
                <FontAwesomeIcon icon={faCertificate} /> Acessar sua área restrita
            </button>
        </div>
    </Container>
    );
}
