/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlayCircle, faShoePrints, faEye, faPowerOff, faMinusCircle, faStop, faTimesCircle, faEdit, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import MenuComponent from '../../components/MenuComponent/';
import ContentComponent from '../../components/ContentComponent/';
import AreaComponent from '../../components/AreaComponent/';
import Model from '../../models';
import baseURL from './baseURL';
import swal from '@sweetalert/with-react';
import { isAfter } from 'date-fns';
import Status from '../../data/status';
import { verifyString, handleSort, sortFunctions } from '../../utils';
import logo from '../../assets/img/logo.png';
import ModalInit from './components/ModalInit';
import ModalAndamento from './components/ModalAndamento';
import axios from 'axios';

import { Container, Process } from './styles';

export default function Profile(props) {
    const { id, who } = props.match.params
    const modelWho = who === 'fabricantes' ? Model.fabricantesModel : Model.clientesModel;
    const modelCertificados = (id) => who === 'fabricantes' ? Model.certificadosModel['FABRICANTES'](id) : Model.certificadosModel['CLIENTES'](id);
    const [ andamento, setAndamento ] = useState([]);
    const [ certificados, setCertificados ] = useState([]);
    const [ dados, setDados ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [sort, setSort] = useState();
    const [dataEtapa, setDataEtapa] = useState('');
    const sortColumns = column => handleSort(column, sort, setSort);
    const { push } = useHistory();

    useEffect(() => { init() }, []);
    useEffect(() => { document.title = `${dados.title} - QCDOCS`; }, [dados.title]);

    async function init(){
        var dados = {
            title: "",
            subtitle: "Perfil",
            bread: ['Perfil', who === 'fabricantes' ? 'Fabricantes' : 'Clientes' ]
        };
        var certificados = await modelCertificados(id);
        setCertificados(certificados.map(certificado => {
            if(parseInt(certificado.SER_INDETERMINADO) === 1){
                return certificado
            }
            //SUSPENDE CERTIFICADO
            if(!!certificado.CER_DATA_VALIDADE && isAfter(new Date(), new Date(certificado.CER_DATA_VALIDADE)) && certificado.CER_STATUS !== 3){
                certificado = {...certificado, CER_STATUS: 2 }
                handleChangeStatus(certificado.CER_ID, 2, false);
                return certificado
            }
            return certificado
        }));
        const requerent = await modelWho['SHOW'](id);
        switch(who){
            case 'fabricantes':{
                dados.title = requerent[0].FAB_RAZAO_SOCIAL;
                dados.bread.push(requerent[0].FAB_RAZAO_SOCIAL);
                setDados(dados);
                break;
            }
            case 'clientes': {
                dados.title = requerent[0].CLI_RAZAO_SOCIAL;
                dados.bread.push(requerent[0].CLI_RAZAO_SOCIAL);
                setDados(dados);
                break;
            }
        }

    }

    async function handleLoadDocuments(id, status){
        setLoading(true);
        push(`${baseURL}/clientes/${props.match.params.id}/documentos/${id}/${status}`);
        setLoading(false);
    }

    async function handleLoadAndamento(certificateId, serviceID){
        setLoading(true);
        setAndamento([])
        const { 
            etapasModel: { SHOW },
            andamentoModel: { STORE, SHOW: SHOWANDAMENTO }
        } = Model
        const etapasResponse = await SHOW(serviceID);
        const andamentoResponse = await SHOWANDAMENTO(certificateId);
        const gerarEtapasAndamentos = filter => Promise.all(
            etapasResponse
            .filter(etapa => typeof filter === 'object' ? !filter.includes(etapa.ETA_NOME) : filter)
            .map(async etapa => {
                const body = {
                    AND_ETAPA: etapa.ETA_NOME,
                    AND_FK_CER_ID: certificateId,
                    AND_FK_ETA_ID: etapa.ETA_ID
                }
                const { insertId: AND_ID } = await STORE(body)

                return { AND_ID, ...body}
            }))
            .then(andamentoData => setAndamento([...andamentoResponse, ...andamentoData]))
            .then(() => setLoading(false))

        if(andamentoResponse.length <= 0 || etapasResponse.length > andamentoResponse.length) 
            return gerarEtapasAndamentos(andamentoResponse.length > 0 ? andamentoResponse.map(andamento => andamento.AND_ETAPA) : true)

        setAndamento(andamentoResponse)
        setLoading(false)
    }


    const _errorCertificate = (response) => {
        setLoading(false)
        swal('Foi encontrado um erro no modelo',
        `${response.value.id}
        ${response.value.explanation} `, 'error');
    }

    const _printCertificate = async (id, type='pdf') => {
        const res = await Model.certificadosModel['DOWNLOAD'](id, type);
        axios({
            url: res.link,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${res.name}`);
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        });
    }

    const _openLink = (link) => {
        setLoading(false);
        return window.open(link);
    }

    async function handleSeeCertficate(id){
        setLoading(true)
        const res = await Model.certificadosModel['DOWNLOAD'](id, 'pdf');
        const res2 = await Model.certificadosModel['DOWNLOAD'](id, 'docx');
        setLoading(false)
        swal({
            title: "Como deseja abrir o certificado?",
            text: "",
            icon: "info",
            dangerMode: false,
            buttons: ['Imprimir', 'Visualizar'],
          })
        .then(async (willDelete) => {
            if (willDelete) {
                setLoading(true)
                const linkCertificado = await Model.certificadosModel['EMITIR'](id);
                switch(linkCertificado.status){
                    case true: {
                        _openLink(linkCertificado.value);
                        break;
                    }
                    default: {
                        _errorCertificate(linkCertificado)
                    }
                }
                return null;
            }
            swal({
                title: "Selecione o tipo do documento",
                text: "",
                icon: "info",
                content: <div><a class="download-link" href={`https://${res.link}`} download>Imprimir PDF</a> <a class="download-link" href={`https://${res2.link}`} download>Imprimir Docx</a></div>,
                dangerMode: false,
                buttons: false,
            })
        });
    }

    async function handleChangeStatus(id, value, show){
        if(value !== 3){
            const res = await Model.certificadosModel['UPDATE'](id, { CER_STATUS: value })
            if(show === undefined){
                if (res.affectedRows > 0) {
                    swal("Sucesso!", "o status foi alterado", 'success', { buttons: false, timer: 1000 })
                } else {
                    swal("Ops!", "ocorreu um erro ao alterar! tente novamente mais tarde!", 'error')
                }
                init();
            }
            return
        }
        swal({
            title: "Você tem certeza?",
            text: "Uma vez cancelado, você não poderá mais editar o certificado!",
            icon: "warning",
            dangerMode: true,
            buttons: true,
          })
        .then(async (willDelete) => {
            if (willDelete) {
                const res = await Model.certificadosModel['UPDATE'](id, { CER_STATUS: value })
                if(show === undefined){
                    if (res.affectedRows > 0) {
                        swal("Sucesso!", "o status foi alterado", 'success', { buttons: false, timer: 1000 })
                    } else {
                        swal("Ops!", "ocorreu um erro ao alterar! tente novamente mais tarde!", 'error')
                    }
                    init();
                }
            }
        });
    }

    function handleInitProcess(){
        swal(<ModalInit {...{id, who, init}} />,{ buttons: false })
    }


    async function handleChangeStatusEtapas(id, data){
        const { UPDATE } = Model.andamentoModel
        UPDATE(id, data)

        const andamentoData = andamento.map(item => {
            if(item.AND_ID === id) {
                return { ...item, ...data }
            }
            return item
        })

        setAndamento(andamentoData)
    }

    async function handleDelete(id){
        swal({
            title: "Você tem certeza?",
            text: "Uma vez removido, você não poderá mais recuperar os dados dessa etapa!",
            icon: "warning",
            dangerMode: true,
            buttons: true,
          })
          .then(async (willDelete) => {
              if(!willDelete) return;

              await Model.andamentoModel['DELETE'](id);
              setAndamento(andamento.filter(item => item.AND_ID !== id))
          })
    }

    return(
    <Container>
        <div className={`load ${loading ? "inProcess" : ""}`}>
            <img src={logo} alt="QCCERT" />
            <span>Carregando... </span>
        </div>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <div className="top-button">
                <button onClick={() => handleInitProcess()}>
                    <FontAwesomeIcon icon={faPowerOff} /> Iniciar Processo
                </button>
            </div>
            <div className="container-areas">
                <AreaComponent theme="def" title="Certificados">
                    <div className="table">
                        <table className="def">
                            <thead>
                                <tr>
                                    <th onClick={() => sortColumns('CER_NUMERO')} width={100}>Número</th>
                                    <th onClick={() => sortColumns('SER_NOME')} width={300}>Tipo</th>
                                    <th onClick={() => sortColumns('CER_STATUS')} width={100}>Status</th>
                                    <th onClick={() => sortColumns('responsavel')} width={100}>Responsável</th>
                                    <th onClick={() => null}  width={230}>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    certificados
                                    .sort(sort ? sortFunctions[sort.currentSort](sort.column) : () => true)
                                    .map((line, key) => {
                                        var status = line.CER_STATUS;
                                        return (
                                            <tr process={Status[line.CER_STATUS].theme} key={key}>
                                                <td  onClick={() => handleLoadDocuments(line.CER_ID, status)} className="click">
                                                    {verifyString(line.CER_NUMERO)}
                                                    {line.notification !== 0 && <span className="notification">{line.notification}</span>}
                                                </td>
                                                <td title={verifyString(line.SER_NOME)}>{verifyString(line.SER_NOME)}</td>
                                                <Process process={Status[line.CER_STATUS].theme}>{!!line.CER_DATA_EMISSAO_MANUTENCAO ? line.CER_STATUS === 1 ? 'Concluído' : Status[line.CER_STATUS].label : Status[line.CER_STATUS].label}</Process>
                                                <td>{verifyString(line.responsavel)}</td>
                                                <td>
                                                    <button className={![4].includes(status) ? "print" : "disabled"} title="Ver Certificado" onClick={() => handleSeeCertficate(line.CER_ID)}>
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </button>

                                                    <button disabled={status === 3} className={status !== 3 ? "continue" : "disabled"} title="Prosseguir Certificado" onClick={() => push(`${baseURL}/editar/${line.CER_FK_CLI_ID ? 'clientes' : 'fabricantes'}/${line.CER_ID}/${line.CER_FK_CLI_ID ? line.CER_FK_CLI_ID : line.CER_FK_FAB_ID}`)}>
                                                        <FontAwesomeIcon icon={faPlayCircle} />
                                                    </button>
                                                    {
                                                        [0].includes(status) ?
                                                            <button disabled={[3].includes(status)} className={![3].includes(status) ? "conclued" : "disabled"} title="Concluir Certificado" onClick={() => handleChangeStatus(line.CER_ID, 1)}>
                                                                <FontAwesomeIcon icon={faCheck} />
                                                            </button>
                                                        :
                                                                <button disabled={[3].includes(status)} className={![3].includes(status) ? "inclonclued" : "disabled"} title="Mudar status para em processo" onClick={() => handleChangeStatus(line.CER_ID, 0)}>
                                                                    <FontAwesomeIcon icon={faMinusCircle} />
                                                                </button>

                                                    }
                                                    <button className="etapas" title="Ver Etapas" type='button' onClick={(e) => {
                                                        e.preventDefault();
                                                        handleLoadAndamento(line.CER_ID, line.SER_ID)
                                                        setDataEtapa(`${line.SER_NOME} - ${line.CER_NUMERO}`)
                                                    }}>
                                                        <FontAwesomeIcon icon={faShoePrints} />
                                                    </button>
                                                    <button disabled={[3].includes(status)} className={![3].includes(status) ? "canceled" : "disabled"} title="Suspender Certificado" onClick={() => handleChangeStatus(line.CER_ID, 2)}>
                                                        <FontAwesomeIcon icon={faStop} />
                                                    </button>
                                                    <button disabled={status === 3} className={status !== 3 ? "canceled" : "disabled"} title="Cancelar Certificado" onClick={() => handleChangeStatus(line.CER_ID, 3)}>
                                                        <FontAwesomeIcon icon={faTimesCircle} />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </AreaComponent>
                {
                    dataEtapa && 
                    <div>
                        <div className="titleContainer">
                            <span className="title" title={dataEtapa}>{dataEtapa}</span>
                            <span className="subTitle" >Etapas do certificado em questão</span>
                        </div>
                        <div className="container-btn-close">
                            <button className="btn btn-primary" onClick={() => {
                                setDataEtapa()
                                setAndamento([])
                            }}>
                                <FontAwesomeIcon icon={faTimesCircle} /> {'  '}
                                Fechar etapas
                            </button>
                        </div>
                        <AreaComponent theme="secondary">
                            <div className="container-button">
                                {
                                    andamento[0] &&
                                    <button className="btn btn-success" onClick={() =>  swal(<ModalAndamento certificadoID={andamento[0].AND_FK_CER_ID} refresh={{setAndamento, andamento}} />, { buttons: false })}> 
                                        <FontAwesomeIcon icon={faPlusCircle} />{' '}
                                        Novo item
                                    </button>
                                }
                            </div>
                            <div className="table">
                                <table className="secondary">
                                    <thead>
                                        <tr>
                                            <th>Etapa</th>
                                            <th>Status</th>
                                            <th>Observações</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            andamento
                                            .sort((a, b) => a.AND_FK_ETA_ID === null ? 2 : a.AND_FK_ETA_ID > b.AND_FK_ETA_ID ? 1 : -1)
                                            .map((line, key) => {
                                                console.log(line)
                                                return (
                                                    <tr key={key}>
                                                    <td title={verifyString(line.AND_ETAPA)}>                                                        
                                                            {verifyString(line.AND_ETAPA)}
                                                        </td>
                                                        <td>{line.AND_STATUS === 2 ? 'Concluído' : line.AND_STATUS === 1 ? 'Em andamento' : 'Pendente'}</td>
                                                        <td title={verifyString(line.AND_OBSERVACOES)}>
                                                            {verifyString(line.AND_OBSERVACOES)}
                                                        </td>                                
                                                        <td title={verifyString(line.AND_OBSERVACOES)}>
                                                            <button className="print" title="Alterar Dados" onClick={() =>  swal(<ModalAndamento certificadoID={line.AND_FK_CER_ID} changeID={line.AND_ID} refresh={{setAndamento, andamento}} />,{ buttons: false })}>
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                            <button className="canceled" title="Mudar status para Pendente" onClick={() => handleChangeStatusEtapas(line.AND_ID, { AND_STATUS: 0 })}>
                                                                <FontAwesomeIcon icon={faStop} />
                                                            </button>
                                                            <button className="inclonclued" title="Mudar status para em andamento" onClick={() => handleChangeStatusEtapas(line.AND_ID, { AND_STATUS: 1 })}>
                                                                <FontAwesomeIcon icon={faPlayCircle} />
                                                            </button>

                                                            <button className="conclued" title="Mudar status para concluído" onClick={() => handleChangeStatusEtapas(line.AND_ID, { AND_STATUS: 2 })}>
                                                                <FontAwesomeIcon icon={faCheck} />
                                                            </button>

                                                            <button className="canceled" title="Excluir andamento" onClick={() => handleDelete(line.AND_ID)}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </td>  
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </AreaComponent>
                </div>
                }
            </div>
        </ContentComponent>
    </Container>
    );
}
