import styled from 'styled-components';

export const Container = styled.div`
        min-width: 97.9%;
        min-height: 95%;
        position: absolute;
        padding: 20px ;
        background: rgba(255, 255, 255, .8);
        display: ${props => props.load ? 'flex' : 'none'};
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20pt;
        svg {
            font-size:50pt;
            margin: 10px;
            font-weight: 100;
            color: #333;
            transform: rotateY(360deg);
            animation:spin 1s linear infinite;
        }

        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
`;
