/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import MenuComponent from '../../components/MenuComponent/';
import ContentComponent from '../../components/ContentComponent/';
import * as Model from '../../models/dashboardModel';
import Status from '../../data/status';
import Charts from '../../data/charts';
import ChartComponent from './components/Chart';
import Card from './components/Card';
import logo from '../../assets/img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { format, addMonths, isValid, parseISO } from 'date-fns';
import printData from "../../utils/prints/functions/print";
import { sortFunctions, handleSort } from "../../utils";

import { Container } from './styles';

export default function Dashboard() {
    const [charts, setCharts] = useState([]);
    const [cards, setCards] = useState([]);
    const [rows, setRows] = useState([]);
    const [sort, setSort] = useState({ currentSort: 'up', column: '' });
    const sortColumns = column => handleSort(column, sort, setSort);
    const [loading, setLoading] = useState(false);
    const dados = {
        title: "Dashboard",
        subtitle: "Resumo",
        bread: ['Dashboard']
    };


    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`;
        init();
    }, []);

    async function init() {
        setLoading(true);
        const resCharts = await Model['CHARTS']();
        setCharts(resCharts);
        const resRows = await Model['CERTIFICADOS'](format(addMonths(new Date(), 3), 'MM'));
        setRows(resRows);
        const resCards = await Model['CARDS']();
        setCards([
            {
                number: resCards.emProcesso,
                ...Status[0]
            },
            {
                number: resCards.ativo,
                ...Status[1]
            },
            {
                number: resCards.suspenso,
                ...Status[2]
            },
            {
                number: resCards.cancelado,
                ...Status[3]
            },
            {
                number: resCards.iniciado,
                ...Status[4]
            }
        ]);
        setLoading(false);
    }

    function geraCor() {
        var hexadecimais = '0123456789ABCDEF';
        var cor = '#';

        // Pega um número aleatório no array acima
        for (var i = 0; i < 6; i++) {
            //E concatena à variável cor
            cor += hexadecimais[Math.floor(Math.random() * 16)];
        }
        return cor;
    }

    async function handleFilter(month) {
        const resRows = await Model['CERTIFICADOS'](month);
        setRows(resRows)
    }

    return (
        <Container>
            <div className={`load ${loading ? "inProcess" : ""}`}>
                <img src={logo} alt="QCCERT" />
                <span>Carregando... </span>
            </div>
            <MenuComponent />
            <ContentComponent {...dados}>
                <div className="relatorio">
                    <div className="input">
                        <div className="title">
                            <FontAwesomeIcon icon={Icons['faFilter']} />
                            <span>Filtro</span>
                        </div>
                        <select onChange={({ target }) => handleFilter(target.value)}>
                            <option value={format(addMonths(new Date(), 3), 'MM')}>Selecione</option>
                            <option value={4}>Janeiro</option>
                            <option value={5}>Fevereiro</option>
                            <option value={6}>Março</option>
                            <option value={7}>Abril</option>
                            <option value={8}>Maio</option>
                            <option value={9}>Junho</option>
                            <option value={10}>Julho</option>
                            <option value={11}>Agosto</option>
                            <option value={12}>Setembro</option>
                            <option value={1}>Outubro</option>
                            <option value={2}>Novembro</option>
                            <option value={3}>Dezembro</option>
                        </select>
                    </div>
                    <div className="list">
                        <div className="button">
                            <button onClick={() => printData({ html: document.getElementById('listPrint').outerHTML, notPDF: true })}>
                                <FontAwesomeIcon icon={Icons['faPrint']} /> Imprimir
                            </button>
                        </div>
                        <table id="listPrint">
                            <thead>
                                <tr>
                                    <th onClick={() => sortColumns('CER_ID')}>#</th>
                                    <th onClick={() => sortColumns('CER_NUMERO')}>Número do certificado</th>
                                    <th onClick={() => sortColumns('CLI_RAZAO_SOCIAL')}>Solicitante</th>
                                    <th onClick={() => sortColumns('CER_DATA_VALIDADE')}>Data de vencimento</th>
                                    <th onClick={() => sortColumns('USU_NAME')}>Responsável</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rows.length > 0 ?
                                        rows
                                        .sort(sortFunctions[sort.currentSort](sort.column))
                                        .map((row, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{row.CER_ID}</td>
                                                    <td>{row.CER_NUMERO}</td>
                                                    <td title={row.CLI_RAZAO_SOCIAL}>{row.CLI_RAZAO_SOCIAL}</td>
                                                    <td>{isValid(parseISO(row.CER_DATA_VALIDADE)) ? format(parseISO(row.CER_DATA_VALIDADE), 'dd/MM/yyyy') : '-'}</td>
                                                    <td title={row.USU_NAME}>{row.USU_NAME}</td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr className="empty">
                                            <td colSpan="100%">
                                                Sem informações
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="cards">
                    {
                        cards.map((card, key) => {
                            return <Card key={key} {...card} onClick={() => { }} />
                        })
                    }
                </div>
                <div className="area-charts">
                    {
                        charts.length > 0 ?
                            Charts.map((chartData, key) => {
                                let chart = charts.filter(chart => chart[0] ? chart[0].id === chartData.id : false);
                                return (
                                    <ChartComponent
                                        key={key}
                                        title={chartData.title}
                                        options={{
                                            type: 'pie',
                                            data: {
                                                labels: chart.length > 0 ? chart[0].map(chart => chart.label === null ? 'Não identificado' : chart.label) : [''],
                                                datasets: [{
                                                    backgroundColor: chart.length > 0 ? chart[0].map(() => geraCor()) : chartData.backgroundColor,
                                                    data: chart.length > 0 ? chart[0].map(chart => chart.value) : [0]
                                                }]
                                            }
                                        }}
                                    />
                                )
                            })
                            : null
                    }

                </div>
            </ContentComponent>
        </Container>
    );
}
