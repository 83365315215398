import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';

import MenuComponent from '../../../../components/MenuComponent';
import ContentComponent from '../../../../components/ContentComponent';
import AreaComponent from '../../../../components/AreaComponent';
import FormComponent from '../../../../components/FormComponent';

import * as Model from '../../../../models/cargosModel';
import baseURL from '../../baseURL';

import { Container } from './styles';

export default function CRUDCargos(props) {
    const { id } = props.match.params;
    const { replace } = useHistory();
    const dados = {
        title: id ? "Editar cargo" : "Cadastro de cargos",
        subtitle: id ? "Alterar dados de cargo gerado" : "Adicionar um novo cargo",
        bread: ['Cargos', id ? 'Editar cargo' : 'Cadastro de cargos' ]
    };
    const [ inputs, setInputs ] = useState([]);

    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`
    }, [dados.title]);

    useEffect(() => {
        async function init(){
            const inputsNew = [
                {
                    span: "Cargo",
                    type: "text",
                    name: "CAR_NOME",
                    value: "",
                    required: true
                }
            ]
            if(id){
                const res = await Model['SHOW'](id);
                inputsNew.map(input => {
                    input.value = res[0][input.name]
                    return null
                })
            }
            setInputs(inputsNew)
        }
        init();
    }, [id])

    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <AreaComponent title={id ? "Formulário de Alteração" : "Formulário de Cadastro"}>
                <FormComponent
                    id={id}
                    inputs={inputs}
                    submitIcon={<FontAwesomeIcon icon={!id ? faCheck : faEdit} />}
                    submitText={`${!id ? 'Cadastrar' : 'Editar'}`}
                    model="cargosModel"
                    gridForm="2fr"
                    return={() => replace(baseURL)}
                />
            </AreaComponent>
        </ContentComponent>
    </Container>
    );
}
