import React from "react";
import { Switch, Route, BrowserRouter  } from 'react-router-dom';
import routeDefault from "./services/route_default";

import Servicos from './modules/Servicos';
import BUServicos from './modules/Servicos/baseURL';
import CRUDServicos from './modules/Servicos/modules/CRUDServicos';

import Etapas from './modules/Etapas';
import BUEtapas from './modules/Etapas/baseURL';
import CRUDEtapas from './modules/Etapas/modules/CRUDEtapas';

import Clientes from './modules/Clientes';
import BUClientes from './modules/Clientes/baseURL';
import CRUDClientes from './modules/Clientes/modules/CRUDClientes';

import Fabricantes from './modules/Fabricantes';
import BUFabricantes from './modules/Fabricantes/baseURL';
import CRUDFabricantes from './modules/Fabricantes/modules/CRUDFabricantes';

import Contatos from './modules/Contatos';
import BUContatos from './modules/Contatos/baseURL';
import CRUDContatos from './modules/Contatos/modules/CRUDContatos';

import Cargos from './modules/Cargos';
import BUCargos from './modules/Cargos/baseURL';
import CRUDCargos from './modules/Cargos/modules/CRUDCargos';

import Assinantes from './modules/Assinantes';
import BUAssinantes from './modules/Assinantes/baseURL';
import CRUDAssinantes from './modules/Assinantes/modules/CRUDAssinantes';

import Usuarios from './modules/Usuarios';
import BUUsuarios from './modules/Usuarios/baseURL';
import CRUDUsuarios from './modules/Usuarios/modules/CRUDUsuarios';

import Profile from './modules/Profile';
import BUProfile from './modules/Profile/baseURL';
import CRUDCertificados from './modules/Profile/modules/CRUDCertificados';
import DocumentosAnexados from './modules/Profile/modules/DocumentosAnexados';
import PrintCertificados from './modules/Profile/modules/PrintCertificados';
import UploadToClient from './modules/Profile/modules/Upload';

import Login from './modules/Login';
import BULogin from './modules/Login/baseURL';

import Certificados from './modules/Certificados';
import BUCertificados from './modules/Certificados/baseURL';

import Dashboard from './modules/Dashboard';
import BUDashboard from './modules/Dashboard/baseURL';

//AREA DO CLIENTE
import UploadAreaCliente from './modules/AreaCliente/modules/Upload';
import DashboardAreaCliente from './modules/AreaCliente/modules/Dashboard';
import FAQAreaCliente from './modules/AreaCliente/modules/FAQ';
import ArquivosAreaCliente from './modules/AreaCliente/modules/Arquivos';
import AndamentoAreaCliente from './modules/AreaCliente/modules/Andamento';
import DocumentosAreaCliente from './modules/AreaCliente/modules/Documentos';
import HomeAreaCliente from './modules/AreaCliente/modules/Home';
import AreaCliente from './modules/AreaCliente';
import BUAreaCliente from './modules/AreaCliente/baseURL';

//AREA DE ADMINISTRADOR DO SISTEMA
import Modelos from './modules/Modelos';
import BUModelos from './modules/Modelos/baseURL';
import CRUDModelos from './modules/Modelos/modules/CRUDModelos';

import FAQ from './modules/FAQ';
import BUFAQ from './modules/FAQ/baseURL';
import CRUDFAQ from './modules/FAQ/modules/CRUDFAQ';

// WEB SERVICE
import WebService from './modules/WebService';
import Consulta from './modules/WebService/modules/Consulta';

const RouterPage = () => {
   return (
    <BrowserRouter basename={routeDefault}>
        <Switch>
            {/* VER STATUS DO CERTIFICADO - WEB SERVICE */}
            <Route path="/certificate/status" exact component={WebService} />
            <Route path="/consultar/certificado" exact component={Consulta} />

            <Route path={`${BUCertificados}`} exact component={Certificados} />
            <Route path={`${BUDashboard}`} exact component={Dashboard} />

            <Route path={`${BUEtapas}/cadastro`} component={CRUDEtapas} />
            <Route path={`${BUEtapas}/editar/:id`} component={CRUDEtapas} />
            <Route path={BUEtapas} component={Etapas} />

            <Route path={`${BUServicos}/cadastro`} component={CRUDServicos} />
            <Route path={`${BUServicos}/editar/:id`} component={CRUDServicos} />
            <Route path={BUServicos} component={Servicos} />

            <Route path={`${BUClientes}/cadastro`} component={CRUDClientes} />
            <Route path={`${BUClientes}/editar/:id`} component={CRUDClientes} />
            <Route path={BUClientes} component={Clientes} />

            <Route path={`${BUFabricantes}/cadastro`} component={CRUDFabricantes} />
            <Route path={`${BUFabricantes}/editar/:id`} component={CRUDFabricantes} />
            <Route path={BUFabricantes} component={Fabricantes} />

            <Route path={`${BUContatos}/cadastro/:who/:id`} render={ props => <CRUDContatos {...props} cadastro/> } />
            <Route path={`${BUContatos}/editar/:who/:id`} render={ props => <CRUDContatos {...props}/> } />
            <Route path={`${BUContatos}/:who/:id`} component={Contatos} />

            <Route path={`${BUCargos}/cadastro`} component={CRUDCargos} />
            <Route path={`${BUCargos}/editar/:id`} component={CRUDCargos} />
            <Route path={BUCargos} component={Cargos} />

            <Route path={`${BUAssinantes}/cadastro`} component={CRUDAssinantes} />
            <Route path={`${BUAssinantes}/editar/:id`} component={CRUDAssinantes} />
            <Route path={BUAssinantes} component={Assinantes} />

            <Route path={`${BUProfile}/:who/:id/documentos/:idCert/:status`} exact component={DocumentosAnexados} />
            <Route path={`${BUProfile}/upload/:who/:id/:requerent`} component={UploadToClient} />
            <Route path={`${BUProfile}/editar/:who/:id/:requerent`} component={CRUDCertificados} />
            <Route path={`${BUProfile}/print/:who/:id`} component={PrintCertificados} />
            <Route path={`${BUProfile}/:who/:id`} exact component={Profile} />

            <Route path={`${BULogin}`} exact component={Login} />

            <Route path={`${BUModelos}/cadastro`} component={CRUDModelos} />
            <Route path={`${BUModelos}/editar/:id`} component={CRUDModelos} />
            <Route path={`${BUModelos}`} component={Modelos} />

            <Route path={`${BUFAQ}/cadastro`} component={CRUDFAQ} />
            <Route path={`${BUFAQ}/editar/:id`} component={CRUDFAQ} />
            <Route path={`${BUFAQ}`} component={FAQ} />

            <Route path={`${BUUsuarios}/cadastro`} component={CRUDUsuarios} />
            <Route path={`${BUUsuarios}/editar/:id`} component={CRUDUsuarios} />
            <Route path={`${BUUsuarios}`} component={Usuarios} />

            {/* AREA DO CLIENTE */}
            <Route path={`${BUAreaCliente}/faq`} exact component={FAQAreaCliente} />
            <Route path={`${BUAreaCliente}/upload/:idCer`} exact component={UploadAreaCliente} />
            <Route path={`${BUAreaCliente}/upload/:idCer`} exact component={UploadAreaCliente} />
            <Route path={`${BUAreaCliente}/dashboard`} exact component={DashboardAreaCliente} />
            <Route path={`${BUAreaCliente}/andamento/:id/:numCert`} exact component={AndamentoAreaCliente} />
            <Route path={`${BUAreaCliente}/arquivos/:id/:numCert`} exact component={ArquivosAreaCliente} />
            <Route path={`${BUAreaCliente}/documentos/:id/:numCert`} exact component={DocumentosAreaCliente} />
            <Route path={`${BUAreaCliente}/home`} exact component={HomeAreaCliente} />
            <Route path={`${BUAreaCliente}/:who`} exact component={AreaCliente} />
            <Route path={`${BUAreaCliente}/print/:who/:id`} component={PrintCertificados} />

        </Switch>
    </BrowserRouter>
  );
}

export default RouterPage;
