/*
* Styles Component de CadastroServicos
*/
import styled from 'styled-components';
import * as constants from '../../../../../constants/styles';

const {
    grayDark,
    boxShadow,
    def,
    whiteLight
} = constants

export const Container = styled.div`
    min-height: 100%;
    background: #eef8fd;
    display: flex;
    flex-direction: row;

    div.empty-model {
        display: flex;
        height: 400px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        span {
            color: #ccc;
            font-size: 16pt;
            font-family: 'Century Gothic';
            font-style: italic;
        }
    }

    ul.models {
        list-style: none;
        display: grid;
        grid-template-columns: 2fr 2fr 2fr 2fr;
        grid-gap: 30px;
        width: 96%;
        li {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 1px solid ${boxShadow};
            height: 10em;
            color: ${grayDark};
            text-align: center;
            font-size: 9pt;
            &:hover {
                box-shadow: 1px 1px 5px ${boxShadow};
            }
            svg {
                font-size: 30px;
                margin-bottom: 5px;
                color: ${grayDark}
            }
        }
        li.select {
            background: #555;
            color: white;
            svg {
                color: white;
            }
        }
    }

    div.fabricante {
        padding: 20px 10px;
    }

    div.button {
        padding: 20px 20px;
        display: flex;
        width: 98%;
        justify-content: end;
        button {
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            background: ${def};
            color: ${whiteLight};
            font-size: 12pt;
            box-shadow: 1px 1px 5px ${boxShadow};
        }
        button:hover {
            box-shadow: none;
        }
    }

    .area {
        width: 99%;
        margin: 10px 0;
    }
    .containerArea{
        max-width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 2fr);
    }
    @media only screen and (max-width: 360px) {
        ul.models {
            grid-template-columns: 2fr;
            padding: 20px;
            width: 85%;
        }
        .area {
            width: 85vw;
        }
    }
    @media only screen and (min-width: 360px) and (max-width: 768px) {
        ul.models {
            grid-template-columns: 2fr;
            width: 85%;
        }
        .area {
            width: 85vw;
        }
    }
`;


