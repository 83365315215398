import styled from 'styled-components';
import * as constants from '../../../constants/styles';

const {
    secondary,
    white,
    boxShadow,
    def
} = constants

export const Container = styled.div.attrs(props => ({
    className: props.className ? props.className : "area",
    theme: props.theme
}))`
    margin: 1em 0;
    background: ${white};
    border-radius: 10px;
    border-top: 7px solid ${props => constants[props.theme] ? constants[props.theme] : props.theme.background ? props.theme.background : secondary};
    padding: 10px;
    box-shadow: 1px 1px 5px ${boxShadow};
    width: 100%;
    h2.area-title {
        color: ${props => props.theme.color ? props.theme.color : white};
        font-weight: 500;
        border-radius: 0 0 10px 10px;
        font-size: 14pt;
        background: ${props => constants[props.theme] ? constants[props.theme] : props.theme.background ? props.theme.background : secondary};
        padding: 20px;
        width: 400px;
        margin-top: -11px;
        margin-bottom: 10px;
        box-shadow: 3px 3px 2px ${boxShadow};
    }
    .displayNone {
        display: none;
    }
    .container-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .btn {
        border: none;
        padding: 15px;
        font-size: 12pt;
    }
    .btn-success {
        background: ${def};
        color: white;
    }
    @media only screen and (max-width: 360px) {
        width: 90%;
        h2.area-title {
            font-size: 12pt;
            width: 200px;
        }
    }
    @media only screen and (min-width: 360px) and (max-width: 768px) {
        width: 90%;
        h2.area-title {
            font-size: 12pt;
            width: 200px;
        }
    }
`;
