/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import * as Mask from '../../../../constants/mask';

const Line = (props) => {
    const [ value, setValue ] = useState(props.value);

    useEffect(() => {
        if(props.type === 'select' && value){
            props.setData(props.name, props.value.value)
        }
    }, [])

    function changeValue(e){
        props.mask ? props.setData(props.name, Mask[props.mask](e.value)) : props.setData(props.name, e.value)
        switch(props.type){
            case 'select' : {
                setValue(e);
                break;
            }
            case 'textarea':{
                setValue(e);
                props.setData(props.name, e)
                break;
            }
            default: {
                setValue(props.mask ? Mask[props.mask](e.value) : e.value);
            }
        }
    }

    return (
        <>
            {
                props.type === 'select' ?
                    <div className="form-line">
                        <span>{props.span} </span>
                        <Select
                            className="selectForm"
                            value={value}
                            onChange={e => changeValue(e)}
                            placeholder="Selecione"
                            options={props.options}
                            noOptionsMessage={() => 'Sem opções'} />
                    </div>
                :
                    props.type === 'textarea' ?
                        <div className="form-line">
                            <span>{props.span} </span>
                            <textarea value={value} onChange={e => changeValue(e.target.value)} rows={props.rows} id={props.name}></textarea>
                        </div>
                    :
                        <div className="form-line">
                            <span>{props.span} </span>
                            <input type={props.type ? props.type : "text"} maxLength={props.maxLength} value={value} disabled={props.disabled} required={props.required} onChange={({target}) => {changeValue(target); if(props.onChange){ props.onChange(target) }}}/>
                        </div>
            }
        </>
    )
};

export default Line;

