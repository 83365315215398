import styled from 'styled-components';
import * as constants from '../../../../../constants/styles';

const {
    secondary,
    primary,
    def,
    warning
} = constants

export const Container = styled.div`
        width: 100vw;
        padding: 20px 0;
        background: ${primary};
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
            width: 150px;
            margin: 0 0 0 20px;
        }
        span {
            font-size: 18pt;
            color: white;
        }
        button {
            border: none;
            padding: 10px 20px;
            color: white;
            font-size: 12pt;
            border-radius: 20px;
        }

        .my-certificate {
            background: ${def};
            margin: 0 10px 0 0;
        }
        .subtitle {
            font-size: 12pt;
            margin-right: 10px;
        }

        .buttons {
            padding-right: 10px;
            button{
                margin: 5px;
            }
            .faq {
                background: ${warning}
            }

            .sign-out {
                background: ${secondary}
            }
        }

        .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
        }

    @media only screen and (max-width: 360px) {
        width: 100vw;
        
        span {
            font-size: 12pt;
            padding: 0 10px;
        }
        .buttons {
            button {
                width: 100%;
                margin: 0;
                font-size: 9pt;
            }
            width: 30vw;
        }
        .nomeCliente{
            display: none;
        }
    }
    @media only screen and (min-width: 360px) and (max-width: 768px) {
        width: 100vw;
        img {
            width: 100px;
        }
        span {
            font-size: 16pt;
            padding: 0 10px;
        }
        .buttons {
            button {
                width: 100%;
                margin: 0;
                font-size: 10pt;
            }
            width: 15vw;
        }
        .nomeCliente{
            display: none;
        }
    }
`;
