import React, { useState } from 'react';

import { Container } from './styles';

const AreaComponent = (props) => {
    const [ view, setView ] = useState(true);
    return (
        <Container className={props.className} theme={props.theme}>
            {
                props.title ?
                    <h2 title="Clique duas vezes para minimizar o conteúdo" className='area-title' onDoubleClick={() => setView(!view)}>{props.title}</h2>
                :null
            }
            <div className={`container ${!view ? 'displayNone' : ''}`}>
                {props.children}
            </div>
        </Container>
    );
}

export default AreaComponent;
