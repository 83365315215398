import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    span {
        margin: 10px;
    }
    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    button {
        margin: 20px 10px 0 10px;
        border: none;
        padding: 10px;
        background: #45b9ef;
        color: white;
    }
`;
