import styled from 'styled-components';
import * as constants from '../../../constants/styles';

const {
    whiteDark,
    secondary,
    whiteLight,
    boxShadow,
    danger
} = constants

export const Container = styled.form.attrs(props => ({
    onSubmit: props.onSubmit,
    theme: props.theme
}))`
    padding: 20px;
    display: grid;
    grid-template-columns: ${props => props.gridForm ? props.gridForm : "2fr 2fr 2fr"};
    grid-gap: 5px;
    flex-wrap: wrap;
    div.form-line {
        display: flex;
        flex-direction: column;
        margin: .5% 1%;
        span {
            font-size: 12pt;
            padding: 10px 0;
        }
        input {
            width: 95%!important;
            border: none;
            background: ${whiteDark};
            padding: 10px;
            border-radius: 20px;
        }
        textarea {
            border: none;
            background: ${whiteDark};
            padding: 10px;
            border-radius: 20px;
        }
    }

    @media only screen and (max-width: 360px) {
        grid-template-columns: ${props => props.gridForm ? props.gridForm : "2fr"};
        grid-gap: 10px;
    }

    @media only screen and (min-width: 360px) and (max-width: 768px) {
        grid-template-columns: ${props => props.gridForm ? props.gridForm : "2fr 2fr"};
        grid-gap: 10px;
    }
`;

export const ContainerButton = styled.div`
    padding: 20px 20px;
    display: flex;
    width: 95%;
    justify-content: end;
    z-index: 9999;
    button {
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        background: ${props => constants[props.theme] ? constants[props.theme] : secondary};
        color: ${whiteLight};
        font-size: 12pt;
        box-shadow: 1px 1px 5px ${boxShadow};
    }
    button.cancel {
        background: ${danger};
        margin: 0 10px;
    }
    button:hover {
        box-shadow: none;
    }
    @media only screen and (max-width: 360px) {
        width: 90%;
    }
`
