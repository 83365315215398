/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import MenuComponent from '../../../../components/MenuComponent';
import ContentComponent from '../../../../components/ContentComponent';
import AreaComponent from '../../../../components/AreaComponent';
import FormComponent from './components/Form';

import * as Model from '../../../../models/clientesModel';

import { Container } from './styles';

export default function CRUDClientes(props) {
    const { id } = props.match.params;
    const [ data, setData ] = useState({});
    const dados = {
        title: id ? "Editar Cliente" : "Cadastro de Clientes",
        subtitle: id ? "Alterar dados de cliente gerado" : "Adicionar um novo cliente",
        bread: ['Clientes', id ? 'Editar Cliente' : 'Cadastro de Clientes' ]
    };

    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`
    }, [dados.title]);

    useEffect(() => {
        async function init(){
            if(id){
                const res = await Model['SHOW'](id)
                setData(res[0]);
            }
        }
        init();
    }, [])

    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <AreaComponent title={id ? "Formulário de Alteração" : "Formulário de Cadastro"}>
                <FormComponent id={id} data={data} setData={setData}/>
            </AreaComponent>
        </ContentComponent>
    </Container>
    );
}
