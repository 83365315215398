/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Content } from './styles';
import * as ModelFAQ from '../../../../models/faqModel';
import AreaComponent from '../../../../components/AreaComponent';
import TopMenu from '../../components/TopMenu';
import baseURL from '../../baseURL';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

export default function FAQAreaCliente() {
    const [faq, setFAQ] = useState([]);
    const { push } = useHistory();

    const dados = {
        title: "Área do Cliente - FAQ"
    };

    useEffect(() => {
        document.title = `${dados.title}`;
    }, [dados.title]);

    useEffect(() => {
        handleLoadDocuments();
    }, [])
    
    async function handleLoadDocuments() {
        setFAQ(await ModelFAQ['INDEX']())
    }

    return (
        <Container>
            <TopMenu />
            <Content>
                <AreaComponent className="area" title="Perguntas e Respostas">
                   {
                       faq.length > 0 ?
                       faq.map((ask, key) => {
                           return (
                                <div key={key} className="cards">
                                    <span>{key+1}. {ask.FAQ_PERGUNTA}</span>
                                    <p>{ask.FAQ_RESPOSTA}</p>
                                </div>
                           )
                       })
                       : <span className="empty">Nada encontrado aqui!...</span>
                   }
                </AreaComponent>          
                <div className="voltar">
                    <button className="back" title="Voltar" onClick={() => push(`${baseURL}/dashboard/`)}>
                        <FontAwesomeIcon icon={faArrowCircleLeft}/> Voltar
                    </button>                         
                </div> 
            </Content>
        </Container>
    );
}
