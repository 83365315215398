import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icons from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/img/logo2.png";
import { Container } from "./styles";
import * as Model from "../../models/certificadosModel";
import Status from "../../data/status";
import { format, parseISO, isValid } from "date-fns";
import pt from "date-fns/locale/pt-BR";
import logoLoad from "../../assets/img/logo.png";

export default function WebService({ location }) {
  const [dados, setDados] = useState();
  const [emissao, setEmissao] = useState();
  const [validade, setValidade] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      const id = location.search.split("?code=")[1];
      const res = await Model["CONSULTA"](id);
      setDados(res[0]);
      if (res.length <= 0) return setLoading(false);
      setEmissao(res[0]?.CER_DATA_EMISSAO);
      setValidade(res[0]?.CER_DATA_VALIDADE);
      setLoading(false);
    }
    init();
  }, [location.search]);

  return (
    <Container>
      <div className={`load ${loading ? "inProcess" : ""}`}>
        <img src={logoLoad} alt="QCCERT" />
        <span>Carregando... </span>
      </div>
      <div className="folha">
        <img src={logo} alt="QCCERT" />
        {dados ? (
          <>
            <div className="labels">
              <span className="span-status">STATUS</span>
              <span
                className="status"
                style={dados ? { ...Status[dados.CER_STATUS].theme } : {}}
              >
                <FontAwesomeIcon
                  icon={icons[dados ? Status[dados.CER_STATUS].icon : ""]}
                />{" "}
                {dados ? Status[dados.CER_STATUS].label : "-"}
              </span>
            </div>
            <div className="infos">
              <div className="line1">
                <div className="line">
                  <span>
                    <b>Produto</b>
                  </span>
                  <p>{dados ? dados.CER_PRODUTO : "-"}</p>
                </div>
                <div className="line">
                  <span>
                    <b>Número do certificado</b>
                  </span>
                  <p>{dados.CER_NUMERO || "-"}</p>
                </div>
                <div className="row-data">
                  <div className="line">
                    <span>
                      <b>Data de Emissão</b>
                    </span>
                    <p>
                      {emissao
                        ? isValid(parseISO(emissao))
                          ? format(parseISO(emissao), "dd/MM/yyyy")
                          : emissao
                        : "-"}
                    </p>
                  </div>
                  <div className="line">
                    <span>
                      <b>Data de Validade</b>
                    </span>
                    <p>
                      {dados.SER_INDETERMINADO === 1
                        ? "Indeterminado"
                        : validade
                        ? isValid(parseISO(validade))
                          ? format(parseISO(validade), "dd/MM/yyyy")
                          : validade
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="line scope">
                <span>
                  <b>Programa de certificação ou Portaria </b>
                </span>
                <p>{dados ? dados.SER_PORTARIA : "-"}</p>
              </div>
            </div>
          </>
        ) : !loading ? (
          <span className="empty">
            <FontAwesomeIcon icon={icons["faExclamationTriangle"]} />{" "}
            Certificado não encontrado!
          </span>
        ) : null}
      </div>
      <span className="info">
        Essa é uma página segura e controlada pela QC Certificações <br />
        <b>
          (Consulta realizada em{" "}
          {format(new Date(), "dd 'de' MMMM 'de' yyyy 'às' HH:mm", {
            locale: pt,
          })}
          )
        </b>
      </span>
    </Container>
  );
}
