import styled from 'styled-components';
import * as constants from '../../../../../../../constants/styles';

const {
    whiteDark,
    secondary,
    whiteLight,
    boxShadow,
    primary,
    def,
    danger
} = constants

export const Container = styled.form.attrs(props => ({
    onSubmit: props.onSubmit,
    theme: props.theme
}))`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    div.form-line {
        display: flex;
        flex-direction: column;
        margin: .5% 1%;
        span {
            font-size: 14pt;
            padding: 10px 0;
        }
        input {
            border: none;
            background: ${whiteDark};
            padding: 10px;
            border-radius: 5px;
        }
        textarea {
            border: none;
            background: ${whiteDark};
            padding: 10px;
            border-radius: 5px;
        }
    }
    div.container-plus {
        padding: 20px 20px;
        display: flex;
        width: 100%;
        justify-content: end;
        button {
            border: none;
            padding: 10px 20px;
            margin: 0 2px;
            border-radius: 5px;
            background: ${secondary};
            color: ${whiteLight};
            font-size: 12pt;
            box-shadow: 1px 1px 5px ${boxShadow};
        }
        button.plus {
            background: ${primary};
        }

        button:hover {
            box-shadow: none;
        }
    }

`;

export const Line = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 6fr) 1fr;
    grid-gap: 10px;
    div.containerCloneLine {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        button.cloneLine {
            font-size: 14pt;
            width: 35px;
            height: 35px;
            border: none;
            background: ${def};
            color: white;
        }
        button.deleteLine {
            margin-left: 5px;
            font-size: 14pt;
            width: 35px;
            height: 35px;
            border: none;
            background: ${danger};
            color: white;
        }
    }
    .form-line {
        input, textarea {
            border-radius: 20px!important;
        }
    }
    @media only screen and (max-width: 360px) {
        display: grid;
        grid-template-columns: 1fr;
        .form-line {
            max-width: 100%;
            min-width: 100%;
            span {
                font-size: 10pt!important;
            }
        }
    }
    @media only screen and (min-width: 360px) and (max-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
        .form-line {
            max-width: 100%;
            min-width: 100%;
            span {
                font-size: 10pt!important;
            }
        }
    }
`;
