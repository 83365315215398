/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import store from './store';
import Select from 'react-select';
import * as Mask from '../../constants/mask';
import Jodit from "jodit";
import 'jodit';
import 'jodit/build/jodit.min.css';

import { Container } from './styles';
import { handleSubmit } from './controllers';

export const Form = (props) => {
    return (
        <Provider store={store}>
            <FormContext {...props}>
                {props.children}
            </FormContext>
        </Provider>
    )
};

const FormContext = (props) => {
    var data = useSelector(state => state);
    const dispatch = useDispatch();
    const setData = (value) => dispatch({
        type: 'SET_DATA',
        ...value
    });

    useEffect(() => {
        setData(props.data)
    }, [props.data])


    return(
        <Container theme={props.theme} onSubmit={(e) => { dispatch({type: 'SET_DATA'}); handleSubmit(e, props, data); setData({}); }}>
            {props.children}
            <div className="button">
                <button className="cancel" onClick={() => { props.returnFunction(); setData({}); }}>
                    <FontAwesomeIcon icon={faTimes} />  Cancelar
                </button>
                <button>
                    {props.submitIcon} {props.submitText}
                </button>
            </div>
        </Container>
    )
}

export const Input = (props) => {
    var textArea = useRef();
    const dispatch = useDispatch();
    const setData = (value) => dispatch({
        type: 'SET_DATA',
        ...value
    });
    var data = useSelector(state => state);

    useEffect(() => {
        if(props.type === 'textarea' && !props.normal){
            textArea = new Jodit(`#${props.name}`, {
                "language": "pt_br",
                "height": "20vw",
                "minHeight": "50%",
                "maxHeight": "50%"
            })
            textArea.value = data[props.name];
            textArea.events.on('change', (e) => changeValue(e))
        }
        if(props.valueForeign){
            if(props.valueForeign === data[props.name]){
                props.setForeign(false);
            }else{
                props.setForeign(true);
            }
        }
        if(!data[props.name] && props.type === 'select') {
            data[props.name] = 0;
        }
    }, [props.data])

    async function changeValue(e){
        if(props.valueForeign){
            if(props.valueForeign === e){
                props.setForeign(false);
            }else{
                props.setForeign(true);
            }
        }
        props.mask ? setData({...data, [props.name]: Mask[props.mask](e)}) : setData({...data, [props.name]: e})
        if(props.onChange){
            setData(await props.onChange(e, data, Mask[props.mask]) ? await props.onChange(e, data, Mask[props.mask]) : data);
        }
    }

    return (
        <>
            {
                props.type === 'select' ?
                    <div className={`form-line ${props.className}`} style={{maxWidth: props.size, minWidth: props.size}}>
                        <span>{props.span} </span>
                        {
                                <Select
                                    {...props}
                                    require={props.require}
                                    className="selectForm"
                                    value={data[props.name] ? { label: props.options.filter(option => option.value === data[props.name])[0] ? props.options.filter(option => option.value === data[props.name])[0].label : "", value: props.options.filter(option => option.value === data[props.name])[0] ? props.options.filter(option => option.value === data[props.name])[0].value : "" } : undefined}
                                    onChange={e => changeValue(e.value)}
                                    placeholder="Selecione"
                                    options={[{label: "Selecione", value: ''},...props.options]}
                                    noOptionsMessage={() => 'Sem opções'} />
                        }
                    </div>
                :
                    props.type === 'textarea' ?
                        <div className={`form-line ${props.className}`} style={{maxWidth: props.size, minWidth: props.size}}>
                            <span>{props.span} </span>
                            <textarea {...props} value={data[props.name]} onChange={({target}) => changeValue(target.value)} id={props.name}></textarea>
                        </div>
                    :
                        <div className={`form-line ${props.className}`} style={{maxWidth: props.size, minWidth: props.size}}>
                            <span>{props.span} </span>
                            <input {...props} value={data[props.name]} onChange={({target}) => changeValue(target.value)}/>
                        </div>
            }
        </>
    )
};
