import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import MenuComponent from '../../components/MenuComponent/';
import ContentComponent from '../../components/ContentComponent/';
import AreaComponent from '../../components/AreaComponent/';
import { Container } from './styles';
import swal from '@sweetalert/with-react';
import * as Model from '../../models/assinantesModel';
import baseURL from './baseURL';
import { debounce, handleSort, sortFunctions } from "../../utils";

export default function Assinantes() {
    const [ lines, setLines ] = useState([]);
    const filtro = useRef();
    const { push } = useHistory();
    const [sort, setSort] = useState({ currentSort: 'up', column: '' });
    const sortColumns = column => handleSort(column, sort, setSort);
    const dados = {
        title: "Assinantes",
        subtitle: "Todos os assinantes",
        bread: ['Assinantes']
    };

    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`;
        init();
    }, [dados.title]);

    async function handleSearch(){
        if(filtro.current.value === ""){ init(); return; }
        setLines(await Model['SEARCH'](filtro.current.value))
    }

    async function init(){
        setLines(await Model['INDEX']())
    }

    async function handleDelete(id){
        swal({
            title: "Você tem certeza?",
            text: "Uma vez excluído, você não poderá recuperar esse registro!",
            icon: "warning",
            dangerMode: true,
            buttons: ['Não', 'Sim'],
          })
        .then(async (willDelete) => {
            if (willDelete) {
                const res = await Model['DELETE'](id);
                if (res.affectedRows > 0) { swal("Sucesso!", "o registro foi removido do banco de dados", 'success', { buttons: false, timer: 2000 }) } else { if (res.errno === 1451) { swal("Não foi possivel!", "Não foi possivel excluir pois o mesmo possui vinculos ao sistema!", 'error') } else { swal("Ops!", "ocorreu um erro ao remover! tente novamente mais tarde!", 'error') } }
                init();
            }
        });
    }

    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <div className="containerButtonNew">
                <input type="text" placeholder="Filtrar..." ref={filtro} onChange={debounce(handleSearch)}/>
                <button onClick={() => push(`${baseURL}/cadastro`)}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    Novo
                </button>
            </div>
            <AreaComponent>
                <table>
                    <thead>
                        <tr>
                            <th onClick={() => sortColumns('CERTA_ID')} width={100}>#</th>
                            <th onClick={() => sortColumns('CERTA_NOME')} >Nome</th>
                            <th onClick={() => sortColumns('CERTA_FUNCAO')} >Função</th>
                            <th width={100}>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lines
                            .sort(sortFunctions[sort.currentSort](sort.column))
                            .map((line, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{line.CERTA_ID}</td>
                                        <td>{line.CERTA_NOME}</td>
                                        <td>{line.CERTA_FUNCAO}</td>
                                        <td>
                                            <button className="action edit" onClick={() => push(`${baseURL}/editar/${line.CERTA_ID}`)}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                            <button className="action delete" onClick={() => handleDelete(line.CERTA_ID)}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </AreaComponent>
        </ContentComponent>
    </Container>
    );
}
