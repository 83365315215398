import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';

import MenuComponent from '../../../../components/MenuComponent';
import ContentComponent from '../../../../components/ContentComponent';
import AreaComponent from '../../../../components/AreaComponent';
import FormComponent from '../../../../components/FormComponent';

import * as Model from '../../../../models/faqModel';

import { Container } from './styles';
import baseURL from '../../baseURL';

export default function CRUDFAQ(props) {
    const { id } = props.match.params;
    const { replace } = useHistory();
    const dados = {
        title: id ? "Editar Pergunta e Resposta" : "Cadastro de Pergunta e Resposta",
        subtitle: id ? "Alterar dados de Pergunta e Resposta" : "Adicionar uma nova Pergunta e Resposta",
        bread: ['FAQ', id ? 'Editar Pergunta e Resposta' : 'Cadastro de Pergunta e Resposta' ]
    };
    const [ inputs, setInputs ] = useState([]);

    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`
    }, [dados.title]);

    useEffect(() => {
        async function init(){
            const inputsNew = [
                {
                    span: "Pergunta",
                    name: "FAQ_PERGUNTA",
                    value: "",
                    required: true,
                    size: '100%'
                },
                {
                    span: "Resposta",
                    name: "FAQ_RESPOSTA",
                    value: "",
                    required: true,
                    type: 'textarea',
                    size: '100%',
                    rows:10
                }
            ]
            if(id){
                const res = await Model['SHOW'](id);
                inputsNew.map(input => {
                    input.value = res[0][input.name]
                    return false
                })
            }
            setInputs(inputsNew)
        }
        init();
    }, [id])

    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <AreaComponent title={id ? "Formulário de Alteração" : "Formulário de Cadastro"}>
                <FormComponent
                    gridForm="1fr"
                    id={id}
                    inputs={inputs}
                    submitIcon={<FontAwesomeIcon icon={!id ? faCheck : faEdit} />}
                    submitText={`${!id ? 'Cadastrar' : 'Editar'}`}
                    model="faqModel"
                    return={() => replace(baseURL)}
                />
            </AreaComponent>
        </ContentComponent>
    </Container>
    );
}
