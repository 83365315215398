import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import baseURL from '../../../baseURL';
import Estados from '../../../../../data/estados';
import Paises from '../../../../../data/paises';
import { Form, Input } from '../../../../../components/Form/';
import { apiCorreios } from '../../../../../services/api';


const FormComponent = (props) => {
    const { id, data } = props;
    const { replace } = useHistory();
    const [ foreign, setForeign ] = useState(true);

    const Address = (props) => {
        const { foreign } = props;
        async function handleCEP(e, data, mask){
            const res = await apiCorreios.get(`${e.replace(/\D/g, '')}/json`);
            if (res.status === 200) {
                data.CLI_BAIRRO = res.data.bairro;
                data.CLI_CIDADE = res.data.localidade;
                data.CLI_ENDERECO = res.data.logradouro;
                data.CLI_CEP = mask(e);
                data.CLI_ESTADO = res.data.uf;
                return data
            }
        }
        return (
            <>
            {
                !foreign ?
                    <>
                        <Input span="CEP" type="text" onChange={(e, data, mask) => handleCEP(e, data, mask)} name="CLI_CEP" mask='CEP' size='25%' />
                        <Input span="Endereço" type="text" name="CLI_ENDERECO" size='55%' />
                        <Input span="Número" type="text" name="CLI_NUMERO" maxLength={5} size='10%' />
                        <Input span="Bairro" type="text" name="CLI_BAIRRO" size='47%' />
                        <Input span="Complemento" type="text" name="CLI_COMPLEMENTO" size='30%' />
                        <Input span="Cidade" className="cidade" type="text" name="CLI_CIDADE" size='660px' />
                        <Input span="Estado" className="estado" type="select" name="CLI_ESTADO" options={Estados.map(estado => {return { label: estado, value: estado }})} maxLength={2} size='16%' />
                    </>
                :<Input span="Endereço" type="text" name="CLI_ENDERECO" size='95%' />
            }
            </>
        )
    }

    return (
        <Form
            id={id}
            data={data}
            model="clientesModel"
            returnFunction={() => replace(baseURL)}
            submitIcon={<FontAwesomeIcon icon={!id ? faCheck : faEdit} />}
            submitText={`${!id ? 'Cadastrar' : 'Editar'}`}>

            <Input span="País" type="select" name="CLI_PAIS" require setForeign={setForeign} valueForeign='Brasil' options={Paises.map(pais => {return { label: pais, value: pais }})} size='95%' />
            <Input span="Razão Social" type="text" require name="CLI_RAZAO_SOCIAL" size={foreign ? '95%':'65%'}/>
            {
                !foreign ?
                    <Input span="CNPJ" type="text" name="CLI_CNPJ" mask='CNPJ' maxLength={20} size='28%' />
                :null
            }
            <Address foreign={foreign}/>
            <Input span="Telefone" className="telefone" type="text" name="CLI_TELEFONE" maxLength={50} size='20%' />
            <Input span="Skype" className="skype" type="text" name="CLI_SKYPE" size='20%' />
            <Input span="Endereço Secundário (caso possua)" type="textarea" name="CLI_ENDERECO_OPCIONAL" size='36%' style={{minHeight: "10vh"}} normal  />

        </Form>
    )
};


export default FormComponent;
