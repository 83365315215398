/*
* Styles Component de CadastroServicos
*/
import styled from 'styled-components';
import * as constants from '../../../constants/styles';
import bg from '../../../assets/img/bg_servicos.png';

const {
    third,
    primary
} = constants

export const Container = styled.div`
    min-height: 100%;
    background: ${third};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .background {
        background-image: url(${bg});
        background-repeat: no-repeat;
        background-size: cover;
        filter: blur(1px);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index:0;
    }

    form.login {
        z-index:9999;
        padding: 2px;
        height: 300px;
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            max-width: 100%;
            margin: 0 0 10px 0;
            padding: 0;
        }
        input {
            max-width: 85%;
            min-width: 85%;
            padding: 12px 20px;
            margin: 4px 0;
            border: none;
            border-radius: 20px;
        }

        input.submit {
            width: 90%;
            margin: 10px 0 0 0;
            padding: 10px;
            border: none;
            font-size: 12pt;
            border-radius: 20px;
            color: white;
            background: ${primary}
        }

    }

    span.seeCertificate {
        cursor: pointer;
        text-decoration: underline;
        font-size: 11pt;
        z-index: 9999;
    }

    @media only screen and (max-width: 360px) {
        overflow: hidden;
        form.login {
            padding: 0;
            img {
                width: 250px;
            }
            input {
                width: 240px;
                padding: 10px;
                text-align: center;
            }

            input.submit {
                width: 250px;
                padding: 10px;
            }
        }
        span.seeCertificate {
            font-size: 8pt;
        }
    }
`;


