/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useSession, useUtils } from '../../../../utils';
import { resizableGrid } from '../../../../utils/resizeTable';
import logo from '../../../../assets/img/logo.png';
import { useHistory } from 'react-router-dom'

import { Container } from './styles';

const TopMenu = ({ processo }) => {
    const { push, replace } = useHistory();
    const { getAuthenticate } = useUtils();
    const { destroy } = useSession();
    const [ user, setUser ] = useState({})


    useEffect(() => {
        const { user } = getAuthenticate(`/portal/clientes`, 'client-session');
        setUser(user[0]);
        const tables = document.getElementsByTagName('table');
        for (let i = 0; i < tables.length; i++){
            resizableGrid(tables[i]);
        }
    }, [])

    

    return (
        <Container>
            <span className="title">
                <img src={logo} alt="QCCERT" onClick={() => replace('/portal/dashboard')} />
                <span className="nomeCliente"><b>Área do Cliente</b>{user ? <><b>:</b> {user.CLI_RAZAO_SOCIAL}</> : ''}<br/>{processo ? <><b>Processo nº:</b> {processo}</> : ''}</span>
                <span className="buttons">
                <span className="subtitle"><b>Skype: </b>Fabioqccert</span>
                    <button className="faq" onClick={() => { push(`/portal/faq`); }}>
                        <FontAwesomeIcon icon={faQuestionCircle} /> FAQ
                    </button>
                    <button className="sign-out" onClick={() => { destroy(); getAuthenticate(`/portal/clientes`); }}>
                        <FontAwesomeIcon icon={faSignOutAlt} /> Sair
                    </button>
                </span>
            </span>
        </Container>
    )
};

export default TopMenu;
