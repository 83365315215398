import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';

import MenuComponent from '../../../../components/MenuComponent';
import ContentComponent from '../../../../components/ContentComponent';
import AreaComponent from '../../../../components/AreaComponent';
import FormComponent from '../../../../components/FormComponent';

import * as Model from '../../../../models/assinantesModel';
import baseURL from '../../baseURL';

import { Container } from './styles';

export default function CRUDAssinantes(props) {
    const { id } = props.match.params;
    const { replace } = useHistory();
    const dados = {
        title: id ? "Editar assinante" : "Cadastro de assinantes",
        subtitle: id ? "Alterar dados do assinante gerado" : "Adicionar um novo assinante",
        bread: ['Assinantes', id ? 'Editar assinante' : 'Cadastro de assinantes' ]
    };
    const [ inputs, setInputs ] = useState([]);

    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`
    }, [dados.title]);

    useEffect(() => {
        async function init(){
            const inputsNew = [
                {
                    span: "Nome",
                    type: "text",
                    name: "CERTA_NOME",
                    value: "",
                    required: true
                },
                {
                    span: "Função",
                    type: "text",
                    name: "CERTA_FUNCAO",
                    value: "",
                    required: true
                }
            ]
            if(id){
                const res = await Model['SHOW'](id);
                inputsNew.map(input => {
                    input.value = res[0][input.name]
                    return null
                })
            }
            setInputs(inputsNew)
        }
        init();
    }, [id])

    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <AreaComponent title={id ? "Formulário de Alteração" : "Formulário de Cadastro"}>
                <FormComponent
                    id={id}
                    inputs={inputs}
                    submitIcon={<FontAwesomeIcon icon={!id ? faCheck : faEdit} />}
                    submitText={`${!id ? 'Cadastrar' : 'Editar'}`}
                    model="assinantesModel"
                    gridForm="2fr 1fr"
                    return={() => replace(baseURL)}
                />
            </AreaComponent>
        </ContentComponent>
    </Container>
    );
}
