import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import MenuComponent from '../../components/MenuComponent/';
import ContentComponent from '../../components/ContentComponent/';
import AreaComponent from '../../components/AreaComponent/';
import { Container } from './styles';
import * as Model from '../../models/servicosModel';
import { calculateYears } from '../../utils';
import baseURL from './baseURL';
import { debounce, handleSort, sortFunctions } from "../../utils";
import swal from '@sweetalert/with-react';

export default function Servicos() {
    const [ lines, setLines ] = useState([]);
    const filtro = useRef();
    const [sort, setSort] = useState({ currentSort: 'up', column: '' });
    const sortColumns = column => handleSort(column, sort, setSort);
    const { push } = useHistory();
    const dados = {
        title: "Serviços",
        subtitle: "Todos os serviços",
        bread: ['Serviços']
    };

    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`;
        init();
    }, [dados.title]);

    async function handleSearch(){
        if(filtro.current.value === ""){ init(); return; }
        setLines(await Model['SEARCH'](filtro.current.value))
    }

    async function init(){
        setLines(await Model['INDEX']())
    }

    async function handleChangeStatus(id, value){
        swal({
            title: "Você tem certeza?",
            text: "Uma vez desativado, você não poderá mais ultilizar o serviço!",
            icon: "warning",
            dangerMode: true,
            buttons: true,
          })
        .then(async (willDelete) => {
            if (willDelete) {
                const res = await Model['UPDATE'](id, { SER_STATUS: value })
                if (res.affectedRows > 0) {
                    swal("Sucesso!", "o serviço foi desativado!", 'success', { buttons: false, timer: 1000 })
                } else {
                    swal("Ops!", "ocorreu um erro ao alterar! tente novamente mais tarde!", 'error')
                }
                init();
            }
        });
    }


    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <div className="containerButtonNew">
                <input type="text" placeholder="Filtrar..." ref={filtro} onChange={debounce(handleSearch)}/>
                <button onClick={() => push(`${baseURL}/cadastro`)}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <span>Novo</span>
                </button>
            </div>
            <AreaComponent>
                <table>
                    <thead>
                        <tr>
                            <th onClick={() => sortColumns('SER_NOME')}>Nome</th>
                            <th onClick={() => sortColumns('SER_PORTARIA')} width={300}>Portaria</th>
                            <th onClick={() => sortColumns('SER_VALIDADE')} width={300}>Validade</th>
                            <th width={90}>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lines
                            .sort(sortFunctions[sort.currentSort](sort.column))
                            .filter(line => line.SER_STATUS).map((line, key) => {
                                return (
                                    <tr key={key}>
                                        <td title={line.SER_NOME}>{line.SER_NOME}</td>
                                        <td title={line.SER_PORTARIA}>{line.SER_PORTARIA}</td>
                                        <td>{line.SER_INDETERMINADO === 1 ? 'Indeterminado' : calculateYears(line.SER_VALIDADE)}</td>
                                        <td className="buttons">
                                            <button className="edit" title="Editar Serviço" onClick={() => push(`${baseURL}/editar/${line.SER_ID}`)}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                            <button className="desative" title="Desativar Serviço" onClick={() => handleChangeStatus(line.SER_ID, 0)}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </AreaComponent>
        </ContentComponent>
    </Container>
    );
}
