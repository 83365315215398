import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';

import MenuComponent from '../../../../components/MenuComponent';
import ContentComponent from '../../../../components/ContentComponent';
import AreaComponent from '../../../../components/AreaComponent';
import FormComponent from '../../../../components/FormComponent';

import * as Model from '../../../../models/usuariosModel';
import baseURL from '../../baseURL';

import { Container } from './styles';

export default function CRUDUsuarios(props) {
    const { id } = props.match.params;
    const { replace } = useHistory();
    const dados = {
        title: id ? "Editar usuários" : "Cadastro de usuários",
        subtitle: id ? "Alterar dados do usuário gerado" : "Adicionar um novo usuário",
        bread: ['Usuários', id ? 'Editar usuário' : 'Cadastro de usuários' ]
    };
    const [ inputs, setInputs ] = useState([]);

    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`
    }, [dados.title]);

    useEffect(() => {
        async function init(){
            const inputsNew = [
                {
                    span: "Administrador ?",
                    type: "select",
                    options: [{ label: 'Sim', value: 1 }, { label: 'Não', value: 2 }],
                    name: "USU_FK_COA_ID",
                    value: null,
                    required: true
                },
                {
                    span: "Responsável ?",
                    type: "select",
                    options: [{ label: 'Sim', value: 1 }, { label: 'Não', value: 2 }],
                    name: "USU_FK_RESP_ID",
                    value: null,
                    required: true
                },
                {
                    span: "Nome",
                    type: "text",
                    name: "USU_NAME",
                    value: "",
                    required: true
                },
                {
                    span: "Usuário",
                    type: "email",
                    name: "USU_LOGIN",
                    value: "",
                    required: true
                },
                {
                    span: id ? "Senha (Deixe em branco se não quiser alterar)" : "Senha",
                    type: "password",
                    name: "USU_PASS",
                    value: "",
                    required: false
                }
            ]
            if(id){
                const res = await Model['SHOW'](id);
                inputsNew.map(input => {
                    if(input.name === 'USU_PASS') {
                        return null;
                    }
                    if(input.type === 'select'){
                        input.value = {label: res[0][input.name] === 1 ? "Sim" : "Não", value: res[0][input.name]}
                        return null
                    }
                    input.value = res[0][input.name]
                    return null
                })
            }
            setInputs(inputsNew)
        }
        init();
    }, [id])

    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <AreaComponent title={id ? "Formulário de Alteração" : "Formulário de Cadastro"}>
                <FormComponent
                    id={id}
                    inputs={inputs}
                    submitIcon={<FontAwesomeIcon icon={!id ? faCheck : faEdit} />}
                    submitText={`${!id ? 'Cadastrar' : 'Editar'}`}
                    model="usuariosModel"
                    gridForm="2fr 2fr 2fr"
                    return={() => replace(baseURL)}
                />
            </AreaComponent>
        </ContentComponent>
    </Container>
    );
}
