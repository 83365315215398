/*
* Styles Component de CadastroServicos
*/
import styled from 'styled-components';

export const Container = styled.div`
    min-height: 100%;
    background: #eef8fd;
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 360px) {
        display: grid;
        grid-template-columns: 2fr;
        div.area {
            width: 80vw!important;
        }
        div.form-line {
            min-width: 100%!important;
            max-width: 100%!important;
        }
    }
    @media only screen and (min-width: 360px) and (max-width: 768px) {
        div.area {
            width: 85vw!important;
        }
        div.form-line.telefone {
            min-width: 45vw!important;
        }
        div.form-line.fabril {
            min-width: 45vw!important;
        }
        div.form-line.cidade {
            min-width: 45vw!important;
        }
        div.form-line.estado {
            min-width: 25vw!important;
        }
    }
`;


