import api from "../services/api";

const url = "dashboard";

export const CHARTS = async () =>
  await api.get(`/${url}/charts`).then((res) => res.data);
export const CARDS = async () =>
  await api.get(`/${url}/cards`).then((res) => res.data[0]);
export const CERTIFICADOS = async (mounth) =>
  await api.get(`/${url}/certificados/${mounth}`).then((res) => res.data);
