import styled from 'styled-components';

export const CardContainer = styled.div`
    border-radius: 10px;
    padding: 20px;
    box-shadow: 5px 5px 5px #ccc;
    max-height: 65px;
    display: grid;
    cursor: pointer!important;
    grid-template-columns: 3fr 1fr;
    ${props => props};
    div.col1 {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div.icon {
            svg {
                font-size: 40px;
                opacity: 0.3;
            }
        }
        div.span {
            font-size: 12pt;
        }
    }
    div.number {
        display: flex;
        width: 100%;
        justify-content: end;
        span {
            font-size: 30pt;
        }
    }


    @media only screen and (max-width: 360px) {
        padding: 10px;
        div.col1 {
            div.icon {
                svg {
                    font-size: 20px;
                    opacity: 0.3;
                }
            }
            div.span {
                font-size: 8pt;
            }
        }
        div.number {
            display: flex;
            width: 100%;
            justify-content: end;
            span {
                font-size: 20pt;
            }
        }
    }
    @media only screen and (min-width: 360px) and (max-width: 768px) {
        div.number {
            display: flex;
            width: 100%;
            justify-content: end;
            span {
                font-size: 40pt;
            }
        }

        div.span {
            font-size: 20pt;
        }
    }
`;

export const CardsAreaContainer = styled.div`
    display: grid;
    padding: 20px 40px;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    margin-bottom: 40px;
    @media only screen and (max-width: 360px) {
        padding: 10px;
        grid-gap: 5px;
        grid-template-columns: 2fr 2fr;
    }

    @media only screen and (min-width: 360px) and (max-width: 768px) {
        padding: 10px 20px;
        grid-gap: 10px;
        grid-template-columns: repeat(2, 1fr);
    }
`;
