/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Content } from './styles';
import * as ModelDocumentos from '../../../../models/documentosModel';
import AreaComponent from '../../../../components/AreaComponent';
import baseURL from '../../baseURL';
import TopMenu from '../../components/TopMenu';
import { verifyString } from '../../../../utils';
import { format, parseISO } from 'date-fns';
import api from '../../../../services/api';


export default function ArquivosAreaCliente() {
    const [documentos, setDocumentos] = useState([]);
    const [documentosQCCERT, setDocumentosQCCERT] = useState([]);
    const { push } = useHistory();

    
    const params = useParams();

    const dados = {
        title: "Área do Cliente - Dashboard"
    };
    useEffect(() => {
        document.title = `${dados.title}`;
    }, [dados.title]);
    useEffect(() => {
        handleLoadDocuments(params.id)
    }, [])

  

    async function handleOpenDocument(url, id) {
        if(url.includes('http')){
            window.open(url)
        }else {
            window.open(`https://${url}`)
        }
        await api.put(`/certificados_documentos/${id}`, {
            CED_SHOW: 1
        }).then(() => {
            handleLoadDocuments(params.id);
            setDocumentos(documentos.map((line) => {
                    if (line.CED_ID === id){
                        return {...line, CED_SHOW : 1} 
                    }
                    return line
                }
            ))
        });
    }

    async function handleLoadDocuments(id) {        
        setDocumentosQCCERT(await ModelDocumentos['SHOW_QCCERT'](id))
    }

    return (
        <Container>
            <TopMenu processo={params.numCert} />
            
            <Content>
                    <AreaComponent className="anexos" theme="primary" title="Arquivos QCCERT">
                        <div className="table">
                            <table className="primary">
                                <thead>
                                    <tr>
                                        <th width={200} title="Nome">Nome</th>
                                        <th title="Data do envio">Data do envio</th>
                                        <th title="Enviado por">Enviado por</th>
                                        <th title="Ação">Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        documentosQCCERT.map((line, key) => {
                                            return (
                                                <tr key={key} >
                                                    <td title={verifyString(line.CED_NOME)}>
                                                        {line.CED_SHOW !== 1 && <span className="notification">novo</span>} 
                                                        {verifyString(line.CED_NOME)}
                                                    </td>
                                                    <td>{format(parseISO(line.CED_DATA), " dd/MM/yyyy 'às' HH:mm ")}</td>
                                                    <td title={verifyString(line.CED_POR === 'undefined' ? '' : line.CED_POR)}>{verifyString(line.CED_POR === 'undefined' ? '' : line.CED_POR)}</td>
                                                    <td>
                                                        <button className="see" title="Ver Documento" onClick={() => handleOpenDocument(line.CED_CAMINHO, line.CED_ID)}>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </AreaComponent>
            <div className="voltar">
            <button className="back" title="Voltar" onClick={() => push(`${baseURL}/dashboard/`)}>
                <FontAwesomeIcon icon={faArrowCircleLeft}/> Voltar
            </button>                         
            </div>
            </Content>
        </Container>
    );
}
