import { createStore } from 'redux';

function data(state = {}, action) {
    switch(action.type) {
        case 'SET_DATA':{
            delete action.type
            return action
        }
        default: return state
    }
}

const store = createStore(data);

export default store;
