import React, { useState, useEffect, useRef } from 'react';
import swal from '@sweetalert/with-react';
import Models from '../../models';
import Line from './components/Line';
import { Container, ContainerButton } from './styles';
import { format, parseISO, addMonths, isValid } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const FormComponent = (props) => {
    const model = props.model;
    const id = props.id ? props.id : 0;
    const [inputs, setInputs] = useState(props.inputs);
    const [data, setDataValue] = useState({});
    const formComponent = useRef(null);

    useEffect(() => {
        setInputs(props.inputs)
        inputs.map(input => {
            data[input.name] = input.value;
            setDataValue(data);
            return null;
        })
    }, [props.inputs, data, inputs])


    function setData(name, value){
        inputs.filter(input => input.name === name)[0].value = value;

        if(inputs.filter(input => input.name === name)[0].start){
            inputs.filter(input => input.end).map(input => {
                const { start, value } = inputs.filter(input => input.name === name)[0]
                input.value = !isValid(parseISO(value)) ? value : format(addMonths(parseISO(value), start), 'yyyy-MM-dd')
                return null;
            })
            setInputs(inputs);
        }

        setInputs(inputs);
        inputs.map(input => {
            data[input.name] = input.value;
            setDataValue(data);
            return null;
        })
    }

    async function handleSubmit(e){
        e.preventDefault();
        var res = {};
        switch(id){
            case 0: {
                res = await Models[model]['STORE'](data);
                break;
            }
            default: res = await Models[model]['UPDATE'](id, data);
        }
        if(res.code){
            switch(res.errno){
                case 1062: {
                    swal('Erro de duplicidade!', res.sqlMessage, 'error');
                    break;
                }
                case 1406: {
                    swal('Valor digitado maior do que o esperado!', res.sqlMessage, 'error');
                    break;
                }
                default: console.log(res); swal('Ops! Houve um erro na transição', res.sqlMessage, 'error');
            }
        }
        if(res.affectedRows){
            switch(res.affectedRows > 0){
                case true: {
                    swal('Feito!', `${id === 0 ? 'cadastro efetuado' : 'alteração efetuada'} com sucesso!`, 'success', { buttons: false, timer: 3000 });
                    props.return(res.insertId);
                    break;
                }
                default: swal('Ops!', 'ocorreu um erro ao processar o cadastro! tente novamente mais tarde!', 'error');
            }
        }
    }
    return (
        <>
            <Container theme={props.theme} gridForm={props.gridForm} onSubmit={(e) => handleSubmit(e)}>
                {
                    inputs ?
                        inputs.map((input, key) => {
                            return <Line key={key} {...input} setData={setData}/>
                        })
                    :null
                }
                <button style={{display: "none"}} ref={formComponent}/>
            </Container>
            <ContainerButton>
                <button type="submit" onClick={() => formComponent.current.click()}>
                    {props.submitIcon} {props.submitText}
                </button>
                <button className="cancel" onClick={() => { props.return(); }}>
                    <FontAwesomeIcon icon={faTimes} />  Cancelar
                </button>
            </ContainerButton>
        </>
    )

};

export default FormComponent;
