import styled, { keyframes } from 'styled-components';
import bg from '../../../assets/img/bg_servicos.png';
import * as constants from '../../../constants/styles';

const {
    primary
} = constants

const rotate = keyframes`
    0% {
    }
    50% {
        color: white;
    }
    100% {
    }
`;


export const Container = styled.div`
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
    background-image: url(${bg});
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 1px #ccc;
    .row-data {
        display: flex;
    }
    div.load {
        position: absolute;
        display: none;
        min-width: 100%;
        min-height: 100%;
        z-index: 9999;
        background: rgba(255, 255, 255, .9);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 100px;
        img {
            background: ${primary};
            padding: 30px;
            border-radius: 20px;
            width: 300px
        }
        span {
            font-size: 20pt;
            color: ${primary};
            animation: ${rotate} 1s linear infinite;
        }
    }
    div.load.inProcess {
        display: flex;
    }
    span.info {
        background: #4e77af;
        border: 1px groove #4e77af;
        color: white;
        width: 45vw;
        border-radius: 0 0 10px 10px;
        font-style: italic;
        text-align: center;
        padding: 10px 0;
        font-size: 10pt;
    }
    div.folha {
        width: 45vw;
        min-height: 50vh;
        border: 1px groove #4e77af;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(255,255,255,.9);
        border-radius: 10px 10px 0 0 ;
        box-shadow: 1px 1px 5px #efefef;
        img {
            width: 30%;
            padding: 20px;
            margin-top: 30px;
        }

        div.labels {
            display: flex;
            flex-direction: column;
            align-items: center;
            line-height: 40px;
            span.span-status {
                font-size: 12pt;
                color: #4e77af;
            }
            span.status {
                font-size: 20pt;
                padding: 10px;
                border-radius: 20px;
            }
        }

        span.empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            font-size: 20pt;
            width: 100%;
            height: 70%;
            color: #222;
            svg {
                color: #ffd700;
                font-size: 60pt;
                padding: 0 0 30px 0;
            }
        }

        div.infos {
            width: 100%;
            margin: 20px 0 0 0;
            padding: 30px 30px 0 30px;
            div.line1 {
                display:flex;
                flex-direction: column;
            }
            div.line {
                text-align: center;
                width: 100%;
                margin: 0 0 30px 0;
                span {
                    font-size: 9pt;
                    color: #4e77af;
                }
                p {
                    font-size: 16pt;
                    line-height: 30px;
                    margin: 0;
                }
            }
            div.line.scope {
                min-width: 100%;
            }
        }
    }

    @media only screen and (max-width: 360px) {
        span.info {
            min-width: 90vw;
            font-size: 10pt!important;
        }
        div.folha {
            min-width: 90vw;
            img {
                width: 40vw;
            }
            div.labels {
                span.span-status {
                    font-size: 12pt;
                }
                span.status {
                    font-size: 14pt;
                }
            }
            span.empty {
                font-size: 10pt;
                margin: 0 0 20px 0;
                svg {
                    font-size: 30pt;
                }
            }
            div.infos {
                div.line1 {
                    display:flex;
                    flex-direction: column;
                }
                grid-template-columns: 1fr;
                div.line {
                    span {
                        font-size: 6pt;
                    }
                    p {
                        font-size: 7pt;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 360px) and (max-width: 768px) {
        span.info {
            min-width: 90vw;
            font-size: 10pt!important;
        }
        div.folha {
            min-width: 90vw;
            img {
                width: 25vw;
            }
            div.labels {
                span.span-status {
                    font-size: 12pt;
                }
                span.status {
                    font-size: 14pt;
                }
            }
            span.empty {
                font-size: 12pt;
                margin: 0 0 20px 0;
                svg {
                    font-size: 50pt;
                }
            }
            div.infos {
                grid-template-columns: 1fr;
                div.line1 {
                    display:flex;
                    flex-direction: column;
                }
                div.line {
                    span {
                        font-size: 10pt;
                    }
                    p {
                        font-size: 12pt;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1280px) {
        div.folha {
            div.labels {
                span.span-status {
                    font-size: 12pt;
                }
                span.status {
                    font-size: 18pt;
                }
            }
            span.empty {
                font-size: 16pt;
                margin: 0 0 20px 0;
                svg {
                    font-size: 50pt;
                }
            }
            div.infos {
                grid-template-columns: 1fr 1fr 1fr 2fr;
                div.line {
                    span {
                        font-size: 7pt;
                    }
                    p {
                        font-size: 12pt;
                    }
                }
            }
        }
    }
`;
