import styled from 'styled-components';
import * as constants from '../../../constants/styles';

const {
    transition,
    whiteDark,
    primary,
    secondary,
    white,
    boxShadow,
    third
} = constants

export const Container = styled.div.attrs(props => ({
    theme: props.theme
}))`
    position: fixed;
    height: 100%!important;
    width:3%;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items:center;
    z-index: 999;
    div.top {
        z-index: 999;
        position: fixed;
        width: 100%;
        height: 5%;
        top: 0;
        color: ${whiteDark};
        display: flex;
        justify-content: space-between;
        align-items:center;
        background: ${props => constants[props.theme] ? constants[props.theme] : primary};
        padding: 10px;
        span {
            max-width: 15%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 30px;
        }
        span.usuario {
            width: 10%;
            font-size: 11pt;
        }
        img {
            width: 100px;
            cursor: pointer;
        }

        &::after, &::before {
            z-index: 999;
        }
    }
    div.title {
        background: ${props => constants[props.theme] ? constants[props.theme] : secondary};
        z-index: 100;
        height: 100vh;
        width: 40px;
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content:center;
        svg {
            color: ${white};
            padding: 10px;
            font-size: 14pt;
        }
    }

    ul.list {
        position: fixed;
        background: ${white};
        margin: 0;
        display: none;
        padding: 5% 0 20px 0!important;
        height: 95%;
        grid-template-columns: 1fr 1fr;
        z-index:0;
        box-shadow: 1px 1px 5px ${boxShadow};
        transition: ${transition};
        li {
            cursor: pointer;
            transition: ${transition};
            list-style: none;
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            svg {
                font-size: 0pt;
                color: ${props => constants[props.theme] ? constants[props.theme] : primary};
                margin: 5px;
                transition: ${transition};
            }
            span {
                font-size: 0pt;
                color: ${props => constants[props.theme] ? constants[props.theme] : primary};
                transition: ${transition};
            }
        }
    }

    div.title:hover {
        ~ul.list {
            transition: ${transition};
            display: grid;
            transform: translateX(20px);
            li {
                transition: ${transition};
                span {
                    transition: ${transition};
                    font-size: 8pt;
                }
                svg {
                    transition: ${transition};
                    font-size: 18pt;
                }
            }
        }
    }

    ul.list:hover {
        transition: ${transition};
        display: grid;
        transform: translateX(39px);
        li {
            transition: ${transition};
            span {
                transition: ${transition};
                font-size: 8pt;
            }
            svg {
                transition: ${transition};
                font-size: 18pt;
            }
        }
    }

    @media only screen and (max-width: 360px) {
        ul.list, div.title {
            display: none;
        }
        div.title:hover {
            ~ul.list {
                transform: none;
            }
        }
        ul.list:hover {
            transform: none;
        }
        div.top {
            width: 97vw;
            span {
                width: 7%;
            }
            span.usuario {
                display:none;
            }
            img {
                width: 80px;
            }

            div.toggleMenu {
                max-width: 30px;
                max-height: 30px;
                div.title {
                    display: flex;
                    max-width: 30px;
                    max-height: 30px;
                }
                div.title {
                    font-size: 6pt;
                }
                ul.list.visible {
                    display: grid;
                }
                ul.list {
                    position: absolute;
                    right: 20px;
                    width: 45vw;
                    height: 28em;
                    overflow-y: scroll;
                    display: none;
                    grid-template-columns: 1fr;
                    grid-gap: 1px;
                    z-index: 9999!important;
                    padding: 0%!important;
                    li {
                        background: ${third};
                        margin: 0px;
                        font-size: 12pt;
                        display: flex;
                        flex-direction: row;
                        justify-content: start;
                        align-items: center;
                        padding: 10px;
                        cursor: pointer;
                        max-width: 34vw;

                        svg {
                            font-size: 14pt;
                            color: ${primary};
                            margin: 10px;
                            cursor: pointer;
                        }
                        span {
                            color: ${primary};
                            cursor: pointer;
                            min-width: 70%;
                        }
                    }
                    li:hover {
                        background: ${secondary};
                        span {
                            color: ${white};
                        }
                        svg {
                            color: ${white};
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 360px) and (max-width: 768px) {
        ul.list, div.title {
            display: none;
        }
        div.title:hover {
            ~ul.list {
                transform: none;
            }
        }
        ul.list:hover {
            transform: none;
        }
        div.top {
            width: 97vw;
            span {
                width: 7%;
            }
            span.usuario {
                display:none;
            }
            img {
                width: 80px;
            }

            div.toggleMenu {
                max-width: 30px;
                max-height: 30px;
                div.title {
                    display: flex;
                    max-width: 30px;
                    max-height: 30px;
                }
                div.title {
                    font-size: 6pt;
                }
                ul.list.visible {
                    display: grid;
                }
                ul.list {
                    position: absolute;
                    right: 20px;
                    width: 35vw;
                    height: 28em;
                    overflow-y: scroll;
                    display: none;
                    grid-template-columns: 1fr;
                    grid-gap: 1px;
                    z-index: 9999!important;
                    padding: 0%!important;
                    li {
                        background: ${third};
                        margin: 0px;
                        font-size: 12pt;
                        display: flex;
                        flex-direction: row;
                        justify-content: start;
                        align-items: center;
                        padding: 10px;
                        cursor: pointer;
                        max-width: 30vw;
                        svg {
                            font-size: 14pt;
                            color: ${primary};
                            margin: 10px;
                            cursor: pointer;
                        }
                        span {
                            color: ${primary};
                            cursor: pointer;
                            min-width: 70%;
                        }
                    }
                    li:hover {
                        background: ${secondary};
                        span {
                            color: ${white};
                        }
                        svg {
                            color: ${white};
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 1280px) {
        ul.list, div.title {
            display: none;
        }
        div.title:hover {
            ~ul.list {
                transform: none;
            }
        }
        ul.list:hover {
            transform: none;
        }
        div.top {
            width: 100vw;
            span {
                width: 7%;
            }
            span.usuario {
                display:none;
            }
            img {
                width: 80px;
            }

            div.toggleMenu {
                margin: 0 30px 0 0;
                max-width: 30px;
                max-height: 30px;
                div.title {
                    display: flex;
                    max-width: 30px;
                    max-height: 30px;
                }
                div.title {
                    font-size: 6pt;
                }
                ul.list.visible {
                    display: grid;
                }
                ul.list {
                    position: absolute;
                    right: 50px;
                    width: 20vw;
                    height: 28em;
                    overflow-y: scroll;
                    display: none;
                    grid-template-columns: 1fr;
                    grid-gap: 1px;
                    z-index: 9999!important;
                    padding: 0%!important;
                    li {
                        background: ${third};
                        margin: 0px;
                        font-size: 12pt;
                        display: flex;
                        flex-direction: row;
                        justify-content: start;
                        align-items: center;
                        padding: 10px;
                        cursor: pointer;
                        max-width: 17vw;
                        svg {
                            font-size: 14pt;
                            color: ${primary};
                            margin: 10px;
                            cursor: pointer;
                        }
                        span {
                            color: ${primary};
                            cursor: pointer;
                            min-width: 70%;
                        }
                    }
                    li:hover {
                        background: ${secondary};
                        span {
                            color: ${white};
                        }
                        svg {
                            color: ${white};
                        }
                    }
                }
            }
        }
    }
`;

