import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';


import { CardContainer, CardsAreaContainer } from './styles';

export const Card = (props) => {
    return (
        <CardContainer onClick={props.onClick} {...props.theme}>
            <div className="col1">
                <div className="icon">
                    <FontAwesomeIcon icon={Icons[props.icon]} />
                </div>
                <div className="span">
                    <span>{props.label}</span>
                </div>
            </div>
            <div className="number">
                <span>{props.number}</span>
            </div>
        </CardContainer>
    )
};


export const CardsArea = (props) => {
    return(
        <CardsAreaContainer>
            {props.children}
        </CardsAreaContainer>
    )
};
