/*
* Styles Component de CadastroServicos
*/
import styled from 'styled-components';
import * as constants from '../../../../../constants/styles';

const {
    third
} = constants

export const Container = styled.div`
    min-width: 100%;
    min-height: 100%;
    background: ${third};
    padding: 0;
    div.content {
        width:100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .area {
            width: 90%;
            min-height: 400px;

        }

    }
    .buttonContainer {
        width: 100%!important;
        display: flex;
        justify-content: end;
        button.submit {
            border: none;
            padding: 15px 20px;
            font-size:14pt;
            background: #00c696;
            color: white;
            border-radius: 10px;
            margin: 10px;
            box-shadow: 1px 2px 3px #ccc;
            &:hover {
                box-shadow: none;
            }
        }
    }

    @media only screen and (max-width: 360px) {
        div.content {
            font-size: 9pt!important;
            .area {
                width: 80vw;
                max-height: 14em!important;
                overflow-y: scroll;|
            }
            button.submit {
                width: 100%;
                font-size: 11pt;
            }
        }
        .dropzone {
            height: 100px!important;
            font-size: 10pt;
        }
    }
`;


