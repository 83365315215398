import api from '../services/api';

const url = 'certificados';

export const INDEX = async () => await api.get(`/${url}`).then(res => res.data);
export const INDEX_CARDS = async (id) => await api.get(`/${url}/cards/${id}`).then(res => res.data);
export const CARDS = async () => await api.get(`/${url}/cards`).then(res => res.data[0]);
export const FABRICANTES = async (id) => await api.get(`/${url}/fabricantes/${id}`).then(res => res.data);
export const CLIENTES = async (id) => await api.get(`/${url}/clientes/${id}`).then(res => res.data);
export const EMITIR = async (id) => await api.get(`/${url}/emitirCertificacao/${id}`).then(res => res.data);
export const HISTORICO = async (id, who) => await api.get(`/${url}/historico/${who}/${id}`).then(res => res.data);
export const SHOW = async (id) => await api.get(`/${url}/${id}`).then(res => res.data);
export const CONSULTA = async (id) => await api.get(`/${url}/certificate/${id}`).then(res => res.data);
export const FILTER = async (search) => await api.get(`/${url}/filter?search=${search}`).then(res => res.data);
export const STORE = async (data) => await api.post(`/${url}`, {...data}).then(res => res.data);
export const UPDATE = async (id, data) => await api.put(`/${url}/${id}`, {...data}).then(res => res.data);
export const DELETE = async (id) => await api.delete(`/${url}/${id}`).then(res => res.data);
export const DOWNLOAD = async (id, type) => await api.get(`/${url}/download/${type}/${id}`).then(res => res.data);
