/*
* Styles Component de CadastroServicos
*/
import styled from 'styled-components';
import * as constants from '../../../../../constants/styles';
import bg from '../../../../../assets/img/bg_servicos.png';

const {
    third,
    secondary,
    boxShadow,
    primary
} = constants

export const Container = styled.div`
    min-height: 100%;
    background: ${third};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .background {
        background-image: url(${bg});
        filter: blur(5px);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index:0;
    }

    form.login {
        z-index:9999;
        padding: 30px;
        height: 280px;
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background: ${secondary};
        border-radius: 10px;
        box-shadow: 3px 3px 5px ${boxShadow};
        span {
            color:white;
            font-size:18pt;
            padding: 20px;
        }
        img {
            width: 70%;
            margin: 0;
            padding: 0;
        }
        input {
            width: 85%;
            padding: 12px 20px;
            margin: 4px 0;
            border: none;
            border-radius: 20px;
        }

        button {
            width: 100%;
            margin: 10px 0 0 0;
            padding: 10px;
            border: none;
            font-size: 12pt;
            border-radius: 20px;
            color: white;
            background: ${primary}
        }

    }
    @media only screen and (max-width: 360px) {
        form.login {
            width: 200px;
            span {
                font-size: 16pt;
                text-align: center;
            }
        }
    }
`;


