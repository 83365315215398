/*
* Styles Component de CadastroServicos
*/
import styled, { keyframes } from 'styled-components';
import * as constants from '../../../constants/styles';

const {
    third,
    white,
    boxShadow,
    secondary,
    danger,
    def,
    yellow,
    warning,
    primary
} = constants

const rotate = keyframes`
    0% {
    }
    50% {
        color: white;
    }
    100% {
    }
`;

export const Container = styled.div`
    min-height: 100%;
    background: ${third};
    display: flex;
    flex-direction: row;
    div.load {
        position: absolute;
        display: none;
        min-width: 100%;
        min-height: 100%;
        z-index: 9999;
        background: rgba(255, 255, 255, .8);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 100px;
        img {
            background: ${primary};
            padding: 30px;
            border-radius: 20px;
            width: 300px;
        }
        span {
            font-size: 20pt;
            color: ${primary};
            animation: ${rotate} .5s linear infinite;
        }
    }
    div.load.inProcess {
        display: flex;
    }
    div.area {
        max-height: 600px;
        overflow:auto;
        border: none;
        padding: 0;
        margin: 30px 0;
        border-radius: 20px;
    }
    div.cards {
        padding: 20px 0 0 0 ;
        margin: 0;
        width: 100%;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(5, 2fr);
    }
    div.containerButtonNew {
        width: 100%;
        padding: 20px 0 0 0;
        display: flex;
        justify-content: start;
        margin-top: 15px;
        font-size: 12pt;
        button {
            padding: 10px 15px;
            border: none;
            background: ${def};
            color: ${white};
            border-radius: 20px;
            font-size: 10pt;
        }
        div{
            width: 45%;
            display: flex;
            justify-content: start;
            input {
                width: 100%;
                border: .5px solid ${boxShadow};
                border-radius: 20px;
                margin: 0 10px 0 0;
                padding: 8px;
                font-size:10pt;
            }
        }

    }

    table {
        width: 100%;
        margin: 0;
        font-size: 12pt;
        border-radius: 20px;
        th {
            padding: 15px;
            background: ${secondary};
            color: ${white};
            text-align: start;
        }
        td {
            padding: 15px;
            button {
                border: none;
                border-radius: 200px;
                padding: 5px;
                margin: 2px;
                color: ${white};
                width: 25px;
                font-size:9pt;
                box-shadow: 3px 1px 5px #ccc;
            }
            button.disabled {
                background: #aaa;
                color: #eee;
            }
            button.upload {
                background: ${warning};
            }
            button.continue {
                background: ${secondary};
            }
            button.edit {
                background: ${secondary};
            }
            button.print {
                background: ${primary};
            }
            button.conclued {
                background: ${def};
            }
            button.inclonclued {
                background: ${yellow};
            }
            button.canceled {
                background: ${danger};
            }
            button.see {
                background: ${primary};
                width: 100%;
            }
        }
        tr {
            background: ${white};
            &:nth-child(even) {
                background: ${third};
            }
        }

    }
    @media only screen and (max-width: 360px) {
        div.containerButtonNew {
            flex-direction: column;
            justify-content: start;
            align-items: start;
            button {
                width: 85%;
                height: 40px;
                padding: 10px;
                margin: 5px 0 0 0;
            }
            div {
                width: 100%;
                margin: 10px 0;
                flex-direction: column;
                span {
                    padding: 0 0 3px 0;
                }
                input {
                    width: 80%!important;
                    padding: 10px;
                }
            }
        }
        div.area {
            width: 90%;
        }
        div.cards {
            grid-gap: 10px;
            grid-template-columns: repeat(2, 2fr);
            width: 90%;
        }
    }
    @media only screen and (min-width: 360px) and (max-width: 768px) {
        div.containerButtonNew {
            flex-direction: row;
            justify-content: start;
            align-items: center;
            width: 90vw;
            button {
                width: 130px;
                height: 40px;
                padding: 10px;
                font-size: 12pt;
                margin-top: 10px;
            }
            div {
                width: 33%;
                margin: 0;
                flex-direction: column;
                span {
                    padding: 0 0 3px 0;
                    font-size: 9pt;
                }
                input {
                    width: 80%;
                    padding: 10px;
                    font-size: 9pt;
                }
            }
        }
        div.area {
            min-width: 90vw!important;
        }
        div.cards {
            grid-gap: 10px;
            grid-template-columns: repeat(4, 2fr);
            width: 90vw;
        }
    }
`;

export const Process = styled.td`
    ${props => props.process};
    font-weight: 400;
`;

