import React, { useState, useEffect } from 'react';
import { verifyTransition } from '../../../../utils';
import Model from '../../../../models';
import swal from '@sweetalert/with-react';

import { Container } from './styles';

function ModalAndamento({ certificadoID, changeID=false, refresh }) {
    const [ etapa, setEtapa ] = useState("");
    const [ observacoes, setObservacoes ] = useState("");
    
    useEffect(() => {
        if(changeID) {
            init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function init() {
        const { andamentoModel: { SHOW_SIMPLE } } = await Model;

        const response = await SHOW_SIMPLE(changeID);
        setEtapa(response[0].AND_ETAPA)
        setObservacoes(response[0].AND_OBSERVACOES)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!etapa && !changeID) { swal('Espere!', 'Todos os campos são obrigatórios!', 'error'); return; };
        let data = { 
            AND_ETAPA: etapa,
            AND_OBSERVACOES: observacoes,
            AND_FK_CER_ID: certificadoID
        };
        const response = !changeID ? await Model.andamentoModel['STORE'](data) : await Model.andamentoModel['UPDATE'](changeID, data);
        if(!verifyTransition(response)) return;
        if(changeID) {
            init();
            return refresh.setAndamento(refresh.andamento.map(andamento => {
                if(andamento.AND_ID === changeID){
                    return { ...andamento, ...data }
                }
                return andamento
            }).sort((a, b) => a.AND_FK_ETA_ID === null ? 2 : a.AND_FK_ETA_ID > b.AND_FK_ETA_ID ? 1 : -1))
        }
        refresh.setAndamento([...refresh.andamento, {...data, AND_ID: response.insertId}].sort((a, b) => a.AND_FK_ETA_ID === null ? 2 : a.AND_FK_ETA_ID > b.AND_FK_ETA_ID ? 1 : -1))

    }
    
    return (
        <Container>
            {
                !changeID && (
                    <>
                        <span>Etapa</span>
                        <input value={etapa} onChange={({target}) => setEtapa(target.value)}/>
                    </>
                )
            }
            <span>Observações</span>
            <textarea value={observacoes} rows={6} onChange={({target}) => setObservacoes(target.value)}/>
            <button onClick={(e) => handleSubmit(e)}>
                Feito
            </button>
        </Container>
);
}

export default ModalAndamento;
