/*
* Styles Component de CadastroServicos
*/
import styled from 'styled-components';

export const Container = styled.div`
    min-height: 100%;
    background: #eef8fd;
    display: flex;
    flex-direction: row;

    @media only screen and (min-width: 360px) and (max-width: 768px) {
        div.area {
            width: 85vw!important;
        }
    }
`;


