import React, { useEffect, useState } from "react";
import swal from "@sweetalert/with-react";
import { useHistory } from "react-router-dom";
import { Container } from "./styles";
import * as Model from "../../models/portalModel";
import logo from "../../assets/img/logo.png";
import { useSession } from "../../utils";

export default function AreaCliente(props) {
  const { who } = props.match.params;
  const { start } = useSession();
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const { replace } = useHistory();
  const dados = {
    title: "Área do Cliente",
  };

  useEffect(() => {
    document.title = `${dados.title} - QCDOCS`;
  }, [dados.title]);

  async function primeiroAcesso(res) {
    return swal({
      title: "Bem Vindo!",
      text: "Identificamos que é seu primeiro acesso. Gostaria de alterar a sua senha?",
      icon: "info",
      buttons: ["Não", "Sim"],
      closeOnClickOutside: false,
    }).then((mudaSenha) => {
      if (!mudaSenha) {
        Model.UPDATE(res.data[0].USU_ID, {
          CC_PRIMEIRO_ACESSO: 1,
        });
        return continuar(res);
      }

      swal({
        text: "Insira a nova senha:",
        content: {
          element: "input",
          attributes: {
            placeholder: "Senha",
            type: "password",
          },
        },
        button: {
          text: "Enviar",
          closeModal: false,
        },
      }).then(async (password) => {
        if (!password) {
          return swal("A senha não pode ficar em branco!", " ", "warning", {
            timer: 3000,
            buttons: false,
          });
        }

        Model.UPDATE(res.data[0].CC_ID, {
          CC_PRIMEIRO_ACESSO: 1,
        });
        Model.UPDATE(res.data[0].CC_ID, {
          CC_SENHA_PORTAL: password,
        });

        swal.close();

        continuar(res);
      });
    });
  }

  async function continuar(res) {
    localStorage.setItem("token", res.token);

    start("client-session", {
      payload: {
        user: res.data,
        who,
        id:
          who === "fabricantes"
            ? res.data[0].FC_FK_FAB_ID
            : res.data[0].CC_FK_CLI_ID,
      },
    });

    //REGISTRA O ULTIMO ACESSO
    await Model["REGISTRATE"](
      who,
      who === "fabricantes" ? res.data[0].FC_ID : res.data[0].CC_ID
    );

    replace(`/portal/home`);
  }

  async function handleAuthenticate(e) {
    if (e) {
      e.preventDefault();
    }
    var res = await Model["AUTHENTICATE"](who, code, email);

    if (res.authenticate) {
      if (!res.data[0].CC_PRIMEIRO_ACESSO) {
        return primeiroAcesso(res);
      }
      continuar(res);
    } else {
      swal(
        "Opa!",
        "Algo deu errado! verifique seu código para continuar",
        "warning"
      );
    }
  }

  return (
    <Container>
      <div className="background" />
      <form className="login" onSubmit={(e) => handleAuthenticate(e)}>
        <img src={logo} alt="Logo" />
        <span>Área do Cliente</span>
        <input
          type="text"
          placeholder="Email do Contato"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          className="pass"
        />
        <input
          type="password"
          placeholder="Código do Cliente"
          value={code}
          onChange={({ target }) => setCode(target.value)}
          className="pass"
        />
        <button className="submit" onClick={() => handleAuthenticate()}>
          Entrar
        </button>
      </form>
    </Container>
  );
}
