/*
* Styles Component de CadastroServicos
*/
import styled from 'styled-components';
import * as constants from '../../../constants/styles';

const {
    third,
    white,
    boxShadow,
    secondary,
    warning,
    def
} = constants

export const Container = styled.div`
    min-height: 100%;
    background: ${third};
    display: flex;
    flex-direction: row;
    div.area {
        max-height: 600px;
        overflow:auto;
        border: none;
        padding: 0;
    }
    div.containerButtonNew {
        width: 99%;
        display: flex;
        justify-content: start;
        margin-top: 15px;
        button {
            padding: 10px 20px;
            border: none;
            background: ${def};
            color: ${white};
            border-radius: 5px;
            font-size: 14pt;

            svg {
                margin-right: 10px;
            }
        }

        input {
            width: 25%;
            border: .5px solid ${boxShadow};
            border-radius: 5px;
            margin: 0 10px 0 0;
            padding: 10px;
        }
    }

    table {
        width: 100%;
        margin: 0;
        th {
            padding: 15px;
            background: ${secondary};
            color: ${white};
            text-align: start;
        }
        td {
            padding: 15px;
            button.action {
                border: none;
                border-radius: 5px;
                padding: 10px;
                color: ${white};
                width: 90%;
                margin: 0 2px;
            }
            button.edit {
                background: ${warning};
            }
        }
        tr {
            background: ${white};
            &:nth-child(even) {
                background: ${third};
            }
        }

    }
    @media only screen and (min-width: 360px) and (max-width: 768px) {
        div.containerButtonNew {
            input {
                width: 50%;
            }
        }
        table {
            td {
                button.edit {
                    span {
                        display: none;
                    }
                }
            }
        }
    }
`;


