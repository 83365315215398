import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    html, body, div#root, input[type=date]{
        margin: 0;
        padding: 0;
        height: 100%!important;
        font-family: 'Century Gothic', sans-serif, Helvetica;
        font-size: 12pt!important;
        overflow-x: hidden;
    }
    textarea, input {
        font-family: 'Century Gothic', sans-serif, Helvetica;
    }
    button {
        outline: none!important;
        &:hover {
            opacity: .8;
        }
    }
    .nanoDisplay {
        display: none;
    }
    .click {
        cursor: pointer;
    } 
    th {
        user-select: none;
        cursor: pointer;
        resize: horizontal;
    }
    .notification{
        color: white;
        background-color: #dd4c34;
        padding: 0px 4px;
        border-radius: 50px;
        font-size: 7pt;
        margin: 3px;
        position: relative;
        top: -10px;
    }
    table {
        font-size: 10pt!important;
        
        thead th {
            position: -webkit-sticky; /* for Safari */
            position: sticky;
            top: 0;
        }

        tbody tr {
            td {
                max-width: 100px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 30px;
            }
        }
    }
    button {
        border-radius: 20px!important;
        cursor: pointer!important;
    }
    .disable {
        background: #ccc!important;
        color: #555!important;
    }
    div.containerButtonNew {
        input {
            border-radius: 20px!important;
        }
        button {
            font-size: 10pt!important;
        }
    }
    .selectForm {
        font-size: 10pt;
        width: 100%;
    }
    @media only screen and (max-width: 360px) {
        div.area {
            width: 90%;
        }
        div.containerButtonNew {
            flex-direction: row;
            justify-content: center;
            align-items: center;
            button {
                display:flex;
                justify-content: center;
                align-items: center;
                padding:10px!important;
                font-size: 10pt!important;
            }
            input {
                width: 60%!important;
                padding: 5px;
                margin: 0 0 0;
            }
            div {
                width: 100%;
                margin: 10px 0;
                flex-direction: column;
            }
        }
    }
    @media only screen and (min-width: 360px) and (max-width: 768px) {
        div.containerButtonNew {
            button {
                font-size: 12pt;
            }
            div {
                span {
                    font-size: 12pt!important;
                }
                input {
                    font-size: 12pt!important;
                }
            }
        }
        div.area {
            width: 90vw;
        }
    }
    @media only screen and (max-width: 1280px) {
        .nanoDisplay {
            display: block;
            font-size: 9pt;
        }
    }
    .download-link {
        border-radius: 20px !important;
        cursor: pointer !important;
        font-size: 12pt;
        padding: 8px;
        background: #2abbac;
        color: #fff;
        margin-right: 10px; /* Adicione margem direita para espaçamento entre os links, se necessário */
    }
`;
