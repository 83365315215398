/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';

import MenuComponent from '../../../../components/MenuComponent';
import ContentComponent from '../../../../components/ContentComponent';
import AreaComponent from '../../../../components/AreaComponent';
import FormComponent from '../../../../components/FormComponent';

import * as Model from '../../../../models/servicosModel';
import * as ModelModelo from '../../../../models/modelos_certificadosModel';

import { Container } from './styles';
import baseURL from '../../baseURL';

export default function CRUDServicos(props) {
    const { id } = props.match.params;
    const { replace } = useHistory();
    const dados = {
        title: id ? "Editar Serviço" : "Cadastro de Serviços",
        subtitle: id ? "Alterar dados de serviço gerado" : "Adicionar um novo serviço",
        bread: ['Serviços', id ? 'Editar Serviço' : 'Cadastro de Serviços' ]
    };
    const [ inputs, setInputs ] = useState([]);
    const indeterminado = [{ value: 0, label: 'Não' }, { value: 1, label: 'Sim' }]

    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`
    }, [dados.title]);

    useEffect(() => {
        async function init(){
            const models = await ModelModelo['INDEX_MODELOS']();
            const inputsNew = [
                {
                    span: "Nome",
                    name: "SER_NOME",
                    value: "",
                    required: true,
                    size: '40%'
                },
                {
                    span: "Portaria",
                    name: "SER_PORTARIA",
                    value: "",
                    required: true,
                    size: '20%'
                },
                {
                    span: "Validade (por meses)",
                    type: "number",
                    name: "SER_VALIDADE",
                    value: "",
                    required: true,
                    size:'12%'
                },
                {
                    span: "Modelo",
                    name: "SER_FK_MOC_ID",
                    type: "select",
                    value: null,
                    options: models.map(model => {return { label: `${model.MOC_ID} - ${model.MOC_NAME.split('-')[1]}`, value: model.MOC_ID }}),
                    required: true,
                    size: '20%'
                },
                {
                    span: "Indeterminado?",
                    name: "SER_INDETERMINADO",
                    type: "select",
                    value: 0,
                    options: indeterminado,
                    required: true,
                    size: '20%'
                },
                {
                    name: "SER_STATUS",
                    type: "hidden",
                    value: 1
                }
            ]
            if(id){
                const res = await Model['SHOW'](id);
                inputsNew.map(input => {
                    if(input.type === 'select'){
                        input.value = input.options.find(option => option.value === res[0][input.name])
                        return null
                    }
                    input.value = res[0][input.name]
                    return null
                })
            }
            setInputs(inputsNew)
        }
        init();
    }, [id])

    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <AreaComponent title={id ? "Formulário de Alteração" : "Formulário de Cadastro"}>
                <FormComponent
                    id={id}
                    inputs={inputs}
                    submitIcon={<FontAwesomeIcon icon={!id ? faCheck : faEdit} />}
                    submitText={`${!id ? 'Cadastrar' : 'Editar'}`}
                    model="servicosModel"
                    return={() => replace(baseURL)}
                />
            </AreaComponent>
        </ContentComponent>
    </Container>
    );
}
