import api from '../services/api';
import { format } from 'date-fns';

const url = 'portal';

export const AUTHENTICATE = async (who, code, email) => await api.get(`/${url}/${who}/authenticate/${code}/${email}`).then(res => res.data);
export const REGISTRATE = async (who, id) => await api.put(`/${url}/${who}/registrate/${id}`, who === 'fabricantes' ? {FC_ULTIMO_ACESSO: format(new Date(), 'yyyy-MM-dd HH:mm:ss')} : {CC_ULTIMO_ACESSO: format(new Date(), 'yyyy-MM-dd HH:mm:ss')}).then(res => res.data);
export const CERTIFICATE = async (status, who, id) => await api.get(`/${url}/${who}/${id}/${status}`).then(res => res.data);
export const CARDS = async (who, id) => await api.get(`/${url}/cards/${who}/${id}`).then(res => res.data[0]);
export const UPDATE = async (id, data) => await api.put(`/${url}/cliente/registrate/${id}`, data).then(res => res.data[0]);
