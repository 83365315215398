/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';

import MenuComponent from '../../../../components/MenuComponent';
import ContentComponent from '../../../../components/ContentComponent';
import AreaComponent from '../../../../components/AreaComponent';
import FormComponent from '../../../../components/FormComponent';

import Model from '../../../../models';

import { Container } from './styles';
import baseURL from '../../baseURL';

export default function CRUDContatos(props) {
    const idContact = props.location.search.split('=')[1] ? props.location.search.split('=')[1] : false;

    const { id, who } = props.match.params;

    const modo = props.cadastro ? 'Cadastro' : 'Alteração';
    const textModo = props.cadastro ? 'Cadastrar' : 'Editar';
    const iconModo = props.cadastro ? faCheck : faEdit;
    const modelModo = who === 'fabricantes' ? 'fabricantes_contatosModel' : 'clientes_contatosModel';

    const { replace } = useHistory();
    const [ dados, setDados ] = useState({});
    const [ inputs, setInputs ] = useState([]);

    useEffect(() => {
        init();
    }, [])

    async function init(){
        const cargos = await Model['cargosModel']['INDEX']();
        switch(who){
            case 'fabricantes':{
                const inputsNew = [
                    {
                        span: "Nome",
                        type: "text",
                        name: "FC_NOME",
                        value: "",
                        required: true,
                        size: '45%'
                    },
                    {
                        span: "Cargo",
                        type: "select",
                        name: "FC_FK_CAR_ID",
                        value: "",
                        options: cargos.map(cargo => {return { label: cargo.CAR_NOME, value: cargo.CAR_ID }}),
                        required: false,
                        size: '48%'
                    },
                    {
                        span: "Telefone",
                        type: "text",
                        name: "FC_TELEFONE",
                        value: "",
                        mask: "FONE",
                        required: false,
                        maxLength: 20,
                        size: '45%'
                    },
                    {
                        span: "Skype",
                        type: "text",
                        name: "FC_SKYPE",
                        value: "",
                        required: false,
                        maxLength: 20,
                        size: '45%'
                    },
                    {
                        span: "Email",
                        type: "email",
                        name: "FC_EMAIL",
                        value: "",
                        required: false,
                        size: '48%'
                    },
                    {
                        type: "hidden",
                        name: "FC_FK_FAB_ID",
                        value: id,
                    }
                ]
                fabricantes();
                switch(props.cadastro){
                    case true: setInputs(inputsNew); break;
                    default: {
                        const res = await Model.fabricantes_contatosModel['SHOW'](idContact);
                        inputsNew.map(input => {
                            if(input.type === 'select'){
                                input.value = {label: res[0].CAR_NOME, value: res[0].CAR_ID}
                                return null
                            }
                            input.value = res[0][input.name]
                            return null
                        })
                        setInputs(inputsNew)
                        break;
                    }
                }
                break;
            }
            case 'clientes':{
                const inputsNew = [
                    {
                        span: "Nome",
                        type: "text",
                        name: "CC_NOME",
                        value: "",
                        required: true,
                        size: '45%'
                    },
                    {
                        span: "Cargo",
                        type: "select",
                        name: "CC_FK_CAR_ID",
                        value: "",
                        options: cargos.map(cargo => {return { label: cargo.CAR_NOME, value: cargo.CAR_ID }}),
                        required: false,
                        size: '48%'
                    },
                    {
                        span: "Telefone",
                        type: "text",
                        name: "CC_TELEFONE",
                        value: "",
                        required: false,
                        mask: "FONE",
                        maxLength: 20,
                        size: '45%'
                    },
                    {
                        span: "Skype",
                        type: "text",
                        name: "CC_SKYPE",
                        value: "",
                        required: false,
                        maxLength: 20,
                        size: '45%'
                    },
                    {
                        span: "Email",
                        type: "email",
                        name: "CC_EMAIL",
                        value: "",
                        required: false,
                        size: '48%'
                    },
                    {
                        type: "hidden",
                        name: "CC_FK_CLI_ID",
                        value: id,
                    }
                ]
                clientes();
                switch(props.cadastro){
                    case true: setInputs(inputsNew); break;
                    default: {
                        const res = await Model.clientes_contatosModel['SHOW'](idContact);
                        inputsNew.map(input => {
                            if(input.type === 'select'){
                                input.value = {label: res[0].CAR_NOME, value: res[0].CAR_ID}
                                return null
                            }
                            input.value = res[0][input.name]
                            return null
                        })
                        setInputs(inputsNew)
                        break;
                    }
                }
                break;
            }
        }
    }

    async function fabricantes() {
        const res = await Model.fabricantesModel['SHOW'](id);
        setDados({
            title: res[0].FAB_RAZAO_SOCIAL,
            subtitle: `${modo} de contato`,
            bread: ['Fabricantes', 'Contatos', res[0].FAB_RAZAO_SOCIAL, modo]
        })
        document.title = `${res[0].CLI_RAZAO_SOCIAL} - QCDOCS`;
    }
    async function clientes() {
        const res = await Model.clientesModel['SHOW'](id);
        setDados({
            title: res[0].CLI_RAZAO_SOCIAL,
            subtitle: `${modo} de contato`,
            bread: ['Clientes', 'Contatos', res[0].CLI_RAZAO_SOCIAL, modo]
        })
        document.title = `${res[0].CLI_RAZAO_SOCIAL} - QCDOCS`;
    }

    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <AreaComponent title={`Formulário de ${modo}`}>
                <FormComponent
                    id={idContact}
                    inputs={inputs}
                    submitIcon={<FontAwesomeIcon icon={iconModo} />}
                    submitText={textModo}
                    model={modelModo}
                    return={() => replace(`${baseURL}/${who}/${id}`)}
                />
            </AreaComponent>
        </ContentComponent>
    </Container>
    );
}
