import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import MenuComponent from '../../components/MenuComponent/';
import ContentComponent from '../../components/ContentComponent/';
import AreaComponent from '../../components/AreaComponent/';
import { Container } from './styles';
import * as Model from '../../models/fabricantesModel';
import baseURL from './baseURL';
import { captalizeString, verifyString, verifyTableString, handleSort, sortFunctions } from '../../utils';
import { debounce } from "../../utils";

export default function Fabricantes() {
    const [ lines, setLines ] = useState([]);
    const filtro = useRef();
    const { push } = useHistory();
    const [sort, setSort] = useState({ currentSort: 'up', column: '' });
    const sortColumns = column => handleSort(column, sort, setSort);
    const dados = {
        title: "Fabricantes",
        subtitle: "Todos os Fabricantes",
        bread: ['Fabricantes']
    };

    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`;
        init();
    }, [dados.title]);

    async function handleSearch(){
        if(filtro.current.value === ""){ init(); return; }
        setLines(await Model['SEARCH'](filtro.current.value))
    }

    async function init(){
        setLines(await Model['INDEX']())
    }

    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <div className="containerButtonNew">
                <input type="text" placeholder="Filtrar..." ref={filtro} onChange={debounce(handleSearch)}/>
                <button onClick={() => push(`${baseURL}/cadastro`)}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    Novo
                </button>
            </div>
            <AreaComponent>
                <table>
                    <thead>
                        <tr>
                            <th onClick={() => sortColumns('FAB_RAZAO_SOCIAL')}>Razão Social</th>
                            <th onClick={() => sortColumns('FAB_ENDERECO')} width={300}>Endereço</th>
                            <th onClick={() => sortColumns('FAB_CNPJ')} width={300}>CNPJ</th>
                            <th onClick={() => sortColumns('FAB_TELEFONE')} width={300}>Telefone</th>
                            <th width={100}>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lines
                            .sort(sortFunctions[sort.currentSort](sort.column))
                            .map((line, key) => {
                                return (
                                    <tr key={key}>
                                        <td title={line.FAB_RAZAO_SOCIAL}>{line.FAB_RAZAO_SOCIAL}</td>
                                        {
                                            line.FAB_PAIS !== 'Brasil' ?
                                                <>
                                                    <td title={`${line.FAB_ENDERECO}, ${captalizeString(line.FAB_PAIS)}`}>{line.FAB_ENDERECO}, {captalizeString(line.FAB_PAIS)} </td>
                                                    <td> - </td>
                                                 </>
                                            :
                                            <>
                                                <td title={`${verifyTableString(line.FAB_ENDERECO)}${verifyTableString(line.FAB_NUMERO)}${verifyTableString(line.FAB_COMPLEMENTO)}${verifyTableString(line.FAB_BAIRRO)}${verifyTableString(line.FAB_CEP)}${verifyTableString(line.FAB_CIDADE)}${verifyTableString(line.FAB_ESTADO)}${captalizeString(line.FAB_PAIS)}`}>
                                                    {verifyTableString(line.FAB_ENDERECO)}{verifyTableString(line.FAB_NUMERO)}{verifyTableString(line.FAB_COMPLEMENTO)}{verifyTableString(line.FAB_BAIRRO)}{verifyTableString(line.FAB_CEP)}{verifyTableString(line.FAB_CIDADE)}{verifyTableString(line.FAB_ESTADO)}{captalizeString(line.FAB_PAIS)}
                                                </td>
                                                <td>{verifyString(line.FAB_CNPJ)}</td>
                                            </>
                                        }
                                        <td>{verifyString(line.FAB_TELEFONE)}</td>
                                        <td>
                                            <button className="action edit" title="Editar" onClick={() => push(`${baseURL}/editar/${line.FAB_ID}`)}>
                                                <FontAwesomeIcon icon={faEdit} /> Editar
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </AreaComponent>
        </ContentComponent>
    </Container>
    );
}
