/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Models from '../../../../models';
import { Container } from './styles';
import swal from '@sweetalert/with-react';


export default function PrintCertificados(props) {
    const { id } = props.match.params;
    const [ link, setLink ] = useState("");
    const [ ok, setOk ] = useState(false);

    const dados = {
        title: "Certificado Gerado"
    };
    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`
    }, [dados.title]);


    useEffect(() => {
        async function init(){
            const linkCertificado = await Models.certificadosModel['EMITIR'](id);
            switch(linkCertificado.status){
                case true: {
                    setLink(linkCertificado.value);
                    setOk(linkCertificado.status)
                    break;
                }
                default: {
                    setOk(linkCertificado.status)
                    console.log(linkCertificado.value)
                    swal('Foi encontrado um erro no modelo',
                    `${linkCertificado.value.id}
                     ${linkCertificado.value.explanation} `, 'error');
                }
            }
        }
        init();
    }, []);

    return(
        <Container>
            {
                ok ?
                    <iframe src={link} title={link} width="100%" height="100%"/>
                :null
            }
        </Container>
    );
}
