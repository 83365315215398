import api from '../services/api';

const url = 'assinantes';

export const INDEX = async () => await api.get(`/${url}`).then(res => res.data);
export const SHOW = async (id) => await api.get(`/${url}/${id}`).then(res => res.data);
export const SEARCH = async (value) => await api.get(`/${url}/search/value?value=${value}`).then(res => res.data);
export const STORE = async (data) => await api.post(`/${url}`, {...data}).then(res => res.data);
export const UPDATE = async (id, data) => await api.put(`/${url}/${id}`, {...data}).then(res => res.data);
export const DELETE = async (id) => await api.delete(`/${url}/${id}`).then(res => res.data);
