import axios from "axios";
import server from "./server";
import swal from "@sweetalert/with-react";

const api = axios.create({
  baseURL: server,
  headers: {
    token: localStorage.getItem("token"),
  },
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) =>
    !!error.response
      ? swal(error.response.statusText, error.response.data, "warning")
      : console.log(error)
);

export const apiCorreios = axios.create({
  baseURL: "https://viacep.com.br/ws/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
