import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTimes, faCheckCircle, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Container } from './styles';
import { useSession, verifyString } from '../../../../utils';
import AreaComponent from '../../../../components/AreaComponent';
import Upload from "../../../../components/UploadComponent";
import FileList from "../../../../components/FileListComponent";
import { uniqueId } from "lodash";
import filesize from "filesize";
import api from '../../../../services/api';
import logo from '../../../../assets/img/logo.png';
import MenuComponent from '../../../../components/MenuComponent';
import ContentComponent from '../../../../components/ContentComponent/';
import { format, parseISO } from 'date-fns';
import * as Model from '../../../../models/clientesModel';
import * as ModelDocumentos from '../../../../models/documentosModel';
import baseUrl from '../../../AreaCliente/baseURL';
import swal from '@sweetalert/with-react';

export default class UploadToClient extends Component {

    state = {
        userLogged: useSession(value => value, 'simple-session'),
        uploadedFiles: [],
        dados: {
            title: "Upload de Documentos",
            subtitle: "",
            bread: [this.props.match.params.who === 'fabricantes' ? 'Fabricantes' : 'Clientes']
        },
        loading: false,
        params: this.props.match.params,
        documentos: [],
        client: {}
    };

    async componentWillMount() {
        if (this.state.params.requerent) {
            const client = await Model['SHOW'](this.state.params.requerent).then(res => res[0]);
            this.setState({
                dados: {
                    ...this.state.dados,
                    subtitle: client.CLI_RAZAO_SOCIAL,
                    bread: [...this.state.dados.bread, client.CLI_RAZAO_SOCIAL]
                },
                client
            });
        }
        this.setState({ documentos: await ModelDocumentos['SHOW_QCCERT'](this.state.params.id) })
    }

    handleUpload = files => {
        const uploadedFiles = files.map(file => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            url: null
        }));

        this.setState({
            uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
        });

        uploadedFiles.forEach(this.processUpload);
    };

    updateFile = (id, data) => {
        this.setState({
            uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
                return id === uploadedFile.id
                    ? { ...uploadedFile, ...data }
                    : uploadedFile;
            })
        });
    };

    processUpload = async (uploadedFile) => {
        const data = new FormData();
        data.append("file", uploadedFile.file, uploadedFile.name);
        await api
            .post(`portal/posts/${this.state.userLogged.user[0].USU_NAME}/${this.state.params.id}?qccert=1`, data, {
                onUploadProgress: e => {
                    const progress = parseInt(Math.round((e.loaded * 100) / e.total));

                    this.updateFile(uploadedFile.id, {
                        progress
                    });
                }
            })
            .then(response => {
                this.updateFile(uploadedFile.id, {
                    uploaded: true,
                    id: response.data.affectedRows
                });
            })
            .catch(() => {
                this.updateFile(uploadedFile.id, {
                    error: true
                });
            });

        this.setState({ documentos: await ModelDocumentos['SHOW_QCCERT'](this.state.params.id), uploadedFiles: []})
    };

    handleDelete = async (id) => {
        swal({
            title: "Você tem certeza?",
            text: "Uma vez excluído, você não poderá recuperar esse registro!",
            icon: "warning",
            dangerMode: true,
            buttons: ['Não', 'Sim'],
          })
        .then(async (willDelete) => {
            if (willDelete) {
                const res = await ModelDocumentos['DELETE'](id);
                if (res.affectedRows > 0) { swal("Sucesso!", "o registro foi removido do banco de dados", 'success', { buttons: false, timer: 2000 }) } else { if (res.errno === 1451) { swal("Não foi possivel!", "Não foi possivel excluir pois o mesmo possui vinculos ao sistema!", 'error') } else { swal("Ops!", "ocorreu um erro ao remover! tente novamente mais tarde!", 'error') } }
                this.setState({ documentos: await ModelDocumentos['SHOW_QCCERT'](this.state.params.id), uploadedFiles: []})
            }
        });

    };


    handleConclued = async () => {
        this.setState({
            loading: true
        });
        await api.get(`${baseUrl}/sendConclued/client/${this.state.params.id}`);
        this.props.history.goBack()
        this.setState({
            loading: false
        });
    }

    render() {
        document.title = `${this.state.dados.title}`;
        return (
            <Container>
                <div className={`load ${this.state.loading ? "inProcess" : ""}`}>
                    <img src={logo} alt="QCCERT" />
                    <span>Carregando... </span>
                </div>
                <MenuComponent {...this.state.dados} />

                <ContentComponent {...this.state.dados}>
                    <div className="content">
                        <AreaComponent title="Área de transferência" theme="warning">
                            <Upload onUpload={this.handleUpload} />
                            <div className="buttonContainer">
                                <button onClick={this.handleConclued} className="submit">
                                <FontAwesomeIcon icon={faCheckCircle} /> Concluir Envio
                                </button>
                            </div>
                            {!!this.state.uploadedFiles.length && (
                                <FileList files={this.state.uploadedFiles} onDelete={this.handleDelete} />
                            )}
                        </AreaComponent>
                        <AreaComponent title="Lista de documentos">
                            <div className="table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th width="50%" title="Nome">Nome</th>
                                            <th width="20%" title="Data do envio">Data do envio</th>
                                            <th title="Enviado por">Enviado por</th>
                                            <th width={100} title="Ação">Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.documentos.map((line, key) => {
                                                return (
                                                    <tr key={key} >
                                                        <td title={verifyString(line.CED_NOME)}>{verifyString(line.CED_NOME)}</td>
                                                        <td>{format(parseISO(line.CED_DATA), " dd/MM/yyyy 'às' HH:mm ")}</td>
                                                        <td title={verifyString(line.CED_POR === 'undefined' ? '' : line.CED_POR)}>{verifyString(line.CED_POR === 'undefined' ? '' : line.CED_POR)}</td>
                                                        <td>
                                                            <button className="see" title="Ver Documento" onClick={() => window.open(line.CED_CAMINHO)}>
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </button>
                                                            <button className="deleted" title="Excluir Documento" onClick={() => this.handleDelete(line.CED_ID)}>
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </AreaComponent>
                    </div>
                    <div className="rodape">
                        <button className="back" title="Voltar" onClick={() => this.props.history.goBack()}>
                            <FontAwesomeIcon icon={faArrowCircleLeft}/> Voltar
                        </button>                        
                    </div>
                </ContentComponent>
            </Container>
        );
    }
}
