/*
* Styles Component de CadastroServicos
*/
import styled from 'styled-components';
import * as Constants from '../../../../../constants/styles';

const {
    def
} = Constants

export const Container = styled.div`
    min-height: 100%;
    background: #eef8fd;
    display: flex;
    flex-direction: row;

    .contentContainer {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 30px;
    }

    .area {
        max-width: 99%!important;
        margin-bottom: 0;
        padding: 10px;
    }

    .dropzone {
        height: 50%;
    }

    .noModel {
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            color: #ccc;
            font-size: 18pt;
        }
    }

    /*====*/
    div.button {
        width: 98%;
        padding: 10px;
        display: flex;
        justify-content: flex-end;
        button {
            border: none;
            padding: 10px 20px;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            background: ${def};
            color: white;
        }
    }
    @media only screen and (max-width: 360px) {
        .area {
            width: 80vw!important;
            margin-bottom: 20px;
            overflow-y: scroll;
        }
        .contentContainer {
            grid-template-columns: 2fr;
            grid-gap: 0px;
        }
        div.button {
            button {
                font-size: 9pt;
            }
        }
    }

    @media only screen and (min-width: 360px) and (max-width: 768px) {
        .area {
            width: 85vw!important;
            margin-bottom: 20px;
            overflow-y: scroll;
        }
        .contentContainer {
            grid-template-columns: 2fr;
            grid-gap: 0px;
        }
        div.button {
            button {
                font-size: 9pt;
            }
        }
    }


`;


