/*
* Styles Component de CadastroServicos
*/
import styled from 'styled-components';

export const Container = styled.div`
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
`;


