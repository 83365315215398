/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import * as Model from '../../../../../../models/certificados_relacao_documentosModel';
import { verifyTransition } from '../../../../../../utils';

import { Container, Line } from './styles';

export default function RelacaoDocuments(props) {
    const [ docs, setDocs ] = useState([]);
    const [ disabledSave, setDisabledSave ] = useState(true);
    const { id } = props;

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        docs.map(doc => {
            if(!doc.CRD_ID && docs.length > 1){
                setDisabledSave(false);
            }
            return null;
        })
    }, [docs])

    async function init(){
        const res = await Model['SEARCH'](id);
        setDocs(res.length <= 0 ? [{}] : res);
    }

    function handleChangeValues(name, value, id){
        setDisabledSave(false);
        var temp = docs;
        if(!temp[id]){
            docs[id] = { [name]: value };
            setDocs(docs);
            return
        }
        setDocs(temp.map((valueTemp, key) => {
            if(key === id){
                return { ...temp[id], [name]: value }
            }
            return valueTemp
        }))
    }

    async function handleSubmit(e) {
        setDisabledSave(true);
        e.preventDefault();
        const res = await docs.map(async doc => {
            if(!doc) {
                return
            }
            if(!doc.CRD_ID){
                doc = {CRD_FK_CER_ID: id, ...doc}
                return await Model['STORE'](doc);
            }
            var idChange = doc.CRD_ID;
            if(Object.values(doc).filter(doc => !["", null, undefined].includes(doc)).length <= 2) {
                return await Model['DELETE'](idChange);
            }
            delete doc.CRD_ID;
            return await Model['UPDATE'](idChange, doc);
        })
        if(await res[0]){ verifyTransition(await res[0]) };
        init();
    }

    async function handleCloneLine(e, id){
        e.preventDefault();
        docs.map(async (doc, key) => {
            if(key ===  id){
                let newDoc = {}
                await Object.keys(doc)
                    .filter(keyFilter => keyFilter !== 'CRD_ID')
                    .map(keyMap => {
                        newDoc = {...newDoc, [keyMap]: doc[keyMap]}
                        return null;
                    })
                setDocs([...docs, newDoc]);
            }
        })
    }

    async function handleDeleteLine(e, id){
        e.preventDefault();
        await Model['DELETE'](id);
        init();
    }


    return (
        <Container theme="def">
            {
                docs.map((doc, key) => {
                    return (
                        <Line key={key}>
                            <div className="form-line">
                                <span>Documentos</span>
                                <input value={doc ? doc.CRD_NOME : null } onChange={({target}) => handleChangeValues('CRD_NOME', target.value, key)}/>
                            </div>
                            <div className="form-line">
                                <span>Descrição dos Documentos</span>
                                <input value={doc ? doc.CRD_DESCRICAO : null } onChange={({target}) => handleChangeValues('CRD_DESCRICAO', target.value, key)}/>
                            </div>
                            <div className="form-line">
                                <span>Versão/ Emissão </span>
                                <input value={doc ? doc.CRD_VERSAO_EMISSAO : null } onChange={({target}) => handleChangeValues('CRD_VERSAO_EMISSAO', target.value, key)}/>
                            </div>

                            <div className="containerCloneLine">
                                <button className="cloneLine" onClick={(e) => handleCloneLine(e, key)}>
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </button>
                                {
                                    key !== 0 ?
                                        <button className="deleteLine" onClick={async (e) => await handleDeleteLine(e, doc.CRD_ID)}>
                                            <FontAwesomeIcon icon={faMinusCircle} />
                                        </button>
                                    :null
                                }
                            </div>
                        </Line>
                    )
                })
            }

            <div className="container-plus">
                <button className="plus" onClick={(e) => {e.preventDefault(); setDocs([...docs, {}])}}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                </button>
                <button disabled={disabledSave} className={disabledSave ? 'disable' : ''} onClick={(e) => handleSubmit(e)}>
                    <FontAwesomeIcon icon={faCheck} /> Salvar
                </button>
            </div>
        </Container>
    );
}
