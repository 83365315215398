import Models from '../../../models';
import swal from '@sweetalert/with-react';

export async function handleSubmit(e, props, data) {
        e.preventDefault();
        var { model, id, returnFunction } = props;
        id = id ? id : 0;
        var res = {};

        switch(id){
            case 0: {
                res = await Models[model]['STORE'](data);
                break;
            }
            default: res = await Models[model]['UPDATE'](id, data);
        }
        if(res.code){
            switch(res.errno){
                case 1062: {
                    swal('Erro de duplicidade!', res.sqlMessage, 'error');
                    break;
                }
                case 1406: {
                    swal('Valor digitado maior do que o esperado!', res.sqlMessage, 'error');
                    break;
                }
                default: console.log(res); swal('Ops! Houve um erro na transição', res.sqlMessage, 'error');
            }
        }
        if(res.affectedRows){
            switch(res.affectedRows > 0){
                case true: {
                    swal('Feito!', `${id === 0 ? 'cadastro efetuado' : 'alteração efetuada'} com sucesso!`, 'success', { buttons: false, timer: 3000 });
                    returnFunction();
                    break;
                }
                default: swal('Ops!', 'ocorreu um erro ao processar o cadastro! tente novamente mais tarde!', 'error');
            }
        }
        return null
}
