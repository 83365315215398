/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSession, useUtils } from "../../utils";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPollH,
  faBars,
  faUserCircle,
  faIndustry,
  faIdBadge,
  faTrophy,
  faSignOutAlt,
  faCertificate,
  faSignature,
  faUser,
  faTachometerAlt,
  faQuestionCircle,
  faShoePrints,
} from "@fortawesome/free-solid-svg-icons";

import logo from "../../assets/img/logo.png";

import { Container } from "./styles";
import { resizableGrid } from "../../utils/resizeTable";

const MenuComponent = (props) => {
  const { replace } = useHistory();
  const { destroy } = useSession();
  const userLogged = useSession((value) =>
    value.user ? value.user[0] : value
  );
  const { getAuthenticate } = useUtils();

  useEffect(() => {
    getAuthenticate();
    const tables = document.getElementsByTagName("table");
    for (let i = 0; i < tables.length; i++) {
      resizableGrid(tables[i]);
    }
  }, []);

  function handleChangeClass() {
    var list = document.getElementById("toggleList");
    list.classList.contains("visible")
      ? list.classList.remove("visible")
      : list.classList.add("visible");
  }

  return (
    <Container theme={props.theme}>
      <div className="top">
        <span
          title="Dashboard"
          onClick={() => {
            handleChangeClass();
            replace("/dashboard");
          }}
        >
          <img src={logo} alt="" />
        </span>
        <span className="usuario">
          Logado como <br />
          <b>{userLogged.USU_NAME}</b>
        </span>
        <div className="toggleMenu nanoDisplay">
          <div className="title" onClick={() => handleChangeClass()}>
            <FontAwesomeIcon className="menuIcon" icon={faBars} />
          </div>
          <ul id="toggleList" className="list">
            <li
              onClick={() => {
                handleChangeClass();
                replace("/dashboard");
              }}
            >
              <FontAwesomeIcon className="menuIcon" icon={faTachometerAlt} />
              <span>Dashboard</span>
            </li>
            <li
              onClick={() => {
                handleChangeClass();
                replace("/certificados");
              }}
            >
              <FontAwesomeIcon className="menuIcon" icon={faCertificate} />
              <span>Certificados</span>
            </li>
            <li
              onClick={() => {
                handleChangeClass();
                replace("/modelos");
              }}
            >
              <FontAwesomeIcon className="menuIcon" icon={faTrophy} />
              <span>Modelos</span>
            </li>
            <li
              onClick={() => {
                handleChangeClass();
                replace("/clientes");
              }}
            >
              <FontAwesomeIcon className="menuIcon" icon={faUserCircle} />
              <span>Clientes</span>
            </li>
            <li
              onClick={() => {
                handleChangeClass();
                replace("/fabricantes");
              }}
            >
              <FontAwesomeIcon className="menuIcon" icon={faIndustry} />
              <span>Fabricantes</span>
            </li>
            <li
              onClick={() => {
                handleChangeClass();
                replace("/servicos");
              }}
            >
              <FontAwesomeIcon className="menuIcon" icon={faPollH} />
              <span>Serviços</span>
            </li>
            <li
              onClick={() => {
                handleChangeClass();
                replace("/cargos");
              }}
            >
              <FontAwesomeIcon className="menuIcon" icon={faIdBadge} />
              <span>Cargos</span>
            </li>
            <li
              onClick={() => {
                handleChangeClass();
                replace("/assinantes");
              }}
            >
              <FontAwesomeIcon className="menuIcon" icon={faSignature} />
              <span>Assinantes</span>
            </li>
            <li
              onClick={() => {
                handleChangeClass();
                replace("/usuarios");
              }}
              style={{
                display: userLogged.USU_FK_COA_ID === 1 ? "flex" : "none",
              }}
            >
              <FontAwesomeIcon className="menuIcon" icon={faUser} />
              <span>Usuários</span>
            </li>
            <li
              onClick={() => {
                handleChangeClass();
                replace("/faq");
              }}
            >
              <FontAwesomeIcon className="menuIcon" icon={faQuestionCircle} />
              <span>FAQ</span>
            </li>
            <li
              onClick={() => {
                handleChangeClass();
                replace("/etapas");
              }}
            >
              <FontAwesomeIcon className="menuIcon" icon={faShoePrints} />
              <span>Etapas</span>
            </li>
            <li
              onClick={() => {
                localStorage.removeItem("token");
                destroy();
                replace("/");
              }}
            >
              <FontAwesomeIcon className="menuIcon" icon={faSignOutAlt} />
              <span>Sair</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="title">
        <FontAwesomeIcon className="menuIcon" icon={faBars} />
      </div>
      <ul className="list">
        <li
          onClick={() => {
            replace("/dashboard");
          }}
        >
          <FontAwesomeIcon className="menuIcon" icon={faTachometerAlt} />
          <span>Dashboard</span>
        </li>
        <li
          onClick={() => {
            replace("/certificados");
          }}
        >
          <FontAwesomeIcon className="menuIcon" icon={faCertificate} />
          <span>Certificados</span>
        </li>
        <li
          onClick={() => {
            replace("/modelos");
          }}
        >
          <FontAwesomeIcon className="menuIcon" icon={faTrophy} />
          <span>Modelos</span>
        </li>
        <li
          onClick={() => {
            replace("/clientes");
          }}
        >
          <FontAwesomeIcon className="menuIcon" icon={faUserCircle} />
          <span>Clientes</span>
        </li>
        <li
          onClick={() => {
            replace("/fabricantes");
          }}
        >
          <FontAwesomeIcon className="menuIcon" icon={faIndustry} />
          <span>Fabricantes</span>
        </li>
        <li
          onClick={() => {
            replace("/servicos");
          }}
        >
          <FontAwesomeIcon className="menuIcon" icon={faPollH} />
          <span>Serviços</span>
        </li>
        <li
          onClick={() => {
            replace("/cargos");
          }}
        >
          <FontAwesomeIcon className="menuIcon" icon={faIdBadge} />
          <span>Cargos</span>
        </li>
        <li
          onClick={() => {
            replace("/assinantes");
          }}
        >
          <FontAwesomeIcon className="menuIcon" icon={faSignature} />
          <span>Assinantes</span>
        </li>
        <li
          onClick={() => {
            replace("/usuarios");
          }}
          style={{ display: userLogged.USU_FK_COA_ID === 1 ? "flex" : "none" }}
        >
          <FontAwesomeIcon className="menuIcon" icon={faUser} />
          <span>Usuários</span>
        </li>
        <li
          onClick={() => {
            replace("/etapas");
          }}
        >
          <FontAwesomeIcon className="menuIcon" icon={faShoePrints} />
          <span>Etapas</span>
        </li>
        <li
          onClick={() => {
            replace("/faq");
          }}
        >
          <FontAwesomeIcon className="menuIcon" icon={faQuestionCircle} />
          <span>FAQ</span>
        </li>
        <li
          onClick={() => {
            localStorage.removeItem("token");
            destroy();
            replace("/");
          }}
        >
          <FontAwesomeIcon className="menuIcon" icon={faSignOutAlt} />
          <span>Sair</span>
        </li>
      </ul>
    </Container>
  );
};

export default MenuComponent;
