import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import { GlobalStyle } from './assets/styles/global';

ReactDOM.render(
    <>
        <GlobalStyle />
        <Routes />
    </>
, document.getElementById('root'));
