/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash, faArrowCircleLeft, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Content } from './styles';
import * as ModelDocumentos from '../../../../models/documentosModel';
import AreaComponent from '../../../../components/AreaComponent';
import baseURL from '../../baseURL';
import TopMenu from '../../components/TopMenu';
import { verifyString } from '../../../../utils';
import { format, parseISO } from 'date-fns';
import swal from '@sweetalert/with-react';

export default function ArquivosAreaCliente() {
    const [documentos, setDocumentos] = useState([]);
    const { push } = useHistory();
    
    const params = useParams();

    const dados = {
        title: "Área do Cliente - Dashboard"
    };
    useEffect(() => {
        document.title = `${dados.title}`;
    }, [dados.title]);
    useEffect(() => {
        handleLoadDocuments(params.id)
    }, [])
  

    function handleOpenDocument(url) {
        if(url.includes('http')){
            window.open(url)
        }else {
            window.open(`https://${url}`)
        }
    }

    async function handleDelete(id, cerId){
        swal({
            title: "Você tem certeza?",
            text: "Uma vez excluído, você não poderá recuperar esse registro!",
            icon: "warning",
            dangerMode: true,
            buttons: ['Não', 'Sim'],
          })
        .then(async (willDelete) => {
            if (willDelete) {
                const res = await ModelDocumentos['DELETE'](id);
                if (res.affectedRows > 0) { swal("Sucesso!", "o registro foi removido do banco de dados", 'success', { buttons: false, timer: 2000 }) } else { if (res.errno === 1451) { swal("Não foi possivel!", "Não foi possivel excluir pois o mesmo possui vinculos ao sistema!", 'error') } else { swal("Ops!", "ocorreu um erro ao remover! tente novamente mais tarde!", 'error') } }
                handleLoadDocuments(cerId)
            }
        });
    }

    async function handleLoadDocuments(id) {        
        setDocumentos(await ModelDocumentos['SHOW'](id))
    }

    return (
        <Container>
            <TopMenu processo={params.numCert} />
            
            <Content>
            <AreaComponent className="documentos anexos" checked={true} theme="warning" title="Documentos Anexados">
                        <div className="table">
                            <table className="warning" id="table">
                                <thead>
                                    <tr>
                                        <th title="Nome">Nome</th>
                                        <th width={200} title="Data do envio">Data do envio</th>
                                        <th title="Enviado por">Enviado por</th>
                                        <th width={100} title="Ação">Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        documentos
                                        .sort((a, b) => a.CED_DATA < b.CED_DATA ? 1 : -1)
                                        .map((line, key) => {
                                            return (
                                                <tr key={key} >
                                                    <td title={verifyString(line.CED_NOME)}>{verifyString(line.CED_NOME)}</td>
                                                    <td>{format(parseISO(line.CED_DATA), " dd/MM/yyyy 'às' HH:mm ")}</td>
                                                    <td title={verifyString(line.CED_POR === 'undefined' ? '' : line.CED_POR)}>{verifyString(line.CED_POR === 'undefined' ? '' : line.CED_POR)}</td>
                                                    <td className="two-buttons">
                                                        <button className="see" title="Ver Documento" onClick={() => handleOpenDocument(line.CED_CAMINHO)}>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </button>
                                                        <button className="canceled" title="Excluir Documento" onClick={() => handleDelete(line.CED_ID, line.CED_FK_CER_ID)}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </AreaComponent>
            <div className="rodape">
            <button className="back" title="Voltar" onClick={() => push(`${baseURL}/dashboard/`)}>
                <FontAwesomeIcon icon={faArrowCircleLeft}/> Voltar
            </button> 
            <button className="upload" title="upload" onClick={() => push(`${baseURL}/upload/${params.id}`)}>
                <FontAwesomeIcon icon={faUpload}/> Carregar Documentos
            </button>                          
            </div>
            </Content>
        </Container>
    );
}
