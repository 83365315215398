import styled from 'styled-components';

export const Container = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    text-align: center;
    border-radius: 10px;
    padding: 0;
    color: #555;
    span {
        font-size: 20px;
        font-weight: 600;
        width: 100%;
        margin-bottom: 10px;
    }
`;
