/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFolderOpen, faFileAlt, faTasks } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Container, Content, Table, Division } from './styles';
import * as Model from '../../../../models/portalModel';
import { useSession } from '../../../../utils';
import AreaComponent from '../../../../components/AreaComponent';
import baseURL from '../../baseURL';
import TopMenu from '../../components/TopMenu';
import { CardsArea, Card } from '../../../../components/CardsComponent';
import Status from '../../../../data/status';
import { verifyString, handleSort, sortFunctions } from '../../../../utils';
import Cookie from 'js-cookie';

export default function DashboardAreaCliente() {
    const [lines, setLines] = useState([]);
    const [cards, setCards] = useState([]);
    const [checked, setChecked] = useState(false);
    const [docsChecked, setDocsChecked] = useState(true);
    const [title, setTitle] = useState("");
    const [theme, setTheme] = useState("");
    const [method, setMethod] = useState("");
    const { push } = useHistory();
    const [sort, setSort] = useState({ currentSort: 'up', column: '' });
    const sortColumns = column => handleSort(column, sort, setSort);

    const userLogged = useSession(value => value, 'client-session');

    const dados = {
        title: "Área do Cliente - Dashboard"
    };

    useEffect(() => {
        document.title = `${dados.title}`;
    }, [dados.title]);

    useEffect(() => {
        init();
        const cookieCheck = Cookie.get('card');
        if (cookieCheck){
            handleChecked(cookieCheck)
        }
    }, [])

    useEffect(() => {
        Cookie.set('card', method)
    }, [method])

    async function init() {
        const resCards = await Model['CARDS'](userLogged.who, userLogged.id);
        setCards([
            {
                number: resCards.emProcesso ? resCards.emProcesso : 0,
                ...Status[0]
            },
            {
                number: resCards.ativo ? resCards.ativo : 0,
                ...Status[1]
            },
            {
                number: resCards.suspenso ? resCards.suspenso : 0,
                ...Status[2]
            },
            {
                number: resCards.cancelado ? resCards.cancelado : 0,
                ...Status[3]
            }
        ]);
    }

    async function handleChecked(method) {
        setMethod(method);
        let docCheck = method === 'Em Processo';
        setDocsChecked(docCheck);
        const status = Status.filter(status => status.label === method)[0]
        const id = Status.indexOf(status);
        setLines(await Model['CERTIFICATE'](id, userLogged.who, userLogged.id))
        setChecked(true)
        setTitle(status.label)
        setTheme(status.theme)
    }

    return (
        <Container>
            <TopMenu />
            <CardsArea>
                {
                    cards.map((card, key) => {
                        return <Card key={key} onClick={() => handleChecked(card.label)} {...card} />
                    })
                }
            </CardsArea>

            <Content checked={checked}>
                <Division checked={docsChecked}>
                    <AreaComponent className="area" title={title} theme={theme}>
                        <Table theme={theme}>
                            <thead>
                                <tr>
                                    <th className="resize" onClick={() => sortColumns('CER_NUMERO_PROJETO')} title="Número">Número</th>
                                    <th className="resize" onClick={() => sortColumns('CER_PRODUTO')} title="Produto">Produto</th>
                                    <th className="resize" onClick={() => sortColumns('CER_MODELO')} title="Modelo">Modelo</th>
                                    <th className="resize" title="Ação">Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    lines                                    
                                    .sort(sortFunctions[sort.currentSort](sort.column))
                                    .map((line, key) => {
                                        return (
                                            <tr key={key}>
                                                <td className="click" title={verifyString(line.CER_NUMERO_PROJETO)}>{verifyString(line.CER_NUMERO_PROJETO)}</td>
                                                <td title={verifyString(line.CER_PRODUTO)}>{verifyString(line.CER_PRODUTO)}</td>
                                                <td title={verifyString(line.CER_MODELO)}>{verifyString(line.CER_MODELO)}</td>
                                                <td >
                                                    {
                                                        method === 'Em Processo' ?
                                                            <button className="upload" title="Documentos Anexados" onClick={() => push(`${baseURL}/documentos/${line.CER_ID}/${line.CER_NUMERO_PROJETO}`)}>
                                                                <FontAwesomeIcon icon={faFolderOpen} />
                                                            </button>
                                                            :
                                                            <button className="see" title="Ver Certificado" onClick={() => window.open(`${baseURL}/print/${userLogged.who}/${line.CER_ID}`)}>
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </button>
                                                    }
                                                    <button className="andamento" title="Ver Andamento" onClick={() => push(`${baseURL}/andamento/${line.CER_ID}/${line.CER_NUMERO_PROJETO}`)}>
                                                        <FontAwesomeIcon icon={faTasks} />
                                                    </button>
                                                    <button className="arquivos" title="Ver Arquivos QCCERT" onClick={() => push(`${baseURL}/arquivos/${line.CER_ID}/${line.CER_NUMERO_PROJETO}`)}>
                                                        <FontAwesomeIcon icon={faFileAlt} />
                                                    </button>
                                                    {line.notification !== 0 && <span className="notification">{line.notification}</span>}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </AreaComponent>
                </Division>
            </Content>

        </Container>
    );
}
