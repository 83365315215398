import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Container } from './styles';

const Loading = (props) => {
    return (
        <Container {...props}>
            <FontAwesomeIcon icon={faSpinner}/>
            <span>Carregando...</span>
        </Container>
    )
};

export default Loading;
