/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCheck, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import * as Model from '../../../../../../models/etapasModel';
import { verifyTransition } from '../../../../../../utils';
import { Container, Line } from './styles';

export default function LineCRUD({ id }) {
    const [ docs, setDocs ] = useState([]);
    const [ disabledSave, setDisabledSave ] = useState(true);

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        docs.map(doc => {
            if(!doc.ETA_ID && docs.length > 1){
                setDisabledSave(false);
            }
            return null;
        })
    }, [docs])

    async function init(){
        const response = await Model['SHOW'](id);
        setDocs(response.length <= 0 ? [{}] : response);
    }

    function handleChangeValues(name, value, id){
        setDisabledSave(false);
        var temp = docs;
        if(!temp[id]){
            docs[id] = { [name]: value };
            setDocs(docs);
            return
        }
        setDocs(temp.map((valueTemp, key) => {
            if(key === id){
                return { ...temp[id], [name]: value }
            }
            return valueTemp
        }))
    }

    async function handleSubmit(e) {
        setDisabledSave(true);
        e.preventDefault();
        const response = await docs.map(async doc => {
            if(!doc) {
                return
            }
            if(!doc.ETA_ID){
                doc = {ETA_FK_SER_ID: id, ...doc}
                return await Model['STORE'](doc);
            }
            let idChange = doc.ETA_ID;
            if(Object.values(doc).filter(doc => !["", null, undefined].includes(doc)).length <= 2) {
                return await Model['DELETE'](idChange);
            }
            return await Model['UPDATE'](idChange, doc);
        })
        if(await response[0]){ verifyTransition(await response[0]) };
        init();
    }

    async function handleDeleteLine(e, id, position){
        e.preventDefault();
        if(!id){
            return setDocs(docs.filter((_value, key) => key !== position))
        }
        await Model['DELETE'](id)
        init();
    }

    return (
        <Container>
            {
                docs.map((doc, key) => {
                    return (
                        <Line key={key}>
                            <div className="form-line">
                                <span>{key+1}ª Etapa</span>
                                <input value={doc ? doc.ETA_NOME : null}  onChange={({target}) => handleChangeValues('ETA_NOME', target.value, key)}/>
                            </div>

                            <div className="containerCloneLine">
                                {
                                    key !== 0 ?
                                        <button className="deleteLine" onClick={async (e) => await handleDeleteLine(e, doc.ETA_ID, key)}>
                                            <FontAwesomeIcon icon={faMinusCircle} />
                                        </button>
                                    :null
                                }
                            </div>
                        </Line>
                    )
                })
            }

            <div className="container-plus">
                <button className="plus" onClick={(e) => {e.preventDefault(); setDocs([...docs, {}])}}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                </button>
                <button disabled={disabledSave} className={disabledSave ? 'disable' : ''} onClick={(e) => handleSubmit(e)}>
                    <FontAwesomeIcon icon={faCheck} /> Salvar
                </button>
            </div>
        </Container>
    );
}
