import { Session } from 'bc-react-session';
import swal from '@sweetalert/with-react';
import { useHistory } from 'react-router-dom';
import routeDefault from "../services/route_default";

export function useUtils() {
    const userLogged = useSession(state => state.user)
    const { replace } = useHistory();

    const setDocTitle = async (route) => {
        document.title = `${route} - QCDOCS`;
    }
    const getAuthenticate = (back = routeDefault, sessionName='simple-session') => {
        //Verificador de autenticidade da sessão
        const { isValid, payload } = Session.getSession(sessionName);
        if (!isValid) {
            Session.destroy();
            localStorage.removeItem('token');
            swal("Sessão expirada", "Faça o login para iniciar uma nova sessão", "error");
            window.location.href = back;
        }
        return payload
    }
    const verifyPermission = () => {
        if(userLogged.USU_ADM !== '1' ){ replace('/dashboard'); swal("Usuário sem permissão!", "O seu usuario não tem permissão de acesso a essa area do sistema", "warning") }
    }

    return {
        setDocTitle,
        getAuthenticate,
        verifyPermission
    }
}

export function verifyString(string) {
    return string ? string : "-";
}

export function verifyTableString(string) {
    return string ? string+', ' : "";
}


export function captalizeString(string) {
    if(!string){
        return
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function verifyTransition(res) {
    switch(true){
        case res.affectedRows > 0 : {
            swal('Sucesso!', "Trasação efetuada com sucesso!", 'success', { timer: 3000, buttons: false });
            return true;
        }
        default: {
            switch(res.errno){
                case 1062: {
                    swal('Já existe um processo cadastrado com o mesmo número!', "", 'error'); console.log(res);
                    break;
                }
                default: swal('Erro!', "Ocorreu um erro na transação, tente novamente mais tarde!", 'error'); console.log(res);
            }
            return false;
        }
    }
}

export function calculateYears(months = 0){
    if(!parseInt(months) && typeof months !== 'number'){
        return 'Parametro não numérico passado!';
    }

    const rest = months % 12;
    const years = (months - rest) / 12;

    var describe = years > 0 ? years > 1 ? `${years} anos` : `${years} ano` : "";

    describe = years > 0 && rest > 0 ? `${describe} e` : describe;
    describe = `${describe} ${rest > 0 ? rest > 1 ? `${rest} meses` : `${rest} mês`  : ""}`;

    return describe === "" ? " - " : describe;
}

export function useSession(reducer, session='simple-session') {
    if(reducer){
        return reducer(Session.get(session).payload)
    }else{
        return Session
    }
}

export function debounce(fn, wait = 500, time) {
    return function(){
      clearTimeout(time);

      time = setTimeout(() => {
        return fn.apply(this, arguments);
      }, wait);
    };
}

export const sortFunctions = {
	up: key => (a, b) => a[key] > b[key] ? 1 : -1,
	down: key => (a, b) => a[key] < b[key] ? 1 : -1
};

export function handleSort(column, sort, setSort) {
    if(!setSort) return
    const sorts = ['down', 'up'];
    const indexSort = sorts.indexOf(sort ? sort.currentSort : 'up');
    const nextSort = indexSort === sorts.length-1 ? sorts[0] : sorts[indexSort+1];
    
    setSort({ currentSort: nextSort, column })
}