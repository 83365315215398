/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faDownload, faUpload, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import MenuComponent from '../../../../components/MenuComponent/';
import ContentComponent from '../../../../components/ContentComponent/';
import AreaComponent from '../../../../components/AreaComponent/';
import Model from '../../../../models';
import { format, parseISO } from 'date-fns';
import { verifyString } from '../../../../utils';
import logo from '../../../../assets/img/logo.png';
import multiDownload from 'multi-download'
import { useHistory } from 'react-router-dom';
import baseURL from '../../baseURL';

import { Container } from './styles';
import api from '../../../../services/api';

export default function DocumentosAnexados(props) {
    const { id, who, idCert, status } = props.match.params
    const modelWho = who === 'fabricantes' ? Model.fabricantesModel : Model.clientesModel;
    const [ documentos, setDocumentos ] = useState([]);
    const [ dados, setDados ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const { push, goBack } = useHistory();

    useEffect(() => { init() }, []);
    useEffect(() => { document.title = `${dados.title} - QCDOCS`; }, [dados.title]);

    async function init(){
        const dados = {
            title: "",
            subtitle: "Perfil",
            bread: ['Perfil', who === 'fabricantes' ? 'Fabricantes' : 'Clientes' ]
        };
        const requerent = await modelWho['SHOW'](id);
        switch(who){
            case 'fabricantes':{
                dados.title = requerent[0].FAB_RAZAO_SOCIAL;
                dados.bread.push(requerent[0].FAB_RAZAO_SOCIAL);
                setDados(dados);
                break;
            }
            case 'clientes': {
                dados.title = requerent[0].CLI_RAZAO_SOCIAL;
                dados.bread.push(requerent[0].CLI_RAZAO_SOCIAL);
                setDados(dados);
                break;
            }
        }

        setDocumentos(await Model.documentosModel['SHOW'](idCert));
    }

    async function handleOpenDocument(url, id) {
        setLoading(true);
        if(url.includes('http')){
            window.open(url)
        }else {
            window.open(`https://${url}`)
        }
        await api.put(`/certificados_documentos/${id}`, {
            CED_SHOW: 1
        }).then(() => {
            init();
            setDocumentos(documentos.map((line) => {
                    if (line.CED_ID === id){
                        return {...line, CED_SHOW : 1} 
                    }
                    return line
                }
            ))
        });
        setLoading(false);
    }

    function handleDownloadMulti() {
        documentos.filter(file => file.CED_CAMINHO.endsWith('.pdf')).map(file => window.open(file.CED_CAMINHO))
        multiDownload(documentos.filter(file => !file.CED_CAMINHO.endsWith('.pdf')).map(file => `https://${file.CED_CAMINHO}`))
        setDocumentos(documentos.map(documento => {
            if(documento.CED_SHOW === 0){
                const statusData = { CED_SHOW: 1 };
                api.put(`/certificados_documentos/${documento.CED_ID}`, statusData)
                return { ...documento, ...statusData }
            }
            return documento
        }))
        init();
      }

    return(
    <Container>
        <div className={`load ${loading ? "inProcess" : ""}`}>
            <img src={logo} alt="QCCERT" />
            <span>Carregando... </span>
        </div>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <div className="container-areas">
                <AreaComponent theme="warning" title="Documentos">
                    <div className="container-button">
                        {
                            documentos[0] &&
                            <button className="btn btn-success" onClick={() => handleDownloadMulti()}> 
                                <FontAwesomeIcon icon={faDownload} />{' '}
                                Baixar todos
                            </button>
                        }

                        <button disabled={[3].includes(status)} className={![3].includes(status) ? "btn upload" : "btn disabled"} title="Enviar Documentos para o cliente" onClick={() => push(`${baseURL}/upload/${who}/${idCert}/${id}`)}>
                            <FontAwesomeIcon icon={faUpload} />{' '}
                            Enviar Documentos
                        </button>
                    </div>
                    <div className="table">
                        <table className="warning">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Data do envio</th>
                                    <th>Enviado por</th>
                                    <th>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    documentos
                                    .sort((a, b) => a.CED_DATA < b.CED_DATA ? 1 : -1)
                                    .map((line, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    {line.CED_SHOW !== 1 && <span className="notification">novo</span>}
                                                    {verifyString(line.CED_NOME)}
                                                </td>
                                                <td>{format(parseISO(line.CED_DATA), " dd/MM/yyyy 'às' HH:mm ")}</td>
                                                <td>{verifyString(line.CED_POR)}</td>
                                                <td>
                                                    <button className="see" title="Ver Documento" onClick={() => handleOpenDocument(line.CED_CAMINHO, line.CED_ID)}>
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </AreaComponent>
                <div className="rodape">
                    <button className="back" title="Voltar" onClick={() => goBack()}>
                        <FontAwesomeIcon icon={faArrowCircleLeft}/> Voltar
                    </button>                        
                </div>
            </div>
        </ContentComponent>
    </Container>
    );
}
