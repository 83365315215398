import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import MenuComponent from '../../components/MenuComponent/';
import ContentComponent from '../../components/ContentComponent/';
import AreaComponent from '../../components/AreaComponent/';
import { Container } from './styles';
import * as Model from '../../models/servicosModel';
import baseURL from './baseURL';
import { debounce, handleSort, sortFunctions } from "../../utils";

export default function Etapas() {
    const [ lines, setLines ] = useState([]);
    const filtro = useRef();
    const [sort, setSort] = useState({ currentSort: 'up', column: '' });
    const sortColumns = column => handleSort(column, sort, setSort);
    const { push } = useHistory();

    const dados = {
        title: "Etapas",
        subtitle: "Lista de etapas principais por escopo",
        bread: ['Etapas']
    };

    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`;
        init();
    }, [dados.title]);

    async function handleSearch(){
        if(filtro.current.value === ""){ init(); return; }
        setLines(await Model['SEARCH'](filtro.current.value))
    }

    async function init(){
        setLines(await Model['INDEX']())
    }

    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <div className="containerButtonNew">
                <input type="text" placeholder="Filtrar..." ref={filtro} onChange={debounce(handleSearch)}/>
            </div>
            <AreaComponent>
                <table>
                    <thead>
                        <tr>
                            <th onClick={() => sortColumns('SER_NOME')}>Escopo</th>
                            <th width={90}>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lines
                            .sort(sortFunctions[sort.currentSort](sort.column))
                            .filter(line => line.SER_STATUS).map((line, key) => {
                                return (
                                    <tr key={key}>
                                        <td title={line.SER_NOME}>{line.SER_NOME}</td>
                                        <td className="buttons">
                                            <button className="edit" title="Editar Etapas" onClick={() => push(`${baseURL}/editar/${line.SER_ID}`)}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </AreaComponent>
        </ContentComponent>
    </Container>
    );
}
