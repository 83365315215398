/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import swal from "@sweetalert/with-react";
import { useHistory } from "react-router-dom";
import { Container } from "./styles";
import * as Model from "../../models/usuariosModel";
import logo from "../../assets/img/logo2.png";
import { useSession } from "../../utils";

export default function Login() {
  const { start, login, onExpiration } = useSession();
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const { replace, push } = useHistory();

  const dados = {
    title: "Acesso",
  };

  useEffect(() => {
    document.title = `${dados.title} - QCDOCS`;
    if (localStorage.getItem("token")) {
      replace("/dashboard");
    }
  }, []);

  async function handleAuthenticate(e) {
    if (e) {
      e.preventDefault();
    }
    const res = await Model["AUTHENTICATE"](user, pass);
    if (!res.authenticate) {
      return swal(
        "Opa!",
        "Algo deu errado! verifique suas credenciais para continuar",
        "warning"
      );
    }

    localStorage.setItem("token", res.token);

    function continuar(res) {
      login();
      onExpiration((session) => {
        localStorage.removeItem("token");
        session.destroy();
      });
      start("simple-session", {
        payload: {
          user: res.data,
        },
      });
      replace("/dashboard");
    }

    if (!res.data[0].USU_PRIMEIRO_ACESSO) {
      return swal({
        title: "Bem Vindo!",
        text: "Identificamos que é seu primeiro acesso. Gostaria de alterar a senha padrão?",
        icon: "info",
        buttons: ["Não", "Sim"],
        closeOnClickOutside: false,
      }).then((mudaSenha) => {
        if (!mudaSenha) {
          Model.UPDATE(res.data[0].USU_ID, {
            USU_PRIMEIRO_ACESSO: 1,
          });
          return continuar(res);
        }

        swal({
          text: "Insira a nova senha:",
          content: {
            element: "input",
            attributes: {
              placeholder: "Senha",
              type: "password",
            },
          },
          button: {
            text: "Enviar",
            closeModal: false,
          },
        }).then(async (password) => {
          if (!password) {
            return swal("A senha não pode ficar em branco!", " ", "warning", {
              timer: 3000,
              buttons: false,
            });
          }

          Model.UPDATE(res.data[0].USU_ID, {
            USU_PRIMEIRO_ACESSO: 1,
          });
          Model.UPDATE(res.data[0].USU_ID, {
            USU_PASS: password,
          });

          swal.close();

          continuar(res);
        });
      });
    }

    continuar(res);
  }

  return (
    <Container>
      <div className="background" />
      <form className="login" onSubmit={(e) => handleAuthenticate(e)}>
        <img src={logo} alt="Logo" />
        <input
          type="text"
          placeholder="Usuario"
          value={user}
          onChange={({ target }) => setUser(target.value)}
          className="user"
        />
        <input
          type="password"
          placeholder="Senha"
          value={pass}
          onChange={({ target }) => setPass(target.value)}
          className="pass"
        />
        <input
          className="submit"
          type="submit"
          value="Entrar"
          onClick={() => handleAuthenticate()}
        />
      </form>
      <span
        onClick={() => push("/consultar/certificado")}
        className="seeCertificate"
      >
        Consultar um certificado
      </span>
    </Container>
  );
}
