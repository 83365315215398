import styled from 'styled-components';

export const Container = styled.div.attrs(props => ({
    onClick: props.onClick
}))`
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    ${props => props};
    div.number {
        display: flex;
        width: 100%;
        justify-content: end;
        span {
            font-size: 40px;
        }
    }
    div.span {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        span {
            font-size: 20px;
        }
    }
    @media only screen and (max-width: 360px) {
        padding: 10px;
        div.number {
            span {
                font-size: 30px;
            }
        }
        div.span {
            span {
                font-size: 15px;
            }
        }
    }
    @media only screen and (min-width: 360px) and (max-width: 768px) {
        padding: 15px;
        div.number {
            span {
                font-size: 30px;
            }
        }
        div.span {
            span {
                font-size: 12px;
            }
        }
    }
`;
