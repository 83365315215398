/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Content } from './styles';
import * as ModelAndamento from '../../../../models/andamentoModel';
import AreaComponent from '../../../../components/AreaComponent';
import baseURL from '../../baseURL';
import TopMenu from '../../components/TopMenu';
import { verifyString } from '../../../../utils';


export default function ArquivosAreaCliente() {
    const [documentosQCCERT, setDocumentosQCCERT] = useState([]);
    const { push } = useHistory();
    
    const params = useParams();

    const dados = {
        title: "Área do Cliente - Dashboard"
    };
    useEffect(() => {
        document.title = `${dados.title}`;
    }, [dados.title]);
    useEffect(() => {
        handleLoadDocuments(params.id)
    }, [])



    async function handleLoadDocuments(id) {        
        setDocumentosQCCERT(await ModelAndamento['SHOW'](id))
    }

    return (
        <Container>
            <TopMenu processo={params.numCert} />
            
            <Content>
                    <AreaComponent className="anexos" theme="def" title="Andamento do processo">
                        <div className="table">
                            <table className="def">
                                <thead>
                                    <tr>
                                        <th width={200} title="Nome">Etapa</th>
                                        <th title="Data do envio">Status</th>
                                        <th title="Enviado por">Observações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        documentosQCCERT
                                        .sort(function(a, b) {
                                            return (a.AND_FK_ETA_ID===null)-(b.AND_FK_ETA_ID===null) || +(a.AND_FK_ETA_ID>b.AND_FK_ETA_ID)||-(a.AND_FK_ETA_ID<b.AND_FK_ETA_ID);
                                        })
                                        .map((line, key) => {
                                            return (
                                                <tr key={key} >
                                                    <td title={verifyString(line.AND_ETAPA)}>                                                        
                                                        {verifyString(line.AND_ETAPA)}
                                                    </td>
                                                    <td>{line.AND_STATUS === 2 ? 'Concluído' : line.AND_STATUS === 1 ? 'Em andamento' : 'Pendente'}</td>
                                                    <td title={verifyString(line.AND_OBSERVACOES)}>
                                                        {verifyString(line.AND_OBSERVACOES)}
                                                    </td>                                                    
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </AreaComponent>
            <div className="voltar">
                <button className="back" title="Voltar" onClick={() => push(`${baseURL}/dashboard/`)}>
                    <FontAwesomeIcon icon={faArrowCircleLeft}/> Voltar
                </button>                         
            </div>
            </Content>
        </Container>
    );
}
