/*
* Styles Component de CadastroServicos
*/
import styled, { keyframes } from 'styled-components';
import * as constants from '../../../constants/styles';

const {
    third,
    primary,
    def
} = constants

const rotate = keyframes`
    0% {
    }
    50% {
        color: white;
    }
    100% {
    }
`;

export const Container = styled.div`
    min-height: 100%;
    background: ${third};
    display: flex;
    flex-direction: row;
    div.load {
        position: absolute;
        display: none;
        min-width: 100%;
        min-height: 100%;
        z-index: 9999;
        background: rgba(255, 255, 255, .8);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 100px;
        img {
            background: ${primary};
            padding: 30px;
            border-radius: 20px;
            width: 300px;
        }
        span {
            font-size: 20pt;
            color: ${primary};
            animation: ${rotate} .5s linear infinite;
        }
    }
    div.load.inProcess {
        display: flex;
    }
    div.area-charts {
        width: 100%;
        min-height: 100vh;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        margin-top: 10px;
    }
    div.cards {
        padding: 20px 0 0 0 ;
        margin: 0;
        width: 100%;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(5, 2fr);
    }

    div.relatorio {
        width: 100%;
        border-radius: 5px;
        .input, .list {
            background: #fff;
            border: 1px solid #ccc;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            border-radius: 10px;
        }

        .input {
            background: #fff;
            .title {
                border-bottom: 1px solid #ccc;
                color: ${primary};
                padding: 15px;
                span {
                    font-weight: 600;
                    font-size: 14pt;
                    padding: 10px;
                }
            }
            select {
                border: 1px solid #ccc;
                background: #fff;
                border-radius: 5px;
                margin: 20px;
                font-size: 10pt;
                padding: 5px;
                option:first {
                    color: #ccc;
                }
            }
        }
        .list {
            margin-top: 10px;
            height: 100vh;
            max-height: 250px;
            overflow-y: auto;
            div.button {
                display: flex;
                justify-content: flex-end;
                button {
                    max-width: 150px;
                    padding: 10px;
                    border: 1px solid #ccc;
                    background: ${def};
                    color: white;
                    border-radius: 5px!important;
                    margin: 10px 20px 0 0;
                }
            }
            table {
                /* border-collapse: collapse; */
                margin: 10px;
                thead {
                    background: ${primary};
                    color: white;
                    th {
                        padding: 10px;
                    }
                }
                tbody {
                    td {
                        padding: 10px;
                        text-align: center;
                    }
                    tr:nth-child(even) {background-color: #f2f2f2;}
                    tr.empty {
                        background:#efefef;
                        text-align: center;
                        font-weight: 500;
                        font-style: italic;
                        color: #ababab;
                        padding: 30px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        div.area-charts {
            grid-template-columns: 1fr;
        }
    }
`;


