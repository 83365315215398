import React, { Component } from "react";
import MenuComponent from "../../../../components/MenuComponent";
import ContentComponent from "../../../../components/ContentComponent";
import AreaComponent from "../../../../components/AreaComponent";
import { useSession } from "../../../../utils";
import { uniqueId } from "lodash";
import filesize from "filesize";
import api from "../../../../services/api";
import Upload from "../../../../components/UploadComponent";
import FileList from "../../../../components/FileListComponent";
import baseURL from "../../baseURL";
import * as Model from "../../../../models/modelos_certificadosModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { Container } from "./styles";

export default class CRUDModelos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLogged: useSession((value) => value),
      uploadedFiles: [],
      id: this.props.match.params.id,
      dados: {
        title: this.props.match.params.id
          ? "Editar Modelo"
          : "Cadastro de Modelo",
        subtitle: this.props.match.params.id
          ? "Alterar dados do modelo gerado"
          : "Adicionar um novo modelo",
        bread: [
          "Modelos",
          this.props.match.params.id ? "Editar Modelo" : "Cadastro de Modelo",
        ],
      },
      loading: false,
      urlModel: null,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.state.id) {
      this.init();
    }
  }

  init = async () => {
    const res = await Model["SHOW"](this.state.id);
    if (res[0].MOC_CAMINHO.includes("https://")) {
      this.setState({
        urlModel: res[0].MOC_CAMINHO
          ? `${res[0].MOC_CAMINHO.split(".doc")[0]}.pdf`
          : "",
      });
    } else {
      this.setState({
        urlModel: res[0].MOC_CAMINHO
          ? `https://${res[0].MOC_CAMINHO.split(".doc")[0]}.pdf`
          : "",
      });
    }
  };

  handleUpload = (files) => {
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    this.setState({
      uploadedFiles: uploadedFiles,
    });

    uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  processUpload = async (uploadedFile) => {
    const data = new FormData();
    data.append("file", uploadedFile.file, uploadedFile.name);
    if (this.state.id) {
      await api
        .put(`modelos_certificados/posts/${this.state.id}`, data, {
          onUploadProgress: (e) => {
            const progress = parseInt(Math.round((e.loaded * 100) / e.total));

            this.updateFile(uploadedFile.id, {
              progress,
            });
          },
        })
        .then((response) => {
          this.updateFile(uploadedFile.id, {
            uploaded: true,
            id: response.data.affectedRows,
          });
        })
        .catch(() => {
          this.updateFile(uploadedFile.id, {
            error: true,
          });
        });
      this.init();
      return;
    }
    await api
      .post(`modelos_certificados/posts`, data, {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFile(uploadedFile.id, {
            progress,
          });
        },
      })
      .then((response) => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data.affectedRows,
        });
        this.setState({ id: response.data.insertId });
      })
      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
    this.init();
  };

  handleDelete = async (id) => {
    await api.delete(`modelos_certificados/posts/${id}`);

    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter((file) => file.id !== id),
    });
  };

  render() {
    document.title = `${this.state.dados.title}`;
    console.log(this.state);
    return (
      <Container>
        <MenuComponent {...this.state.dados} />
        <ContentComponent {...this.state.dados}>
          <AreaComponent title="Modelo Carregado" theme="def">
            {this.state.urlModel === null ? (
              <div className="noModel">
                <span>Não há modelo carregado!</span>
              </div>
            ) : (
              <iframe
                src={this.state.urlModel}
                title={this.state.urlModel}
                width="100%"
                height="400"
              />
            )}
          </AreaComponent>
          <AreaComponent {...this.state.dados}>
            <Upload
              onUpload={this.handleUpload}
              label="Arraste o modelo aqui..."
              accept=".odt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
            <div className="button">
              <button onClick={() => this.props.history.replace(baseURL)}>
                <FontAwesomeIcon icon={faCheck} /> Feito
              </button>
            </div>
            {!!this.state.uploadedFiles.length && (
              <FileList
                files={this.state.uploadedFiles}
                onDelete={this.handleDelete}
              />
            )}
          </AreaComponent>
        </ContentComponent>
      </Container>
    );
  }
}
