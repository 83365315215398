/*
* Styles Component de CadastroServicos
*/
import styled, { keyframes } from 'styled-components';
import * as constants from '../../../../../constants/styles';

const {
    third,
    primary,
    secondary,
    white,
    danger,
    warning
} = constants

const rotate = keyframes`
    0% {
    }
    50% {
        color: white;
    }
    100% {
    }
`;

export const Container = styled.div.attrs(props => ({
    theme: props.theme
}))`
    min-height: 100%;
    background: ${third};
    display: flex;
    flex-direction: row;
    div.load {
        position: absolute;
        display: none;
        min-width: 100%;
        min-height: 100%;
        z-index: 9999;
        background: rgba(255, 255, 255, .8);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 100px;
        img {
            background: ${primary};
            padding: 30px;
            border-radius: 20px;
        }
        span {
            font-size: 20pt;
            color: ${primary};
            animation: ${rotate} .5s linear infinite;
        }
    }
    div.load.inProcess {
        display: flex;
    }
    div.content {
        width:100%;
        margin: 0;
        display: flex;
        grid-gap: 30px;
        justify-content: center;
        align-items: center;
        .area {
            width: 90%;
            height: 400px;
            max-height: 400px;
            overflow-y: scroll;
        }

    }
    .rodape{
        width: 100%;
        display: flex;        
    }
    .back{
       background: ${warning}; 
       border: none;
       border-radius: 200px;
       padding: 10px 20px;
       margin:0 10px 0 0;
       color: ${white};
       font-size:12pt;
       box-shadow: 3px 1px 5px #ccc;
    }
    .buttonContainer {
        width: 100%!important;
        display: flex;
        justify-content: end;
        button.submit {
            border: none;
            padding: 15px 20px;
            font-size:14pt;
            background: #00c696;
            color: white;
            border-radius: 10px;
            margin: 10px;
            box-shadow: 1px 2px 3px #ccc;
            &:hover {
                box-shadow: none;
            }
        }
    }
    div.table {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            th {
                background: ${secondary};
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            table {
                width: 100%;
                margin: 0;
                th {
                    padding: 15px;
                    background: ${secondary};
                    color: ${white};
                    text-align: start;
                }
                td {
                    padding: 15px;
                    button {
                        border: none;
                        border-radius: 200px;
                        padding: 5px;
                        margin:0 10px 0 0;
                        color: ${white};
                        width: 20px;
                        font-size:12pt;
                        box-shadow: 3px 1px 5px #ccc;
                    }
                    button.see {
                        background: ${primary};
                        width: 40px;
                    }
                    button.deleted {
                        background: ${danger};
                        width: 40px;
                    }
                }
                tr {
                    background: ${white};
                    &:nth-child(even) {
                        background: ${third};
                    }
                }

            }
        }

    @media only screen and (max-width: 1000px) {
        div.content{
            flex-direction: column;
            grid-gap: 20px;
        }
    }
    @media only screen and (max-width: 360px) {
        div.content {
            font-size: 9pt!important;
            .area {
                width: 80vw;
                max-height: 14em!important;
                overflow-y: scroll;
            }
            button.submit {
                width: 100%;
                font-size: 11pt;
            }
        }
        .dropzone {
            height: 100px!important;
            font-size: 10pt;
        }
    }
`;


