/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/* eslint-disable no-fallthrough */
import React, { useEffect, useState } from 'react';
import MenuComponent from '../../../../components/MenuComponent';
import ContentComponent from '../../../../components/ContentComponent';
import AreaComponent from '../../../../components/AreaComponent';
import Models from '../../../../models'
import { Container } from './styles';
import LineCRUD from './components/Line';


export default function CRUDEtapas(props) {
    const { id } = props.match.params;
    const [ carregando, setCarregando ] = useState(false);
    const [ servico, setServico ] = useState('');
    const dados = {
        title: id ? "Editar Etapa" : "Cadastro de Etapas",
        subtitle: id ? "Alterar dados de Etapa gerada" : "Adicionar nova Etapa",
        bread: ['Etapas', id ? 'Editar Etapa' : 'Cadastro de Etapas' ]
    };

    useEffect(() => { init(); }, [])
    useEffect(() => { document.title = `${dados.title} - QCDOCS`; }, [dados.title])

    async function init(){
        setCarregando(true);
        setServico(await Models['servicosModel']['SHOW'](id).then(response =>  response[0] && response[0].SER_NOME))
        setCarregando(false);
    }


    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <AreaComponent title={servico} theme="primary">
                {
                    !carregando ?
                        <LineCRUD
                            id={id} />
                    :
                    <div className="empty-model">
                        <span>Carregando...</span>
                    </div>
                }
            </AreaComponent>
        </ContentComponent>
    </Container>
    );
}
