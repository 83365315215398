import React, { useState, useEffect } from 'react';
import { verifyTransition, useSession } from '../../../../utils';
import Select from 'react-select';
import Model from '../../../../models';
import swal from '@sweetalert/with-react';

import { Container } from './styles';

function ModalInit(props) {
    const { who, id, init } = props;
    const [ responsaveis, setResponsaveis ] = useState([]);
    const [ responsavel, setResponsavel ] = useState([]);
    const [ number, setNumber ] = useState("");
    const [ numberProject, setNumberProject ] = useState("");
    const [ produto, setProduto ] = useState("");
    const [ modelo, setModelo ] = useState("");
    const userLogged = useSession(value => value.user ? value.user[0] : value);

    useEffect(() => {
        async function init() {
            let dataResponsaveis = await Model.usuariosModel['INDEX']();
            let resResponsaveis = [];
            dataResponsaveis.filter(user => user.USU_FK_RESP_ID === 1).map(user => resResponsaveis = [...resResponsaveis, {label: user.USU_NAME, value: user.USU_ID}])
            setResponsaveis(resResponsaveis)
        }
        init();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!number || [undefined, 0].includes(responsavel.value)) { swal('Espere!', 'Os campos número do certificado e reponsável são obrigatórios!', 'error'); return; };
        var data = { CER_NUMERO: number, CER_NUMERO_PROJETO: numberProject, CER_PRODUTO: produto, CER_MODELO: modelo, CER_FK_USU_ID: ![undefined, 0].includes(responsavel.value) ? responsavel.value : userLogged.USU_ID, CER_STATUS: 4 };
        switch(who) {
            case 'clientes': {
                data = {...data, CER_FK_CLI_ID: id };
                break;
            }
            default: data = {...data, CER_FK_FAB_ID: id };
        }
        const res = await Model.certificadosModel['STORE'](data);
        verifyTransition(res)
        init();
    }
    return (
        <Container>
            <span>Número do certificado</span>
            <input type="text" value={number} onChange={({target}) => setNumber(target.value)}/>
            <span>Produto</span>
            <input type="text" maxLength={255} value={produto} onChange={({target}) => setProduto(target.value)}/>
            <span>Modelo</span>
            <input type="text" maxLength={255} value={modelo} onChange={({target}) => setModelo(target.value)}/>
            <span>Número do projeto</span>
            <input type="text" value={numberProject} onChange={({target}) => setNumberProject(target.value)}/>
            <span>Responsável</span>
            <Select
                className="selectForm"
                value={responsavel}
                onChange={e => setResponsavel(e)}
                placeholder="Selecione"
                options={responsaveis}
                noOptionsMessage={() => 'Sem opções'} />
            <button onClick={(e) => handleSubmit(e)}>
                Iniciar
            </button>
        </Container>
);
}

export default ModalInit;
