import jsPDF from 'jspdf';

export default function printData({ html, notPDF }) {
  if(notPDF){
    let folha = document.getElementById('printSessionCloud').contentWindow;
    folha.document.open();
    folha.document.write(html);
    folha.window.print();
    folha.window.close();
    return
  }
  let doc = new jsPDF();
  doc.fromHTML(html, 15, 15, { "width": 180 });

  window.open(doc.output("bloburl"), "_blank");
}
