import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import { Container } from './styles';

const ContentComponent = (props) => {
  return (
    <Container theme={props.theme}>
        <div className="headerContainer">
            <div className="titleContainer">
                <span className="title" title={props.title}>{props.title}</span>
                <span className="subTitle">
                    {props.subtitle}
                </span>
            </div>
            {
                props.bread ?
                    <ul className="bdCrums">
                        <li><FontAwesomeIcon icon={faHome} /></li>
                        {
                            props.bread.map((item,key) => {
                                return <li title={item} key={key}>{item}</li>
                            })
                        }
                    </ul>
                : null

            }
        </div>
        <div className="contentContainer">
            {props.children}
        </div>
    </Container>
  );
}

export default ContentComponent;
