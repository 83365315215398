/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faEdit, faTrash, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import MenuComponent from '../../components/MenuComponent/';
import ContentComponent from '../../components/ContentComponent/';
import AreaComponent from '../../components/AreaComponent/';
import { Container } from './styles';
import Model from '../../models';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import swal from '@sweetalert/with-react';
import baseURL from './baseURL';
import { debounce } from '../../utils';

export default function ContatosFabricante(props) {
    const { id, who } = props.match.params;

    const model = who === 'fabricantes' ? 'fabricantes_contatosModel' : 'clientes_contatosModel';

    const [ lines, setLines ] = useState([]);
    const [ dados, setDados ] = useState({});
    const filtro = useRef();
    const { push } = useHistory();

    useEffect(() => {
        init();
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function init(){
        switch(who){
            case 'fabricantes': {
                const res = await Model.fabricantesModel['SHOW'](id);
                setDados({
                    title: "Contatos",
                    subtitle: res[0].FAB_RAZAO_SOCIAL,
                    bread: ['Fabricantes', 'Contatos', res[0].FAB_RAZAO_SOCIAL]
                })

                document.title = `${res[0].FAB_RAZAO_SOCIAL} - QCDOCS`;

                setLines(await Model.fabricantes_contatosModel['INDEX'](id))
                break;
            }
            case 'clientes': {
                const res = await Model.clientesModel['SHOW'](id);
                setDados({
                    title: "Contatos",
                    subtitle: res[0].CLI_RAZAO_SOCIAL,
                    bread: ['Clientes', 'Contatos', res[0].CLI_RAZAO_SOCIAL]
                })

                document.title = `${res[0].CLI_RAZAO_SOCIAL} - QCDOCS`;

                setLines(await Model.clientes_contatosModel['INDEX'](id))
                break;
            }
        }
    }

    async function handleDelete(id){
        swal({
            title: "Você tem certeza?",
            text: "Uma vez excluído, você não poderá recuperar esse registro!",
            icon: "warning",
            dangerMode: true,
            buttons: ['Não', 'Sim'],
          })
        .then(async (willDelete) => {
            if (willDelete) {
                const res = await Model[model]['DELETE'](id);
                if (res.affectedRows > 0) { swal("Sucesso!", "o registro foi removido do banco de dados", 'success', { buttons: false, timer: 2000 }) } else { if (res.errno === 1451) { swal("Não foi possivel!", "Não foi possivel excluir pois o mesmo possui vinculos ao sistema!", 'error') } else { swal("Ops!", "ocorreu um erro ao remover! tente novamente mais tarde!", 'error') } }
                init();
            }
        });
    }

    async function handleSendPass(id){
        swal({
            title: "Deseja enviar informações de acesso a esse contato?",
            text: "",
            icon: "info",
            dangerMode: false,
            buttons: true,
          })
        .then(async (willDelete) => {
            if (willDelete) {
                const res = await Model[who === 'fabricantes' ? 'fabricantes_contatosModel' : 'clientes_contatosModel']['SEND'](id);
                if(res.accepted){
                    swal("Sucesso!", "o email foi enviado!", 'success', { buttons: false, timer: 2000 })
                }else{
                    console.log(res)
                    swal("Não foi possivel!", `Detalhes do erro:\n ${res.response}`, 'error')
                }
            }
        });
    }

    async function handleSearch(){
        if(filtro.current.value === "") { init(); return; };
        setLines(await Model[model]['SEARCH'](filtro.current.value))
    }

    return(
    <Container>
        <MenuComponent {...dados}/>
        <ContentComponent {...dados}>
            <div className="containerButtonNew">
                <input ref={filtro} placeholder="Filtrar..." onChange={debounce(handleSearch)}/>
                <button onClick={() => push(`${baseURL}/cadastro/${who}/${id}`)}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    Novo
                </button>
            </div>
            <AreaComponent>
            <div className="card-contacts">
                {
                    lines.length > 0 ?
                        <ul>
                            {
                                lines.map((line, key) => {
                                    return (
                                        <li key={key}>
                                            <footer>
                                                <strong>{who === 'fabricantes' ? line.FC_NOME : line.CC_NOME}</strong>
                                                <p>
                                                    <b>Telefone:</b> {who === 'fabricantes' ? line.FC_TELEFONE : line.CC_TELEFONE}<br/>
                                                    <b>Email:</b> {who === 'fabricantes' ? line.FC_EMAIL : line.CC_EMAIL}<br/>
                                                    <b>Cargo:</b> {line.CAR_NOME}<br/>
                                                    <b>Último registro</b> {format(parseISO(who === 'fabricantes' ? line.FC_ULTIMO_ACESSO : line.CC_ULTIMO_ACESSO), "'no dia 'dd' de 'MMMM' de 'yyyy 'às' HH:mm", {locale: pt})}
                                                </p>
                                                <div className="buttons">
                                                    <button className="action delete" title="Excluir contato" onClick={() => handleDelete(who === 'fabricantes' ? line.FC_ID : line.CC_ID)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                    <button className="action edit" title="Editar contato" onClick={() => push(`${baseURL}/editar/${who}/${id}?contact=${who === 'fabricantes' ? line.FC_ID : line.CC_ID}`)}>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    <button className="action send" title="Enviar acesso" onClick={() => handleSendPass(who === 'fabricantes' ? line.FC_ID : line.CC_ID)}>
                                                        <FontAwesomeIcon icon={faPaperPlane} />
                                                    </button>
                                                </div>
                                            </footer>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    :
                    <div className="empty-box">
                        <span>Não há contatos registrados</span>
                    </div>
                }
            </div>
            </AreaComponent>
        </ContentComponent>
    </Container>
    );
}
