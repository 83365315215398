/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faCheck, faMinusCircle, faTimesCircle, faStop, faPlayCircle, faUpload } from '@fortawesome/free-solid-svg-icons';
import MenuComponent from '../../components/MenuComponent/';
import ContentComponent from '../../components/ContentComponent/';
import AreaComponent from '../../components/AreaComponent/';
import * as Model from '../../models/certificadosModel';
import Status from '../../data/status';
import swal from '@sweetalert/with-react';
import { isAfter } from 'date-fns';
import Card from './components/Card';
import { format, parseISO, isValid } from 'date-fns';
import logo from '../../assets/img/logo.png';
import { debounce, sortFunctions, handleSort } from '../../utils';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import baseURLProfile from '../Profile/baseURL';
import { Container, Process } from './styles';

export default function Certificados() {
    const [lines, setLines] = useState([]);
    const filtro = useRef();
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState({ currentSort: 'up', column: '' });
    const sortColumns = column => handleSort(column, sort, setSort);
    const { push } = useHistory();
    const dados = {
        title: "Certificados",
        subtitle: "Todos os Certificados",
        bread: ['Certificados']
    };

    useEffect(() => {
        document.title = `${dados.title} - QCDOCS`;
        init();
    }, []);

    async function handleSearch() {
        if (filtro.current.value === "") { init(); return; };
        const certificados = await Model['FILTER'](filtro.current.value);
        setLines(certificados.map(certificado => {
            if(parseInt(certificado.SER_INDETERMINADO) === 1){
                return certificado
            }
            //SUSPENDE CERTIFICADO
            if (!!certificado.CER_DATA_VALIDADE && isAfter(new Date(), new Date(certificado.CER_DATA_VALIDADE)) && certificado.CER_STATUS !== 3) {
                certificado = { ...certificado, CER_STATUS: 2 }
                handleChangeStatus(certificado.CER_ID, 2, false);
                return certificado
            }
            return certificado
        }))
    }

    async function init() {
        const certificados = await Model['INDEX']();
        setLines(certificados.map(certificado => {
            if(parseInt(certificado.SER_INDETERMINADO) === 1){
                return certificado
            }
            //SUSPENDE CERTIFICADO
            if (!!certificado.CER_DATA_VALIDADE && isAfter(new Date(), new Date(certificado.CER_DATA_VALIDADE)) && certificado.CER_STATUS !== 3) {
                certificado = { ...certificado, CER_STATUS: 2 }
                handleChangeStatus(certificado.CER_ID, 2, false);
                return certificado
            }
            return certificado
        }))
        const resCards = await Model['CARDS']();
        setCards([
            {
                number: resCards.emProcesso,
                ...Status[0]
            },
            {
                number: resCards.ativo,
                ...Status[1]
            },
            {
                number: resCards.suspenso,
                ...Status[2]
            },
            {
                number: resCards.cancelado,
                ...Status[3]
            },
            {
                number: resCards.iniciado,
                ...Status[4]
            }
        ]);
    }

    async function handleChangeStatus(id, value, show) {
        if (value !== 3) {
            const res = await Model['UPDATE'](id, { CER_STATUS: value })
            if (show === undefined) {
                if (res.affectedRows > 0) {
                    swal("Sucesso!", "o status foi alterado", 'success', { buttons: false, timer: 1000 })
                } else {
                    swal("Ops!", "ocorreu um erro ao alterar! tente novamente mais tarde!", 'error')
                }
                init();
            }
            return
        }
        swal({
            title: "Você tem certeza?",
            text: "Uma vez cancelado, você não poderá mais editar o certificado!",
            icon: "warning",
            dangerMode: true,
            buttons: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    const res = await Model['UPDATE'](id, { CER_STATUS: value })
                    if (show === undefined) {
                        if (res.affectedRows > 0) {
                            swal("Sucesso!", "o status foi alterado", 'success', { buttons: false, timer: 1000 })
                        } else {
                            swal("Ops!", "ocorreu um erro ao alterar! tente novamente mais tarde!", 'error')
                        }
                        init();
                    }
                }
            });
    }

    async function handleSearchCards(id) {
        const certificados = await Model['INDEX_CARDS'](id);
        setLines(certificados.map(certificado => {
            //SUSPENDE CERTIFICADO
            if(parseInt(certificado.SER_INDETERMINADO) === 1){
                return certificado
            }
            if (!!certificado.CER_DATA_VALIDADE && isAfter(new Date(), new Date(certificado.CER_DATA_VALIDADE)) && certificado.CER_STATUS !== 3) {
                certificado = { ...certificado, CER_STATUS: 2 }
                handleChangeStatus(certificado.CER_ID, 2, false);
                return certificado
            } else
                //REVERTE CERTIFICADO SUSPENSO
                if (!isAfter(new Date(), new Date(certificado.CER_DATA_VALIDADE)) && certificado.CER_STATUS === 2) {
                    certificado = { ...certificado, CER_STATUS: 0 }
                    handleChangeStatus(certificado.CER_ID, 0, false);
                    return certificado
                }
            return certificado
        }))
    }

    const _errorCertificate = (response) => {
        setLoading(false)
        swal('Foi encontrado um erro no modelo',
            `${response.value.id}
        ${response.value.explanation} `, 'error');
    }

    const _printCertificate = async (id, type = 'pdf') => {
        const res = await Model['DOWNLOAD'](id, type);
        axios({
            url: `http://${res.link}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${res.name}`);
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        });
    }

    const _openLink = (link) => {
        setLoading(false);
        return window.open(`http://${link}`);
    }
    
    async function handleSeeCertficate(id) {
        setLoading(true)
        const res = await Model['DOWNLOAD'](id, 'pdf');
        const res2 = await Model['DOWNLOAD'](id, 'docx');
        setLoading(false)
        swal({
            title: "Como deseja abrir o certificado?",
            text: "",
            icon: "info",
            dangerMode: false,
            buttons: ['Imprimir', 'Visualizar'],
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    setLoading(true)
                    const linkCertificado = await Model['EMITIR'](id);
                    switch (linkCertificado.status) {
                        case true: {
                            _openLink(linkCertificado.value);
                            break;
                        }
                        default: {
                            _errorCertificate(linkCertificado)
                        }
                    }
                    return null;
                }
                swal({
                    title: "Selecione o tipo do documento",
                    text: "",
                    icon: "info",
                    content: <div><a class="download-link" href={`https://${res.link}`} download>Imprimir PDF</a> <a class="download-link" href={`https://${res2.link}`} download>Imprimir Docx</a></div>,
                    dangerMode: false,
                    buttons: false,
                })
            });
    }

    return (
        <Container>
            <div className={`load ${loading ? "inProcess" : ""}`}>
                <img src={logo} alt="QCCERT" />
                <span>Carregando... </span>
            </div>
            <MenuComponent {...dados} />
            <ContentComponent {...dados}>
                <div className="cards">
                    {
                        cards.map((card, key) => {
                            return <Card onClick={handleSearchCards} key={key} {...card} />
                        })
                    }
                </div>
                <div className="containerButtonNew">
                    <div>
                        <input ref={filtro} type="text" placeholder="Filtrar..." onChange={debounce(handleSearch)} />
                    </div>
                </div>
                <AreaComponent>
                    <div className="table">
                        <table className="def">
                            <thead>
                                <tr>
                                    <th onClick={() => sortColumns('CER_NUMERO')} width="5%">Número</th>
                                    <th onClick={() => sortColumns('CLI_RAZAO_SOCIAL')} width="15%">Requerente</th>
                                    <th onClick={() => sortColumns('CER_DATA_EMISSAO')} width="5%">Emissão</th>
                                    <th onClick={() => sortColumns('CER_DATA_VALIDADE')} width="5%">Validade</th>
                                    <th onClick={() => sortColumns('SER_NOME')} width="10%">Tipo</th>
                                    <th onClick={() => sortColumns('CER_STATUS')} width="10%">Status</th>
                                    <th onClick={() => sortColumns('responsavel')} width="10%">Responsável</th>
                                    <th width="10%">Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    lines
                                    .sort(sortFunctions[sort.currentSort](sort.column))
                                    .map((line, key) => {
                                        var status = line.CER_STATUS;
                                        return (
                                            <tr process={Status[line.CER_STATUS].theme} key={key}>
                                                <td>{line.CER_NUMERO}</td>
                                                <td title={line.CLI_RAZAO_SOCIAL}>{line.CLI_RAZAO_SOCIAL}</td>
                                                <td>{isValid(line.CER_DATA_EMISSAO) ? format(parseISO(line.CER_DATA_EMISSAO), 'dd/MM/yyyy') : line.CER_DATA_EMISSAO}</td>
                                                <td>{isValid(line.CER_DATA_VALIDADE) ? format(parseISO(line.CER_DATA_VALIDADE), 'dd/MM/yyyy') : line.CER_DATA_VALIDADE}</td>
                                                <td title={line.SER_NOME}>{line.SER_NOME}</td>
                                                <Process process={Status[line.CER_STATUS].theme}>{!!line.CER_DATA_EMISSAO_MANUTENCAO ? line.CER_STATUS === 1 ? 'Concluído' : Status[line.CER_STATUS].label : Status[line.CER_STATUS].label}</Process>
                                                <td>{line.responsavel}</td>
                                                <td>
                                                    <button disabled={[4].includes(status)} className={![4].includes(status) ? "print" : "disabled"} title="Ver Certificado" onClick={() => handleSeeCertficate(line.CER_ID)}>
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </button>
                                                    <button disabled={status === 3} className={status !== 3 ? "continue" : "disabled"} title="Prosseguir Certificado" onClick={() => push(`${baseURLProfile}/editar/${line.CER_FK_CLI_ID ? 'clientes' : 'fabricantes'}/${line.CER_ID}/${line.CER_FK_CLI_ID ? line.CER_FK_CLI_ID : line.CER_FK_FAB_ID}`)}>
                                                        <FontAwesomeIcon icon={faPlayCircle} />
                                                    </button>

                                                    {
                                                        [0].includes(status) ?
                                                            <button disabled={[3].includes(status)} className={![3].includes(status) ? "conclued" : "disabled"} title="Concluir Certificado" onClick={() => handleChangeStatus(line.CER_ID, 1)}>
                                                                <FontAwesomeIcon icon={faCheck} />
                                                            </button>
                                                            :
                                                            <button disabled={[3].includes(status)} className={![3].includes(status) ? "inclonclued" : "disabled"} title="Mudar status para em processo" onClick={() => handleChangeStatus(line.CER_ID, 0)}>
                                                                <FontAwesomeIcon icon={faMinusCircle} />
                                                            </button>

                                                    }

                                                    <button disabled={[3].includes(status)} className={![3].includes(status) ? "upload" : "disabled"} title="Enviar Documentos para o cliente" onClick={() => push(`${baseURLProfile}/upload/${line.CER_FK_CLI_ID ? 'clientes' : 'fabricantes'}/${line.CER_ID}/${line.CER_FK_CLI_ID ? line.CER_FK_CLI_ID : line.CER_FK_FAB_ID}`)}>
                                                        <FontAwesomeIcon icon={faUpload} />
                                                    </button>
                                                    <button disabled={[3].includes(status)} className={![3].includes(status) ? "canceled" : "disabled"} title="Suspender Certificado" onClick={() => handleChangeStatus(line.CER_ID, 2)}>
                                                        <FontAwesomeIcon icon={faStop} />
                                                    </button>
                                                    <button disabled={status === 3} className={status !== 3 ? "canceled" : "disabled"} title="Cancelar Certificado" onClick={() => handleChangeStatus(line.CER_ID, 3)}>
                                                        <FontAwesomeIcon icon={faTimesCircle} />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </AreaComponent>
            </ContentComponent>
        </Container>
    );
}
